'use client';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbar, A11y, Navigation, FreeMode } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import {
  useInView,
  inView,
  useScroll,
  useMotionValueEvent,
} from 'framer-motion';
import {
  ShopAnchorSlide,
  ShopAnchorSlideSticky,
} from '@/components/shared/carousel';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

export type SlideType = {
  title: string;
  image: {
    url: string;
    alt?: string;
    placeholderUrl?: string;
  };
  anchor: string;
};

export default function ShopAnchorSwiper({
  items,
  hideId,
}: {
  items: SlideType[];
  hideId: string;
}) {
  const swiperSectionRef = useRef(null);
  const isInView = useInView(swiperSectionRef, { amount: 1, once: false });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isUpdatingSlide, setIsUpdatingSlide] = useState<boolean>(false);

  const { scrollY } = useScroll();
  const [hideStickyNav, setHideStickyNav] = useState<boolean>(false);

  /**
   * This effect runs on the client side to setup the inView observer.
   * Checks to see if the scroll value is more than the offset of the hideId element section.
   * If it is, it sets the hideStickyNav state to true.
   */
  useMotionValueEvent(scrollY, 'change', (latestScrollY) => {
    const scrollFlagElement = document.getElementById(hideId);

    if (!scrollFlagElement) return;
    const scrollTopValue = scrollFlagElement.offsetTop - 100;

    if (latestScrollY > scrollTopValue) {
      setHideStickyNav(true);
    } else {
      setHideStickyNav(false);
    }

    if (!isUpdatingSlide) {
      const unsubscribe = inView(
        'section',
        ({ target }) => {
          if (target.id && activeAnchor !== target.id) {
            setActiveAnchor(target.id);
          }
        },
        { margin: '0px 0px -50% 0px' }
      );
      // Cleanup function to run when the component unmounts
      return () => unsubscribe();
    }
  });

  // Swiper
  const [, setSwiperTop] = useState<SwiperClass | null>(null);
  const [swiperSticky, setSwiperSticky] = useState<SwiperClass | null>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const slideClass = `overflow-hidden bg-transparent !overflow-visible !h-[unset] !w-[unset]`;
  const paginationButtonClass = `disabled:opacity-0 cursor-pointer [&_path]:fill-black fill-black h-12 w-12 shadow-lg absolute rounded-full bg-gray-50 hover:bg-gray-100 flex items-center justify-center hover:scale-105 duration-100 transform z-20 p-2.5`;
  const leftButton = `${paginationButtonClass} left-0`;
  const rightButton = `${paginationButtonClass} right-0`;

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const [activeAnchor, setActiveAnchor] = useState<string>('');

  /*
   * This effect runs on the client side to setup the inView observer.
   * Checks for the section in view and sets the active slide accordingly based on the anchor.
   */

  useEffect(() => {
    if (activeAnchor && items.length > 0 && !isUpdatingSlide && swiperSticky) {
      // Find the index of the slide item with the matching anchor but remove prefix added in Headings
      const index = items.findIndex(
        (item) => item.anchor === activeAnchor?.replace('anchor-', '')
      );

      if (swiperSticky && !swiperSticky.destroyed && index !== -1) {
        setActiveSlide(index);
        swiperSticky.slideTo(index);
      }
    }
  }, [swiperSticky, setActiveSlide, activeAnchor, items]);

  // check once component is fully rendered make loaded true
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      <div ref={swiperSectionRef} className='container'>
        <div className={`relative bottom-0 z-10 mt-12`}>
          <Swiper
            className='container !overflow-visible [&>.swiper-wrapper]:my-0 [&>.swiper-wrapper]:space-x-2 [&>.swiper-wrapper]:overflow-visible md:[&>.swiper-wrapper]:space-x-4 lg:[&>.swiper-wrapper]:space-x-4 sm:[&_.swiper-pagination]:!bottom-8'
            modules={[Navigation, Scrollbar, A11y]}
            onInit={(swiper: SwiperClass) => {
              setSwiperTop(swiper);
            }}
            breakpoints={{
              0: {
                slidesPerView: 'auto',
                spaceBetween: 0,
              },
              // when window width is >= 425px
              425: {
                slidesPerView: 'auto',
                spaceBetween: 0,
              },
              // when window width is >= 765px
              765: {
                slidesPerView: 'auto',
                spaceBetween: 0,
              },
              // when window width is >= 980px
              980: {
                slidesPerView: 'auto',
                spaceBetween: 0,
              },
            }}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
              disabledClass: 'disabled',
            }}
          >
            {items.length > 0 &&
              items.map((item, index) => (
                <SwiperSlide
                  className={slideClass}
                  key={`product-carousel-${index}`}
                >
                  <ShopAnchorSlide
                    slide={item}
                    key={'anchor-slide-' + index}
                    index={index}
                    activeSlide={activeSlide}
                  />
                </SwiperSlide>
              ))}

            <div
              className={`absolute left-0 top-[50%] z-40 w-full -translate-y-8 transform ${
                isInView ? '' : 'hidden'
              }`}
            >
              <button ref={prevRef} className={leftButton}>
                <svg
                  className='h-5 w-5 rotate-180 transform'
                  xmlns='http://www.w3.org/2000/svg'
                  width='17'
                  height='16'
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                    fill='none'
                  />
                </svg>
              </button>
              <button ref={nextRef} className={rightButton}>
                <svg
                  className='h-5 w-5'
                  xmlns='http://www.w3.org/2000/svg'
                  width='17'
                  height='16'
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                    fill='none'
                  />
                </svg>
              </button>
            </div>
          </Swiper>
        </div>

        {isLoaded && !isInView && !hideStickyNav && (
          <div
            className={`container-padding fixed left-0 right-0 top-[70px] z-50 bg-yellow-200 bg-gradient-to-r !from-yellow-400 !to-yellow-300 pb-3 pt-2`}
          >
            <Swiper
              className='container !overflow-visible [&>.swiper-wrapper]:my-0 [&>.swiper-wrapper]:space-x-2 [&>.swiper-wrapper]:overflow-visible md:[&>.swiper-wrapper]:space-x-4 lg:[&>.swiper-wrapper]:space-x-4 sm:[&_.swiper-pagination]:!bottom-8'
              modules={[Navigation, Scrollbar, A11y, FreeMode]}
              onInit={(swiper: SwiperClass) => {
                setSwiperSticky(swiper);
              }}
              freeMode={true}
              breakpoints={{
                0: {
                  slidesPerView: 'auto',
                  spaceBetween: 0,
                },
                // when window width is >= 425px
                425: {
                  slidesPerView: 'auto',
                  spaceBetween: 0,
                },
                // when window width is >= 765px
                765: {
                  slidesPerView: 'auto',
                  spaceBetween: 0,
                },
                // when window width is >= 980px
                980: {
                  slidesPerView: 'auto',
                  spaceBetween: 0,
                },
              }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
                disabledClass: 'disabled',
              }}
            >
              {items.length > 0 &&
                items.map((item, index) => (
                  <SwiperSlide
                    className={slideClass}
                    key={`product-carousel-${index}`}
                    onClick={() => {
                      setActiveSlide(index);
                      setIsUpdatingSlide(true);
                      setTimeout(() => {
                        setIsUpdatingSlide(false);
                      }, 1000);
                    }}
                  >
                    <ShopAnchorSlideSticky
                      slide={item}
                      key={'anchor-slide-' + index}
                      index={index}
                      activeSlide={activeSlide}
                    />
                  </SwiperSlide>
                ))}

              <div
                className={`absolute left-0 top-[50%] z-40 w-full -translate-y-8 transform ${
                  isInView ? '' : 'hidden'
                }`}
              >
                <button ref={prevRef} className={leftButton}>
                  <svg
                    className='h-5 w-5 rotate-180 transform'
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='16'
                    viewBox='0 0 17 16'
                    fill='none'
                  >
                    <path
                      d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                      fill='none'
                    />
                  </svg>
                </button>
                <button ref={nextRef} className={rightButton}>
                  <svg
                    className='h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='16'
                    viewBox='0 0 17 16'
                    fill='none'
                  >
                    <path
                      d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                      fill='none'
                    />
                  </svg>
                </button>
              </div>
            </Swiper>
          </div>
        )}
      </div>
    </>
  );
}
