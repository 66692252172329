'use client';
import React, { useCallback, useLayoutEffect } from 'react';

import { IKButton } from '@/components/shared/material';
import { X, CaretRight, ChatCentered } from '@phosphor-icons/react';
import { Dialog, DialogBody } from '@material-tailwind/react';
import { PortableText } from '@portabletext/react';
import { MenuItemLink } from '@/types/types';
import {
  noParagraphs,
  paragraphs,
} from '@/components/sections/PortableTextComponents';
import { confirmationRedirectURLAtom, pageTitleAtom } from '@/store/formStore';
import { useSetAtom } from 'jotai';
import { usePathname, useRouter } from 'next/navigation';

export default function ContactModal(props: {
  open: boolean;
  handler: (open: boolean) => void;
  modal?: {
    heading: any;
    textContentTop: any;
    textContentBottom: any;
    buttons: {
      whatsAppButton: {
        text: string;
        link: MenuItemLink;
      };
      chatbotButton: {
        text: string;
        link?: MenuItemLink;
      };
      callbackButton: {
        text: string;
        link: MenuItemLink;
      };
    };
    textContentEnd?: any;
  };
}) {
  const pathname = usePathname();
  const router = useRouter();

  const { open, handler, modal } = props;
  const setConfirmationRedirectURL = useSetAtom(confirmationRedirectURLAtom);
  const setPageTitleAtom = useSetAtom(pageTitleAtom);

  const handleSetConfirmationRedirectURL = useCallback(() => {
    setConfirmationRedirectURL(window.location.href);
    setPageTitleAtom(document.title);
  }, [setConfirmationRedirectURL, setPageTitleAtom]);

  useLayoutEffect(() => {
    const html = document.querySelector('html');
    if (open && html) {
      // Turn off smooth scroll for IOS devices
      html.style.scrollBehavior = 'auto';
    }
    return () => {
      const html = document.querySelector('html');
      setTimeout(() => {
        // Turn on smooth scroll back on after 5 seconds
        if (html) html.style.scrollBehavior = 'smooth';
      }, 5000);
    };
  }, [open]);

  // Remove hash from URL after half a second so subsequent chatbot clicks work
  const handleRemoveHash = useCallback(() => {
    setTimeout(() => {
      router.replace(pathname, { scroll: false });
    }, 500);
  }, [router, pathname]);

  return (
    <>
      <Dialog
        size='xs'
        open={open}
        handler={() => handler}
        className='!z-700 max-w-[350px] md:max-w-[80%] lg:!max-w-[450px]'
      >
        <DialogBody className='space-y-5 px-6 pb-10 pt-8 md:px-8 md:pb-10 md:pt-8'>
          <div className='space-y-2'>
            <div className='flex items-center justify-between'>
              {modal?.heading && (
                <h3 className='font-display text-3xl font-bold text-charcoal md:text-3xl'>
                  <PortableText
                    value={modal.heading}
                    components={noParagraphs}
                  />
                </h3>
              )}
              <button
                onClick={() => handler(false)}
                aria-label='Close Modal'
                className='inline-block p-2 hover:cursor-pointer hover:opacity-80'
              >
                <X weight='bold' className='text-2xl text-charcoal' />
              </button>
            </div>
            {modal?.textContentTop && (
              <div className='text-charcoal'>
                <PortableText
                  value={modal.textContentTop}
                  components={paragraphs}
                />
              </div>
            )}
          </div>
          <div className='flex flex-wrap items-center justify-between gap-5'>
            {modal?.buttons?.whatsAppButton?.text &&
              modal?.buttons?.whatsAppButton?.link && (
                <IKButton
                  linkProps={{
                    className: 'flex-grow',
                  }}
                  link={modal.buttons.whatsAppButton.link}
                  color='green'
                  size='sm'
                  className='flex w-full flex-shrink-0 flex-grow items-center justify-center gap-2 px-5'
                >
                  <span>
                    {/* Whatsapp Logo */}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='16'
                      viewBox='0 0 17 16'
                      fill='none'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M13.1569 11.2969C12.9641 11.8425 12.1978 12.2941 11.5866 12.4263C11.1681 12.515 10.6225 12.5856 8.78406 11.8234C6.71937 10.9678 3.85219 7.92063 3.85219 5.89281C3.85219 4.86031 4.4475 3.65844 5.48812 3.65844C5.98906 3.65844 6.09937 3.66812 6.26406 4.06344C6.45687 4.52906 6.92719 5.67656 6.98313 5.79437C7.21438 6.27687 6.74781 6.55906 6.40938 6.97969C6.30125 7.10625 6.17906 7.24281 6.31594 7.47813C6.45188 7.70875 6.92219 8.47531 7.61344 9.09062C8.50625 9.88625 9.23031 10.14 9.48938 10.2481C9.68219 10.3278 9.9125 10.3094 10.0534 10.1591C10.2319 9.96625 10.4534 9.64625 10.6791 9.33094C10.8381 9.105 11.0406 9.07719 11.2528 9.15719C11.3959 9.20687 13.2159 10.0522 13.2928 10.1872C13.3497 10.2856 13.3497 10.7513 13.1569 11.2969ZM8.50156 0H8.4975C4.08719 0 0.5 3.58813 0.5 8C0.5 9.74937 1.06406 11.3722 2.02313 12.6888L1.02625 15.6613L4.10063 14.6788C5.36563 15.5159 6.87531 16 8.50156 16C12.9119 16 16.5 12.4116 16.5 8C16.5 3.58844 12.9119 0 8.50156 0Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  <span>{modal?.buttons?.whatsAppButton?.text}</span>
                </IKButton>
              )}

            {/* Todo: Get Chatbot action added in here  */}
            {modal?.buttons?.chatbotButton?.text && (
              <IKButton
                linkProps={{
                  className: 'flex-grow',
                }}
                link={
                  modal?.buttons?.chatbotButton?.link
                    ? modal?.buttons?.chatbotButton?.link
                    : {
                        url: '#hs-chat-open',
                        document: 'external',
                        type: 'external',
                      }
                }
                onClick={() => {
                  if (!modal?.buttons?.chatbotButton?.link) handleRemoveHash();
                  handler(false);
                }}
                color='yellow'
                size='sm'
                className='flex flex-shrink-0 flex-grow items-center justify-center gap-2 px-5'
              >
                <span>
                  <ChatCentered weight='fill' className='text-charcoal' />
                </span>
                <span>{modal?.buttons?.chatbotButton?.text}</span>
              </IKButton>
            )}
          </div>
          {modal?.textContentBottom && (
            <div className='text-charcoal'>
              <PortableText
                value={modal.textContentBottom}
                components={paragraphs}
              />
            </div>
          )}
          {modal?.buttons?.callbackButton?.text &&
            modal?.buttons?.callbackButton?.link && (
              <div className=''>
                <IKButton
                  link={modal?.buttons?.callbackButton?.link}
                  onClick={() => {
                    handler(false);
                    handleSetConfirmationRedirectURL();
                  }}
                  color='charcoal'
                  size='sm'
                  className='group flex w-full items-center justify-center gap-2 px-5 text-center'
                >
                  <span>{modal?.buttons?.callbackButton?.text}</span>
                  <span>
                    <CaretRight
                      weight='bold'
                      className='fill-yellow group-hover:fill-white'
                    />
                  </span>
                </IKButton>
              </div>
            )}

          {modal?.textContentEnd && (
            <div className='text-center text-gray-600 [&_a]:font-bold [&_a]:text-charcoal [&_a]:underline'>
              <PortableText
                value={modal.textContentEnd}
                components={paragraphs}
              />
            </div>
          )}
        </DialogBody>
      </Dialog>
    </>
  );
}
