export const fadeUp = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const fadeUpLeft = {
  hidden: {
    opacity: 0,
    y: 50,
    x: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
  },
};

export const fadeUpRight = {
  hidden: {
    opacity: 0,
    y: 50,
    x: -20,
  },
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
  },
};

export const moveUp = {
  outOfView: {
    y: 40,
    x: 0,
  },
  visible: {
    y: 0,
    x: 0,
  },
};

export const fadeRight = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};
