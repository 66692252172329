'use client';
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  Fragment,
} from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components, noParagraphs, paragraphs } from './PortableTextComponents';
import {
  m,
  useInView,
  useDragControls,
  useMotionValue,
  AnimatePresence,
  motion,
} from 'framer-motion';
import { fadeUp } from '@/assets/framer/animations';
import Image from 'next/image';
import Pattern from '@/public/images/Pattern.png';
import { Radio } from '@material-tailwind/react';
import { Check, CaretRight } from '@phosphor-icons/react';
import { IKButton } from '../shared/material';
import { MenuItemLink } from '@/types/types';

export default function PricingCalculator(props: {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock;
    description: PortableTextBlock;
    valuesIncluded: string[];
    displayOptions: 'bothRates' | 'inPersonRates' | 'onlineRates';
    type: string;
    calculator: {
      heading: PortableTextBlock;
      description: PortableTextBlock;
      customRatesTitle: PortableTextBlock;
      customRatesText: PortableTextBlock;
      initialTurnOver: number;
      rates: {
        inPersonRates: {
          name: string;
          ranges: {
            min: number;
            max: number;
            rate: number;
            customRates: boolean;
          }[];
        }[];
        onlineRates: {
          name: string;
          ranges: {
            min: number;
            max: number;
            rate: number;
            customRates: boolean;
          }[];
        }[];
      };
      button: {
        text: string;
        link: MenuItemLink;
      };
    };
  };
}) {
  const section = useRef(null);
  const isInView = useInView(section, { amount: 0.3, once: true });
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const description = props.data?.description;
  const calculator = props.data?.calculator;
  const displayOptions = props.data?.displayOptions;
  const inPersonRates = props.data?.calculator.rates.inPersonRates;
  const onlineRates = props.data?.calculator.rates.onlineRates;
  const initialTurnOver = props.data?.calculator.initialTurnOver;
  const valuesIncluded = props.data?.valuesIncluded;
  const dragControls = useDragControls();

  const [progressBarWidth, setProgressBarWidth] = useState<number>(0);
  const [turnover, setTurnover] = useState<number>(initialTurnOver ?? 20000);
  const toggle = useRef(null);
  const progressBar = useRef<HTMLDivElement>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [isCustomRates, setIsCustomRates] = useState<boolean>(false);

  const defaultRateStructure = useMemo(() => {
    return {
      name: '',
      ranges: [{ min: 0, max: null, rate: 2.75, customRates: false }],
    };
  }, []);

  const defaultRateObject = useMemo(() => {
    switch (displayOptions) {
      case 'bothRates':
      case 'inPersonRates':
        return inPersonRates?.[0] ?? defaultRateStructure;
      case 'onlineRates':
        return onlineRates?.[0] ?? defaultRateStructure;
      default:
        return defaultRateStructure;
    }
  }, [inPersonRates, onlineRates, displayOptions, defaultRateStructure]);

  const defaultRateType = useMemo(() => {
    switch (displayOptions) {
      case 'bothRates':
      case 'inPersonRates':
        return 'inPersonRates';
      case 'onlineRates':
        return 'onlineRates';
      default:
        return 'inPersonRates';
    }
  }, [displayOptions]);

  const [rateType, setRateType] = useState<string>(defaultRateType);

  const [rateObject, setRateObject] = useState<{
    name: string;
    ranges: {
      min: number;
      max: number | null;
      rate: number | null;
      customRates: boolean;
    }[];
  }>(defaultRateObject);

  const [dynamicRate, setDynamicRate] = useState<number>(2.75);

  const min = 0;
  const max = 100000;

  const x = useMotionValue(0);

  const numberFormatter = new Intl.NumberFormat('en-US');

  useEffect(() => {
    function handleResize() {
      if (progressBar.current === null) return;
      setProgressBarWidth(progressBar.current.clientWidth);
    }

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [progressBar]);

  useEffect(() => {
    if (turnover > max) {
      x.set(progressBarWidth - 20);
    } else {
      const newTogglePosition =
        (((turnover as number) - min) / (max - min)) * progressBarWidth;
      x.set(newTogglePosition - 20);
    }
    // Subtract the offset if necessary
  }, [turnover, progressBarWidth, x, min, max]);

  const handleInput = (event: any) => {
    // Parse as number first
    let value = parseInt(event.target.value.replace(/(R|\s|,)/g, ''), 10);

    // Check if the value is valid
    if (value === null || isNaN(value) || value < 0 || value === undefined) {
      setTurnover(0);
      return;
    }

    if (value > max) {
      value = max;
    }

    // Set turnover
    setTurnover(value);
  };
  // Function to convert pixel value to the desired range value
  const calculateValue = (x: number) => {
    // Assuming x is the drag point from the left of the screen, we need to adjust
    // it relative to the slider's position.
    if (progressBar && progressBar.current) {
      const sliderOffset = progressBar.current.getBoundingClientRect().left;
      const adjustedX = x - sliderOffset;

      // Calculate the new value based on the adjusted X
      let newValue =
        Math.round(((adjustedX / progressBarWidth) * (max - min) + min) / 100) *
        100;

      // Ensure the value is within the min and max range
      newValue = Math.max(newValue, min);
      newValue = Math.min(newValue, max);

      // Snap the newValue to the closest interval of 100
      return newValue;
    }
  };

  function startDrag(event: any) {
    dragControls.start(event, { snapToCursor: true });
    calculateProgressBarWidth(turnover as number);
  }

  const calculateProgressBarWidth = (value: number) => {
    if (value > max) return progressBarWidth;
    if (value < min) return 0;
    return ((value - min) / (max - min)) * progressBarWidth;
  };

  useEffect(() => {
    if (turnover >= max) {
      // setIsCustomRates(true);
      setTotalAmount((turnover * dynamicRate) / 100);
    } else {
      // setIsCustomRates(false);
      setTotalAmount((turnover * dynamicRate) / 100);
    }
  }, [turnover, dynamicRate]);

  useEffect(() => {
    // Find the value object that matches the turnover range
    const matchingValue = rateObject.ranges.find((value) => {
      return turnover >= value.min && turnover <= (value.max ?? Infinity);
    });

    // If a matching value is found, update the selectedRate
    if (matchingValue) {
      setDynamicRate(matchingValue.rate || 2.75);
      if (matchingValue.customRates) {
        setIsCustomRates(true);
      } else {
        setIsCustomRates(false);
      }
    }
  }, [turnover, rateObject]);

  const formatCurrency = (amount: number) => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    // Convert to a string with two decimal places
    const fixedAmount = amount.toFixed(2);
    // Format it with comma separators
    return currencyFormatter.format(parseFloat(fixedAmount));
  };

  const handleRateTypeChange = useCallback(
    (rateType: string) => {
      setRateType(rateType);
      switch (rateType) {
        case 'inPersonRates':
          setRateObject(inPersonRates?.[0] ?? defaultRateStructure);
          break;
        case 'onlineRates':
          setRateObject(onlineRates?.[0] ?? defaultRateStructure);
          break;
        default:
          return 'inPersonRates';
      }
    },
    [inPersonRates, onlineRates, defaultRateStructure]
  );

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className='section-padding relative scroll-m-16'
      >
        <div className='container-padding container relative z-10'>
          <div className='grid gap-5 md:gap-16 lg:grid-cols-2 lg:gap-20'>
            {/* Column 1 */}
            <div className='space-y-5'>
              <m.div
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
              >
                {heading && (
                  <h2 className='heading-xl mt-5 max-w-[560px] font-display font-medium tracking-tighter sm:mt-0 md:leading-[0.9] [&_strong]:font-bold [&_strong]:text-pink'>
                    <PortableText
                      components={components}
                      value={heading}
                    ></PortableText>
                  </h2>
                )}
              </m.div>

              <m.div
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
              >
                <div className='space-y-5 text-base text-charcoal sm:text-lg md:text-2xl'>
                  {description && (
                    <PortableText value={description}></PortableText>
                  )}
                </div>
              </m.div>
            </div>

            {/* Column 2 */}
            <div>
              <div>
                {calculator && calculator.heading && (
                  <h3 className='heading-sm font-display font-bold'>
                    <PortableText
                      components={components}
                      value={calculator.heading}
                    ></PortableText>
                  </h3>
                )}
              </div>
              <div>
                {/* Slider & Input*/}
                <div className='relative mb-5'>
                  <div className='relative'>
                    {/* Input */}
                    <div className='relative my-5'>
                      <input
                        onInput={handleInput}
                        value={'R ' + numberFormatter.format(turnover)}
                        className='w-full border-b border-gray-500 pl-0 pr-5 text-4xl text-teal-500 outline-none'
                        type='text'
                      />
                      <span className='absolute bottom-0 right-0 text-xl text-gray-500'>
                        p/m
                      </span>
                    </div>
                    {/* Slider */}
                    <div className='h-4'>
                      <div className='group relative flex items-center'>
                        {/* Progress */}
                        <div
                          className='pointer-events-none absolute left-0 z-10 h-3 bg-teal-500 hover:cursor-pointer'
                          style={{
                            width: calculateProgressBarWidth(turnover),
                          }}
                        ></div>
                        {/* Circle / Toggle */}

                        {/* <LazyMotion features={loadFeatures}> */}
                        <motion.div
                          ref={toggle}
                          drag='x'
                          whileDrag={{ scale: 1.1 }}
                          dragConstraints={{
                            left: -20,
                            right: progressBarWidth - 20,
                          }}
                          dragMomentum={false}
                          dragElastic={0}
                          dragControls={dragControls}
                          onDrag={(_event, info) => {
                            setTurnover(calculateValue(info.point.x) || 0);
                          }}
                          style={{
                            x,
                            transform: 'translateX(104.6px)',
                          }}
                          className='absolute z-20 h-8 w-8 rounded-full border-4 border-teal-500 bg-white [will-change:transform] hover:cursor-grab active:cursor-grabbing'
                        ></motion.div>

                        {/* Bar */}
                        <m.div
                          ref={progressBar}
                          onPointerDown={(event: any) => {
                            startDrag(event);
                            setTurnover(calculateValue(event.clientX) || 0);
                          }}
                          className='absolute h-2.5 w-full bg-gray-400 transition-all duration-300 hover:h-3 hover:cursor-pointer group-hover:h-3'
                        ></m.div>
                        {/* </LazyMotion> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Description */}
                {calculator && calculator.description && (
                  <div className='hidden space-y-5 pt-8 text-base text-charcoal md:block'>
                    <PortableText value={calculator.description}></PortableText>
                  </div>
                )}

                {/* Rate type tabs selection ----  */}
                {displayOptions === 'bothRates' && (
                  <div className='-mb-[1px] mt-8 flex'>
                    <button
                      className={`-mr-[1px] flex-1 rounded-t-2xl border border-yellow bg-white p-4 px-5 font-medium ${
                        rateType === 'inPersonRates'
                          ? '!border-b-white bg-white text-charcoal/80'
                          : 'bg-yellow-100 text-charcoal/70 hover:bg-yellow-150'
                      }`}
                      onClick={() => handleRateTypeChange('inPersonRates')}
                    >
                      Card Machine
                    </button>
                    <button
                      className={`-mr-[1px] flex-1 rounded-t-2xl border border-yellow bg-white p-4 px-5 font-medium ${
                        rateType === 'onlineRates'
                          ? '!border-b-white bg-white text-charcoal/80'
                          : 'bg-yellow-100 text-charcoal/70 hover:bg-yellow-150'
                      }`}
                      onClick={() => handleRateTypeChange('onlineRates')}
                    >
                      Online Payments
                    </button>
                  </div>
                )}

                {/* Results & Rates Card  */}
                <div
                  className={`overflow-hidden rounded-b-[20px] border border-yellow bg-white ${
                    displayOptions === 'bothRates'
                      ? ''
                      : 'mt-8 rounded-t-[20px]'
                  }`}
                >
                  {/* Rates */}
                  <div className='bg-white p-6'>
                    <div className='flex items-center gap-5'>
                      <div>
                        <h5 className='inline-block text-base font-bold text-charcoal'>
                          Your rate:
                        </h5>
                        <AnimatePresence mode='popLayout' initial={false}>
                          {!isCustomRates && (
                            <m.h4
                              key='standardRatesText'
                              initial={{ opacity: 0, x: 10 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{
                                duration: 0.5,
                                type: 'spring',
                              }}
                              className='text-3xl font-bold text-teal'
                            >
                              {dynamicRate.toFixed(2)}%
                            </m.h4>
                          )}
                          {isCustomRates && (
                            <m.h4
                              key='customRatesText'
                              initial={{ opacity: 0, x: 10 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{
                                duration: 0.5,
                                type: 'spring',
                              }}
                              className='text-3xl font-bold text-teal'
                            >
                              *Custom
                            </m.h4>
                          )}
                        </AnimatePresence>
                      </div>
                      {/* Divider */}
                      <span className='block w-0.5 flex-shrink-0 self-stretch bg-charcoal'></span>
                      {/* Rate controls */}
                      <div className='[&_label.p-3]:!p-2'>
                        <AnimatePresence mode='popLayout' initial={false}>
                          {rateType === 'inPersonRates' && (
                            <m.div
                              className='flex flex-wrap items-center gap-x-4 gap-y-1'
                              key='inPersonRates'
                              initial={{ opacity: 0, x: 10 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{
                                duration: 0.5,
                                type: 'spring',
                              }}
                            >
                              {inPersonRates &&
                                inPersonRates.length &&
                                inPersonRates.map((rate, index) => (
                                  <Fragment key={`radio-${index}`}>
                                    <Radio
                                      crossOrigin={true}
                                      checked={
                                        rateObject &&
                                        rateObject.name === rate.name
                                      }
                                      onChange={() => setRateObject(rate)}
                                      color='yellow'
                                      ripple={false}
                                      name='rate'
                                      className='flex-shrink-0 before:scale-50 before:transform before:bg-yellow'
                                      label={rate.name}
                                    />
                                  </Fragment>
                                ))}
                            </m.div>
                          )}
                          {rateType === 'onlineRates' && (
                            <m.div
                              className='flex flex-wrap items-center gap-x-4 gap-y-1'
                              key='onlineRates'
                              initial={{ opacity: 0, x: 10 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{
                                duration: 0.5,
                                type: 'spring',
                              }}
                            >
                              {onlineRates &&
                                onlineRates.length &&
                                onlineRates.map((rate, index) => (
                                  <Fragment key={`radio-${index}`}>
                                    <Radio
                                      crossOrigin={true}
                                      checked={
                                        rateObject &&
                                        rateObject.name === rate.name
                                      }
                                      onChange={() => setRateObject(rate)}
                                      color='yellow'
                                      ripple={false}
                                      name='rate'
                                      className='flex-shrink-0 before:scale-50 before:transform before:bg-yellow'
                                      label={rate.name}
                                    />
                                  </Fragment>
                                ))}
                            </m.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                  </div>
                  <div className='border-t border-t-yellow bg-yellow-100 p-6'>
                    <AnimatePresence mode='popLayout' initial={false}>
                      {!isCustomRates && (
                        <m.div
                          key='standardRates'
                          initial={{ opacity: 0, x: 10 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{
                            duration: 0.5,
                            type: 'spring',
                          }}
                        >
                          {/* Results */}
                          <div className='flex flex-col items-start gap-6 md:flex-row lg:flex-col xl:flex-row'>
                            <div className='flex-shrink-0'>
                              <span className='text-lg font-bold text-charcoal-900'>
                                You&apos;ll pay
                              </span>
                              {totalAmount !== null && (
                                <>
                                  <h4 className='text-4xl font-bold text-charcoal'>
                                    R {formatCurrency(totalAmount)}
                                  </h4>
                                  <span className='text-sm font-normal text-charcoal-500'>
                                    ex VAT and R2.50 settlement fee
                                  </span>
                                </>
                              )}
                            </div>

                            {/* Valued included */}
                            <div>
                              <h5 className='mt-1 text-base font-bold text-charcoal-900'>
                                Value included
                              </h5>
                              <div className='flex flex-wrap gap-x-4 gap-y-1'>
                                {valuesIncluded &&
                                  valuesIncluded.map((value, index) => (
                                    <div
                                      key={`value-${index}`}
                                      className='mt-2 flex items-center gap-2'
                                    >
                                      <span className='flex h-5 w-5 items-center justify-center rounded-full bg-yellow'>
                                        <Check
                                          weight='bold'
                                          className='h-3 w-3 text-charcoal'
                                        />
                                      </span>
                                      <span className='text-sm text-charcoal-900'>
                                        {value}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </m.div>
                      )}

                      {isCustomRates && (
                        <m.div
                          key='customRatesDiv'
                          initial={{ opacity: 0, x: 10 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{
                            duration: 0.5,
                            type: 'spring',
                          }}
                        >
                          <div className='mb-1 space-y-2'>
                            {calculator && calculator.customRatesTitle && (
                              <h4 className='text-2xl font-bold text-charcoal'>
                                <PortableText
                                  value={calculator.customRatesTitle}
                                  components={noParagraphs}
                                />
                              </h4>
                            )}
                            {calculator && calculator.customRatesText && (
                              <div className='text-base text-charcoal md:max-w-[90%] [&_a]:font-bold [&_a]:text-teal hover:[&_a]:underline'>
                                <PortableText
                                  value={calculator.customRatesText}
                                  components={paragraphs}
                                />
                              </div>
                            )}
                          </div>
                        </m.div>
                      )}
                    </AnimatePresence>

                    {/* Call to action button */}
                    {calculator &&
                      calculator.button &&
                      calculator.button.text &&
                      calculator.button.link && (
                        <div className='pt-7'>
                          <IKButton
                            color='charcoal'
                            className='flex w-full items-center justify-center gap-2 md:w-auto xs:min-w-[300px]'
                            link={calculator.button.link}
                          >
                            <span>{calculator.button.text}</span>
                            <CaretRight
                              weight='bold'
                              className='text-currentColor text-lg'
                            />
                          </IKButton>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='absolute inset-x-0 bottom-32 z-0 hidden h-28 flex-shrink-0 overflow-hidden bg-yellow-100/50 lg:block'>
          <Image
            className='h-full w-full min-w-[1920px] flex-shrink-0 object-cover opacity-60'
            src={Pattern}
            alt={''}
            width={1920}
            height={100}
            loading='lazy'
          />
        </div>
      </section>
    </>
  );
}
