'use client';
import React, { useRef } from 'react';
import { m, useInView } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import {
  noParagraphs,
  paragraphs,
} from '@/components/sections/PortableTextComponents';
import { IKButton, IKLink } from '@/components/shared/material';
import { fadeUp } from '@/assets/framer/animations';
import HuaweiLogo from '@/assets/images/huawei_logo.svg';
import GoogleIcon from '@/assets/images/GoogleIcon.svg';
import AppleLogo from '@/assets/images/apple_logo.svg';
import Image from 'next/image';

export default function SSUSignUpConfirmationCard(props: {
  data?: {
    sectionId?: string;
    image?: {
      url: string;
      alt: string;
      mediaAlt?: string;
      blurDataUrl: string;
    };
    heading?: PortableTextBlock;
    subheading?: PortableTextBlock;
    textContentTop?: PortableTextBlock;
    textContentBottom?: PortableTextBlock;
    subText?: PortableTextBlock;
    dashboardButtonText?: string;
    displayOptions?: {
      showDashboardButton?: boolean;
      showAppSelection?: boolean;
      appSelectionBadgesToShow?: string;
    };
  };
}) {
  const sectionId = props.data?.sectionId;
  const image = props.data?.image;
  const heading = props.data?.heading;
  const subheading = props.data?.subheading;
  const textContentTop = props.data?.textContentTop;
  const textContentBottom = props.data?.textContentBottom;
  const displayOptions = props.data?.displayOptions;

  const showAppSelection = displayOptions?.showAppSelection;
  const showDashboardButton = displayOptions?.showDashboardButton;
  const appSelectionBadgesToShow = displayOptions?.appSelectionBadgesToShow;

  const subText = props.data?.subText;
  const dashboardButtonText = props.data?.dashboardButtonText;
  const section = useRef(null);
  const isInView = useInView(section, { amount: 0.3, once: true });

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className='section-padding flex min-h-[max(90vh,600px)] scroll-m-16 items-center bg-[#F6F6F6]'
      >
        <div className='container-padding container'>
          {/* Card */}
          <div className='mx-auto w-full max-w-[420px] space-y-6 rounded-3xl border bg-white px-9 py-10 text-charcoal'>
            {/* Icon */}
            <div className='flex justify-center'>
              <m.div
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.1 }}
                className='w-full'
              >
                {image?.url && (
                  <Image
                    height={120}
                    width={340}
                    src={image?.url}
                    alt={image.mediaAlt || image.alt || ''}
                    loading='eager'
                  />
                )}
              </m.div>
            </div>
            {/* Body */}
            {(heading || textContentTop) && (
              <div>
                {heading && (
                  <m.h1
                    initial='hidden'
                    variants={fadeUp}
                    animate={isInView ? 'visible' : 'hidden'}
                    transition={{ delay: 0.1 }}
                    className='font-display text-2xl font-semibold'
                  >
                    <PortableText value={heading} components={noParagraphs} />
                  </m.h1>
                )}

                {textContentTop && (
                  <m.div
                    initial='hidden'
                    variants={fadeUp}
                    animate={isInView ? 'visible' : 'hidden'}
                    transition={{ delay: 0.2 }}
                    className='text-base text-charcoal-800 md:max-w-[98%]'
                  >
                    <PortableText
                      value={textContentTop}
                      components={paragraphs}
                    />
                  </m.div>
                )}
              </div>
            )}
            {dashboardButtonText && showDashboardButton && (
              <m.div
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.2 }}
                className='flex w-full items-center'
              >
                <IKButton
                  linkProps={{
                    className: 'w-full',
                  }}
                  link={{
                    type: 'external',
                    url: 'https://dashboard.ikhokha.com',
                    document: 'external',
                    newWindow: true,
                  }}
                  color='yellow'
                  size='sm'
                  className='flex w-full items-center justify-center gap-2 px-5'
                >
                  <span>{dashboardButtonText}</span>
                </IKButton>
              </m.div>
            )}
            <m.div
              className='space-y-3'
              initial='hidden'
              variants={fadeUp}
              animate={isInView ? 'visible' : 'hidden'}
              transition={{ delay: 0.4 }}
            >
              {subheading && (
                <h3 className='text-lg font-semibold'>
                  <PortableText value={subheading} components={noParagraphs} />
                </h3>
              )}

              {showAppSelection && (
                <div>
                  <div className='grid grid-cols-2 gap-2'>
                    <IKLink
                      link={{
                        type: 'external',
                        document: 'external',
                        url: 'https://play.google.com/store/apps/details?id=ik.emerge.ikhokha',
                      }}
                      className='relative flex select-none rounded-md bg-black hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
                    >
                      <div className='absolute left-0 top-0 z-[1] h-full w-full rounded-md shadow-black [box-shadow:_inset_0_0_0_6px_var(--tw-shadow-color)]'></div>
                      <Image
                        src={GoogleIcon}
                        width={200}
                        height={50}
                        className='select-none'
                        alt='Google app logo'
                      />
                    </IKLink>
                    <IKLink
                      link={{
                        type: 'external',
                        document: 'external',
                        url: 'https://appgallery.huawei.com/#/app/C102486477',
                      }}
                      className='relative flex select-none rounded-md bg-black hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
                    >
                      <div className='absolute left-0 top-0 z-[1] h-full w-full rounded-md shadow-black [box-shadow:_inset_0_0_0_6px_var(--tw-shadow-color)]'></div>
                      <Image
                        src={HuaweiLogo}
                        width={200}
                        height={50}
                        className='select-none'
                        alt='Huawei logo'
                      />
                    </IKLink>
                    {appSelectionBadgesToShow === 'all' && (
                      <IKLink
                        link={{
                          type: 'external',
                          document: 'external',
                          url: 'https://apps.apple.com/za/app/ikhokha/id1325073911',
                        }}
                        className='relative flex select-none rounded-md bg-black hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
                      >
                        <div className='absolute left-0 top-0 z-[1] h-full w-full rounded-md shadow-black [box-shadow:_inset_0_0_0_6px_var(--tw-shadow-color)]'></div>
                        <Image
                          src={AppleLogo}
                          width={200}
                          height={50}
                          className='select-none'
                          alt='Apple app store logo'
                        />
                      </IKLink>
                    )}
                  </div>
                </div>
              )}
            </m.div>

            {textContentBottom && (
              <m.div
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.6 }}
                className='text-base text-charcoal-800 md:max-w-[98%]'
              >
                <PortableText
                  value={textContentBottom}
                  components={paragraphs}
                />
              </m.div>
            )}
          </div>

          {subText && (
            <div
              className={`mt-8 pb-10 text-center font-normal text-charcoal md:mt-10 [&_a]:underline`}
            >
              <PortableText value={subText} components={paragraphs} />
            </div>
          )}
        </div>
      </section>
    </>
  );
}
