'use client';
import React, { useCallback, useEffect, useState } from 'react';
import { IKButton } from '@/components/shared/material';
import { CaretRight, CircleNotch, X } from '@phosphor-icons/react';
import { Input } from '@material-tailwind/react';
import { updateHubspotContactFunnelResultURL } from '@/actions/funnelActions';
import { getHomePageUrl } from '@/lib/pathUtils';
import { usePathname, useSearchParams } from 'next/navigation';
import { mixpanelFormSubmission } from '@/lib/mixpanel/services/client';
import { savedResultsToEmailAtom } from '@/store/funnelStore';
import { useSetAtom } from 'jotai';

export default function FunnelSaveToEmailModalInner(props: {
  handler: (open: boolean) => void;
  notSubmittedTitle?: string;
  hideCloseButton?: boolean;
  showExitButton?: boolean;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [email, setEmail] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const setSavedResultsToEmail = useSetAtom(savedResultsToEmailAtom);

  const { handler, notSubmittedTitle, hideCloseButton, showExitButton } = props;

  useEffect(() => {
    if (email) {
      if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        setError(null);
      } else {
        setError('Please enter a valid email address.');
      }
    } else {
      if (email !== null) {
        setError('Please enter a valid email address.');
      }
    }
  }, [email]);

  const handleSendEmail = useCallback(async () => {
    setIsSubmitting(true);
    if (email) {
      await updateHubspotContactFunnelResultURL(
        email,
        `${getHomePageUrl()}${pathname}?result=${searchParams.get('result')}`
      );

      // Mixpanel tracking for Save to Email
      mixpanelFormSubmission('Submit', 'Save to Email', 'Funnel Results');
      setSavedResultsToEmail(true);
    }
    setIsSubmitting(false);
    setHasSubmitted(true);
  }, [searchParams, pathname, email]);

  return (
    <>
      <div className='space-y-2'>
        <div className='flex w-full items-center justify-between'>
          <h3 className='text-2xl font-bold text-charcoal md:text-3xl'>
            {hasSubmitted ? 'Sent!' : notSubmittedTitle || 'Save your results'}
          </h3>
          <button
            onClick={() => {
              handler(false);
              setTimeout(() => {
                setHasSubmitted(false);
              }, 500);
              setEmail(null);
            }}
            aria-label='Close Modal'
            className='inline-block p-2 hover:cursor-pointer hover:opacity-80'
          >
            <X weight='bold' className='text-2xl text-charcoal' />
          </button>
        </div>
      </div>
      <div className='font-normal text-charcoal-700'>
        <p>
          {hasSubmitted ? (
            <>
              <div>Check your inbox for an email from us.</div>{' '}
              <div className='mt-2'>
                If you {`don't`} see it, check your spam folder.{' '}
              </div>
            </>
          ) : (
            `Enter your email address and we will send you your iK toolkit results.`
          )}
        </p>
      </div>
      {!hasSubmitted && (
        <div className='form-fields'>
          <Input
            crossOrigin={true}
            maxLength={256}
            labelProps={{
              className: 'inputLabel',
            }}
            onChange={(e) => setEmail(e.target.value)}
            variant='standard'
            label={'Email'}
            name='email'
            error={!!error}
            containerProps={{ className: '!h-[56px]' }}
            type='email'
            className={`input placeholder:opacity-0 focus:placeholder:opacity-100`}
          />
          {error && (
            <div className='mt-5 text-xs font-normal text-red-500'>{error}</div>
          )}
        </div>
      )}

      <div
        className={`flex flex-wrap items-center justify-between gap-10 ${hasSubmitted ? 'mt-0' : ''}`}
      >
        {!hasSubmitted ? (
          <IKButton
            disabled={!!error || email === null}
            // @ts-ignore
            onClick={() => handleSendEmail()}
            linkProps={{
              className: 'flex-grow',
            }}
            color='yellow'
            size='sm'
            className='flex flex-shrink-0 flex-grow items-center justify-center gap-2'
          >
            <span className={`${isSubmitting ? 'opacity-0' : ''}`}>Submit</span>
            <span className={`${isSubmitting ? 'opacity-0' : ''}`}>
              <CaretRight weight='bold' />
            </span>
            {isSubmitting && (
              <span className='absolute'>
                <CircleNotch weight='bold' className='h-6 w-6 animate-spin' />
              </span>
            )}
          </IKButton>
        ) : (
          <>
            {hideCloseButton ? null : (
              <IKButton
                // @ts-ignore
                onClick={() => {
                  handler(false);
                  setHasSubmitted(false);
                  setEmail(null);
                }}
                linkProps={{
                  className: 'flex-grow',
                }}
                color='yellow'
                size='sm'
                className='flex flex-shrink-0 flex-grow items-center justify-center gap-2'
              >
                <span>Close</span>
              </IKButton>
            )}
          </>
        )}
        {showExitButton && (
          <div className='flex w-full justify-center'>
            {showExitButton && !hasSubmitted ? (
              <IKButton
                link={{ document: 'home', type: 'internal', url: '/' }}
                variant='text'
                size='sm'
                ripple={false}
                className='bg-gradient-line group relative flex flex-shrink-0 items-center justify-center gap-2 !overflow-visible overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !py-1 transition-all duration-500 hover:!bg-left-bottom hover:transition-all hover:duration-500'
              >
                <span>Back to homepage</span>
              </IKButton>
            ) : (
              <>
                {showExitButton && (
                  <IKButton
                    // @ts-ignore
                    link={{ document: 'home', type: 'internal', url: '/' }}
                    linkProps={{
                      className: 'flex-grow w-full',
                    }}
                    color='yellow'
                    size='sm'
                    className='flex w-full flex-shrink-0 flex-grow items-center justify-center gap-2'
                  >
                    <span>Back to homepage</span>
                  </IKButton>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
