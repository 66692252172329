import React from 'react';
interface FormProps {
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function LocalSearch({ className, onChange }: FormProps) {
  return (
    <div
      className={`inline-flex h-14 w-full items-center rounded-xl border-2 border-charcoal bg-white focus-within:bg-white focus-within:shadow-md focus-within:shadow-black/10 sm:mx-1 sm:w-[30rem] ${className}`}
    >
      <div className='relative w-full px-4 [order:1]'>
        <input
          onChange={(e) => onChange(e)}
          placeholder='Search by city, suburb or store'
          className='w-full appearance-none border-none bg-transparent !bg-none p-0 text-charcoal placeholder:text-charcoal focus:outline-none'
        />
      </div>

      <div className='flex flex-shrink-0 items-center [order:2]'>
        <label className='flex'>
          <div className='h-full px-3 [&_path]:stroke-charcoal [&_stroke]:text-charcoal'>
            {/* SearchIcon */}
            <svg width='20' height='20' viewBox='0 0 20 20'>
              <path
                d='M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z'
                fill='none'
                fillRule='evenodd'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.4'
              />
            </svg>
          </div>
        </label>
      </div>
    </div>
  );
}
