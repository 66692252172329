'use client';
import MuxPlayer from '@mux/mux-player-react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { m, useInView, AnimatePresence } from 'framer-motion';
import { PortableTextBlock } from 'sanity';
import { fadeUp } from '@/assets/framer/animations';
import { components, noParagraphs } from '../PortableTextComponents';
import { PortableText } from '@portabletext/react';
import { AcceptedPaymentMethods } from '@/components/shared/groupIcons';
import { cn } from '@/components/utils/utils';
import { useMatchMedia } from '@/lib/matchMedia';

export default function ProductVideoAninmation(props: {
  data: {
    sectionId?: string;
    heading?: PortableTextBlock;
    muxVideoId: string;
    muxVideoMobileId: string;
    videoToasts: { time: number; value: { text: string } }[];
    acceptedPaymentMethods: {
      showAcceptedPaymentMethods: boolean;
      acceptedPaymentMethodsText: PortableTextBlock;
    };
    displayOptions?: {
      videoSize: 'contain' | 'cover';
    };
  };
}) {
  const section = useRef(null);
  const video = useRef<null | HTMLVideoElement>(null);
  const videoMobile = useRef<null | HTMLVideoElement>(null);
  const [activeCuePoint, setActiveCuePoint] = useState<any>(null);
  const [sellingPoints, setSellingPoints] = useState<any[]>([]);

  const isVideoInView = useInView(video, { amount: 0.2, once: false });
  const isInView = useInView(section, { amount: 0.4, once: true });
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const muxVideoId = props.data?.muxVideoId;
  const muxVideoMobileId = props.data?.muxVideoMobileId;
  const videoToasts = props.data?.videoToasts;
  const acceptedPaymentMethods = props.data?.acceptedPaymentMethods;
  const displayOptions = props.data?.displayOptions;

  const isDesktopResolution = useMatchMedia('(min-width:768px)', true);

  useEffect(() => {
    if (video.current) {
      let muxPlayerEl = video.current;

      const handleCuePointChange = () => {
        // @ts-expect-error
        const activeCuePointObj = muxPlayerEl.activeCuePoint;
        setActiveCuePoint(activeCuePointObj);
        if (activeCuePointObj?.time !== 0) {
          setSellingPoints((prev) => {
            if (
              activeCuePointObj.value.text &&
              !prev.some((point) => point.time === activeCuePointObj.time)
            ) {
              return [
                ...prev,
                {
                  text: activeCuePointObj.value.text,
                  active: true,
                  time: activeCuePointObj.time,
                },
              ];
            } else {
              return prev;
            }
          });
        }
      };

      muxPlayerEl.addEventListener('cuepointchange', handleCuePointChange);

      // Cleanup
      return () => {
        muxPlayerEl.removeEventListener('cuepointchange', handleCuePointChange);
      };
    }
    if (videoMobile.current) {
      let muxPlayerEl = videoMobile.current;

      const handleCuePointChange = () => {
        // @ts-expect-error
        const activeCuePointObj = muxPlayerEl.activeCuePoint;
        setActiveCuePoint(activeCuePointObj);
        if (activeCuePointObj?.time !== 0) {
          setSellingPoints((prev) => {
            if (
              activeCuePointObj.value.text &&
              !prev.some((point) => point.time === activeCuePointObj.time)
            ) {
              return [
                ...prev,
                {
                  text: activeCuePointObj.value.text,
                  active: true,
                  time: activeCuePointObj.time,
                },
              ];
            } else {
              return prev;
            }
          });
        }
      };

      muxPlayerEl.addEventListener('cuepointchange', handleCuePointChange);

      // Cleanup
      return () => {
        muxPlayerEl.removeEventListener('cuepointchange', handleCuePointChange);
      };
    }
  }, [video, videoMobile]);

  const handleDurationChange = (e: any) => {
    let muxPlayerEl = e.target;

    if (muxPlayerEl.readyState && videoToasts && videoToasts.length) {
      muxPlayerEl.addCuePoints(videoToasts);
    }
  };

  const setVideoToCuePointHandler = useCallback(
    (point: { time: number }) => {
      let videoEl = video.current;
      if (videoEl && videoEl.readyState >= 2 && point.time) {
        videoEl.currentTime = point.time;
        videoEl.play();
      }
    },
    [video]
  );

  useEffect(() => {
    // let videoDimensions = video.current.getBoundingClientRect();
    if (isVideoInView && video.current) {
      video.current.play();
    } else if (!isVideoInView && video.current) {
      video.current.pause();
    }

    if (
      (isVideoInView &&
        video.current?.currentTime === video.current?.duration) ||
      video.current?.currentTime === 0
    ) {
      setSellingPoints([]);
    }

    if (
      !isVideoInView &&
      video.current?.currentTime === video.current?.duration
    ) {
      setSellingPoints([]);
    }
  }, [isVideoInView, video]);

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className={cn(
          'relative z-40 -mb-28 scroll-m-16 bg-gradient-to-b pt-5 sm:pt-0 md:mb-14',
          displayOptions?.videoSize === 'cover'
            ? 'md:-mt-10'
            : 'bg-[#121112] lg:mb-20',
          displayOptions?.videoSize === 'cover' && !isDesktopResolution
            ? muxVideoMobileId
              ? '-mt-32 sm:-mt-12'
              : '-mt-20 sm:-mt-12'
            : '',
          acceptedPaymentMethods?.showAcceptedPaymentMethods ? '-mb-32' : 'pb-0'
        )}
      >
        {/* Top Divider wave */}
        {displayOptions?.videoSize !== 'cover' && (
          <div className='absolute left-0 top-0 h-max w-full -translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-[200%] fill-[#121112] md:w-full'
              width='1464'
              height='34'
              viewBox='0 0 1464 34'
              fill='none'
              preserveAspectRatio='none'
            >
              <path
                className='fill-[#121112]'
                d='M0 34H1464V13.9567C1462.14 14.0919 1459.76 14.2755 1456.86 14.4993L1456.79 14.5044C1400.39 18.8525 1148.67 38.2577 731.531 12.6924C315.789 -12.7874 65.0374 7.19974 7.26685 11.8046C4.35205 12.0369 1.92871 12.23 0 12.3763V34Z'
                fill='none'
              />
            </svg>
          </div>
        )}

        {(heading || (sellingPoints && sellingPoints.length > 0)) && (
          <div className='container-padding container absolute left-0 right-0 top-14 z-10 mx-auto flex h-full w-full flex-col pt-0 sm:pt-10 md:block md:h-[unset] md:pt-0'>
            {heading && (
              <m.h2
                initial='hidden'
                animate={isInView ? 'visible' : 'hidden'}
                variants={fadeUp}
                className='heading-xl relative z-10 max-w-[450px] font-display font-bold leading-[1] tracking-tighter text-yellow-150 sm:max-w-[550px] md:leading-[0.95] [&_strong]:text-yellow'
              >
                {heading && (
                  <PortableText
                    components={components}
                    value={heading}
                  ></PortableText>
                )}
              </m.h2>
            )}
            <div
              className={`${acceptedPaymentMethods ? 'min-h-[370px]' : ''} absolute bottom-[200px] w-[calc(100%_-_40px)] max-w-[unset] overflow-hidden md:relative md:bottom-[unset] md:max-w-[380px] md:overflow-visible`}
            >
              <div className='z-20 mt-14 flex min-h-[170px] flex-col gap-3 md:justify-center'>
                <AnimatePresence mode='wait' initial={false}>
                  {sellingPoints.map((point, index) => (
                    <Fragment key={`cue-point-'${index}`}>
                      <m.div
                        onClick={() => setVideoToCuePointHandler(point)}
                        initial={{ opacity: 0, x: 200 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{
                          duration: 1.5,
                          type: 'spring',
                        }}
                        className={`flex min-w-[300px] items-center gap-4 rounded-2xl px-5 py-3 text-base font-bold text-teal hover:cursor-pointer hover:sm:bg-teal-700/80 md:text-xl ${
                          activeCuePoint.time === point.time
                            ? 'bg-teal-700 md:bg-teal-700/80'
                            : 'bg-[#252525]/[95%] md:bg-[#282825]/40'
                        }`}
                      >
                        <span className='inline-block h-2 w-2 flex-shrink-0 rounded-full bg-teal'></span>
                        <span>{point.text}</span>
                      </m.div>
                    </Fragment>
                  ))}
                </AnimatePresence>
              </div>
            </div>
          </div>
        )}

        <div
          className={cn(
            'relative mx-auto flex justify-center overflow-hidden pt-10 md:pt-0',
            displayOptions?.videoSize === 'cover'
              ? muxVideoMobileId
                ? 'w-100vw h-[calc(100vw_*_5_/_4_+_125px)] sm:h-[h-[calc(100vw_*_7_/_16_+_100px)]] lg:h-[calc(100vw_*_7_/_16)]'
                : 'w-100vw'
              : 'h-[min(100vh,_600px)] sm:h-[min(100vh,_700px)] md:h-[min(100vh,_800px)]'
          )}
        >
          <svg
            className='absolute'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1471 633'
            preserveAspectRatio='none'
          >
            <defs>
              <clipPath
                id='curved-top-bottom'
                clipPathUnits='objectBoundingBox'
                className='w-[200%]'
              >
                <path
                  className='hidden md:block'
                  d='M 1 0.0221 C 0.9993 0.0223 0.9972 0.0228 0.9948 0.0231 C 0.9556 0.0300 0.7837 0.0606 0.5007 0.0203 C 0.2182 -0.0200 0.0479 0.0116 0.0086 0.0188 L 0.0086 0.0188 C 0.0039 0.0197 0.0011 0.0203 0.0002 0.0203 L 0.0002 0.2524 L 0 0.2645 V 0.9801 C 0.0436 0.9729 0.2076 0.9504 0.5007 0.9821 C 0.7891 1.0134 0.9554 0.9931 1 0.9852 V 0.0221 Z'
                ></path>
                <path
                  className='block md:hidden'
                  d='M0 0.0365 C 0.0129 0.0365 0.4227 -0.0476 1 0.0395 V 1 C 0.6541 0.9485 0.3443 0.9485 0 1 L 0 0.0365 Z'
                />
              </clipPath>
            </defs>
          </svg>
          <div
            className={cn(
              'pointer-events-none sticky top-0 flex select-none items-center justify-center',
              displayOptions?.videoSize === 'cover'
                ? muxVideoMobileId
                  ? 'h-[40rem] w-full sm:h-[calc(100vw_*_7_/_16)] sm:[clip-path:_url(#curved-top-bottom)] md:bg-black lg:h-[calc(100vw_*_7_/_16)]'
                  : 'h-[30rem] w-full sm:h-[calc(100vw_*_7_/_16)] sm:[clip-path:_url(#curved-top-bottom)] md:bg-black lg:h-[calc(100vw_*_7_/_16)]'
                : 'h-[min(100vh,_500px)] sm:h-[min(100vh,_700px)] md:h-[min(100vh,_800px)] md:w-[min(100vh,_800px)]'
            )}
          >
            {displayOptions?.videoSize !== 'cover' && (
              <div className='absolute left-0 top-0 z-20 h-full w-full shadow-[2px_2px_60px_27px_#121112_inset]'></div>
            )}
            {/* Gradient Overlay */}
            {/* <div className='absolute z-10 h-full w-full bg-gradient-radial from-[#121112]/[0%] from-0% via-[#121112]/[40%] via-[40%] to-[#121112] to-80%'></div> */}
            {/* Video */}
            {muxVideoId && (
              <MuxPlayer
                // @ts-ignore
                ref={video}
                className={cn(
                  'pointer-events-none',
                  displayOptions?.videoSize === 'cover'
                    ? 'relative w-[102vw] scale-[140%] transform after:hidden sm:scale-105 md:h-[104%]'
                    : 'h-full w-full',
                  muxVideoMobileId ? 'hidden sm:block' : ''
                )}
                onDurationChange={handleDurationChange}
                muted={true}
                nohotkeys
                nospace
                controls={false}
                autoPlay={true}
                loop={displayOptions?.videoSize === 'cover'}
                accent-color='#121112'
                style={{
                  /* Target all sections by excluding the section prefix */
                  // @ts-ignore
                  '--play-button': 'none',
                  '--live-button': 'none',
                  '--seek-backward-button': 'none',
                  '--seek-forward-button': 'none',
                  '--mute-button': 'none',
                  '--captions-button': 'none',
                  '--airplay-button': 'none',
                  '--pip-button': 'none',
                  '--fullscreen-button': 'none',
                  '--cast-button': 'none',
                  '--playback-rate-button': 'none',
                  '--volume-range': 'none',
                  '--time-range': 'none',
                  '--time-display': 'none',
                  '--duration-display': 'none',
                  '--rendition-selectmenu': 'none',
                  '--center-controls': 'none',
                  '--controls': 'none',
                  aspectRatio:
                    displayOptions?.videoSize === 'cover' && isDesktopResolution
                      ? '16/7'
                      : '1/1',
                  // @ts-ignore
                  '--controls-backdrop-color': 'rgb(0 0 0 / 0)',
                  accentColor: '#121112',
                  backgroundColor: 'transparent',
                  ...(displayOptions?.videoSize === 'cover' && {
                    '--media-object-fit': 'cover',
                  }),
                }}
                streamType='on-demand'
                playbackId={muxVideoId}
                metadataVideoTitle='iK Flyer Card Machine'
                primaryColor='#FFFFFF'
                secondaryColor='#000000'
              />
            )}

            {muxVideoMobileId && (
              <MuxPlayer
                // @ts-ignore
                ref={videoMobile}
                className={cn(
                  'pointer-events-none',
                  displayOptions?.videoSize === 'cover'
                    ? 'relative w-[102vw] scale-[140%] transform after:hidden sm:scale-105 md:h-[104%]'
                    : 'h-full w-full',
                  'block sm:hidden'
                )}
                onDurationChange={handleDurationChange}
                muted={true}
                nohotkeys
                nospace
                controls={false}
                autoPlay={true}
                loop={displayOptions?.videoSize === 'cover'}
                accent-color='#121112'
                style={{
                  /* Target all sections by excluding the section prefix */
                  // @ts-ignore
                  '--play-button': 'none',
                  '--live-button': 'none',
                  '--seek-backward-button': 'none',
                  '--seek-forward-button': 'none',
                  '--mute-button': 'none',
                  '--captions-button': 'none',
                  '--airplay-button': 'none',
                  '--pip-button': 'none',
                  '--fullscreen-button': 'none',
                  '--cast-button': 'none',
                  '--playback-rate-button': 'none',
                  '--volume-range': 'none',
                  '--time-range': 'none',
                  '--time-display': 'none',
                  '--duration-display': 'none',
                  '--rendition-selectmenu': 'none',
                  '--center-controls': 'none',
                  '--controls': 'none',
                  aspectRatio: '1/1',
                  ...(displayOptions?.videoSize === 'cover' && {
                    '--media-object-fit': 'cover',
                  }),
                  // @ts-ignore
                  '--controls-backdrop-color': 'rgb(0 0 0 / 0)',
                  accentColor: '#121112',
                  backgroundColor: 'transparent',
                }}
                streamType='on-demand'
                playbackId={muxVideoMobileId}
                metadataVideoTitle='iK Flyer Card Machine'
                primaryColor='#FFFFFF'
                secondaryColor='#000000'
              />
            )}
          </div>
        </div>

        {/* Bottom Divider wave */}
        {displayOptions?.videoSize !== 'cover' && (
          <div className='absolute bottom-0 left-0 h-max w-full translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-[200%] md:w-full [&_path]:fill-[#121112]'
              width='1464'
              height='26'
              viewBox='0 0 1464 26'
              fill='none'
              preserveAspectRatio='none'
            >
              <path
                d='M0 0V13.4014C71.6641 8.27045 311.629 -4.51068 731.531 15.2506C1153.28 35.099 1393.51 22.1175 1464 17.0324V0H0Z'
                fill='none'
              />
            </svg>
          </div>
        )}

        <div
          className={cn(
            'container-padding mb w-full transform items-center justify-center pb-28 md:absolute md:bottom-0 md:mb-0 md:flex md:pb-0',
            displayOptions?.videoSize === 'cover'
              ? 'translate-y-[-15%] sm:translate-y-[-50%] lg:translate-y-[50%]'
              : 'md:translate-y-[50%]'
          )}
        >
          {acceptedPaymentMethods?.showAcceptedPaymentMethods ? (
            <div className='bg-gradient-charcoal flex flex-col items-center justify-center gap-2 rounded-3xl bg-charcoal-900 px-5 py-7 md:min-w-[530px] md:px-10'>
              {acceptedPaymentMethods?.acceptedPaymentMethodsText && (
                <h4 className='text-gray-300'>
                  <PortableText
                    components={noParagraphs}
                    value={acceptedPaymentMethods.acceptedPaymentMethodsText}
                  />
                </h4>
              )}
              <AcceptedPaymentMethods
                color='white'
                className='w-full opacity-80'
              />
            </div>
          ) : (
            <>
              <div className='h-20'></div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
