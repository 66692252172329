'use client';
import React from 'react';
import { Dialog, DialogBody } from '@material-tailwind/react';
import { MenuItemLink } from '@/types/types';
import FunnelSaveToEmailModalInner from '@/components/business-toolkit/FunnelSaveToEmailModalInner';

export default function FunnelSaveToEmailModal(props: {
  open: boolean;
  handler: (open: boolean) => void;
  modal?: {
    heading: any;
    buttons: {
      whatsAppButton: {
        text: string;
        link: MenuItemLink;
      };
      chatbotButton: {
        text: string;
      };
      callbackButton: {
        text: string;
        link: MenuItemLink;
      };
    };
  };
}) {
  const { open, handler } = props;

  return (
    <>
      <Dialog
        size='xs'
        open={open}
        handler={() => handler}
        className='z-50 !w-full max-w-[350px] lg:!max-w-[350px]'
      >
        <DialogBody className='space-y-5 px-6 pb-10 pt-8 md:px-8 md:pb-10 md:pt-8'>
          <FunnelSaveToEmailModalInner handler={handler} />
        </DialogBody>
      </Dialog>
    </>
  );
}
