'use client';
import React, { useCallback, useRef, useState } from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components, paragraphs } from './PortableTextComponents';
import MapContainer from '@/components/shared/MapContainer';
import { m, useInView, AnimatePresence } from 'framer-motion';
import { fadeUp } from '@/assets/framer/animations';
import { Storefront } from '@phosphor-icons/react';
import { useAtom } from 'jotai';
import { Store } from '@/types/types';
import { selectedStoreAtom } from '@/store/globalStore';
import LocalSearch from '@/components/shared/LocalSearch';
export default function StoreLocator(props: {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock;
    stores: {
      _key: string;
      title: PortableTextBlock[];
      type?: string;
      address?: PortableTextBlock[];
      tradingHours?: PortableTextBlock[];
      contactDetails?: PortableTextBlock[];
      geoLocation: {
        lat: number;
        lng: number;
      };
    }[];
  };
}) {
  const sectionRef = useRef(null);
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const stores = props.data?.stores || [];
  const [filteredStores, setFilteredStores] = useState(stores);
  const [selectedStore, setSelectedStore] = useAtom(selectedStoreAtom);

  const isInView = useInView(sectionRef, { amount: 0.4, once: true });

  const handleAccordionToggle = useCallback(
    (store?: Store) => {
      if (store !== undefined) {
        setSelectedStore((prevStore) =>
          prevStore?._key === store._key ? null : store
        );
      }
    },
    [setSelectedStore]
  );

  let timeout: NodeJS.Timeout;

  const filterStoresOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      const filteredStores = stores.filter((store) => {
        const storeTitle = store.title
          ? store.title
              .map((title: any) => title.children[0]?.text.toLowerCase())
              .join(' ')
          : '';
        const address = store.address
          ? store.address
              .map((address: any) => address.children[0]?.text.toLowerCase())
              .join(' ')
          : '';
        // regex to match the search value with the store title or address
        const regex = new RegExp(searchValue, 'gi');
        return storeTitle.match(regex) || address.match(regex);
      });

      setFilteredStores(filteredStores);
      // collapse all accordions if the search value is empty
      if (filteredStores.length === stores.length) {
        setSelectedStore(null);
      }
    }, 300);
  };

  return (
    <>
      <section
        id={sectionId}
        className={`bg-gradient-yellow relative z-10 scroll-m-16 bg-gradient-to-b pb-4 pt-24 md:pb-5 md:pt-28 xl:pt-32 2xl:pt-36`}
      >
        {/* Top section */}
        <div className='container-padding container relative mx-auto'>
          <div className='flex flex-col items-stretch gap-x-7 md:flex-row md:justify-start'>
            <div
              className={`text-charcoal'} relative z-10 flex flex-grow flex-col justify-start space-y-6 md:w-1/2 md:pt-6`}
            >
              {/* Breadcrumbs
            {breadcrumbs && breadcrumbs.length > 0 && (
              <div>
                <PageBreadcrumbs breadcrumbs={breadcrumbs} />
              </div>
            )} */}

              <h1
                className={`heading-xl relative z-10 pr-10 font-display font-medium tracking-tighter text-charcoal md:pr-0`}
              >
                {heading && (
                  <PortableText
                    components={components}
                    value={heading}
                  ></PortableText>
                )}
              </h1>
              <LocalSearch
                className='hidden sm:flex'
                onChange={filterStoresOnSearch}
              />
            </div>
          </div>
        </div>
        {/* Bottom Divider wave */}
        <div className='absolute bottom-0 left-0 h-max w-full translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-[200%] md:w-full [&_path]:fill-yellow-700'
            width='1464'
            height='26'
            viewBox='0 0 1464 26'
            fill='none'
            preserveAspectRatio='none'
          >
            <path
              d='M0 0V13.4014C71.6641 8.27045 311.629 -4.51068 731.531 15.2506C1153.28 35.099 1393.51 22.1175 1464 17.0324V0H0Z'
              fill='none'
            />
          </svg>
        </div>
      </section>
      <section
        ref={sectionRef}
        className={`section-padding-b relative bg-gradient-to-b lg:p-0`}
      >
        {/* Store locator section */}
        <div className='container mx-auto grid grid-cols-12 gap-x-8 px-0 lg:px-10'>
          <div className='col-span-12 lg:absolute lg:inset-0 lg:left-1/2 lg:order-2'>
            <MapContainer stores={stores} className='lg:sticky lg:top-0' />
          </div>

          <div className='z-20 col-span-12 -mt-4 rounded-t-xl bg-white px-4 lg:z-0 lg:order-1 lg:col-span-6 lg:mt-0 lg:block lg:rounded-none lg:pl-0 lg:pr-6 lg:pt-12'>
            <LocalSearch
              className='mb-2 mt-5 flex sm:hidden'
              onChange={filterStoresOnSearch}
            />
            <div className='flex min-h-[10rem] flex-col divide-y divide-gray-100 sm:min-h-[44rem]'>
              {filteredStores &&
                filteredStores.length > 0 &&
                filteredStores.map((store, index) => (
                  <m.div
                    initial='hidden'
                    animate={isInView ? 'visible' : 'hidden'}
                    transition={{
                      delay: 0.1 * index + 0.1,
                    }}
                    variants={fadeUp}
                    key={index}
                  >
                    <button
                      onClick={() => handleAccordionToggle(store)}
                      className='group block w-full cursor-pointer items-center py-4'
                    >
                      <div className='flex items-center gap-4'>
                        <div
                          className={`relative flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full transition-all`}
                        >
                          <span
                            className={`relative z-[4] mix-blend-normal ${
                              selectedStore?._key === store._key
                                ? 'opacity-100'
                                : 'opacity-80 mix-blend-color-burn'
                            }`}
                          >
                            <Storefront className='h-6 w-6' />
                          </span>
                          <span
                            className={`absolute z-[2] h-full w-full rounded-full bg-yellow`}
                          ></span>
                          <span
                            className={`absolute h-[calc(100%_+_6px)] w-[calc(100%_+_6px)] rounded-full ${
                              selectedStore?._key === store._key
                                ? 'z-0 animate-grow bg-orange/10'
                                : ''
                            }`}
                          ></span>
                        </div>

                        <div className='flex-grow'>
                          {store.title && (
                            <span
                              className={`flex items-center gap-2 transition-all duration-500`}
                            >
                              <h3
                                className={`heading-xs text-left leading-none ${
                                  selectedStore?._key === store._key
                                    ? 'font-medium'
                                    : 'font-normal'
                                }`}
                              >
                                <PortableText
                                  value={store.title}
                                  components={components}
                                />
                              </h3>

                              <div className='relative ml-auto flex h-8 w-8 items-center justify-center'>
                                {/* Minus */}
                                <svg
                                  className={`absolute [&_path]:fill-charcoal ${
                                    selectedStore?._key === store._key
                                      ? 'opacity-100'
                                      : 'opacity-0'
                                  }`}
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='23'
                                  height='3'
                                  viewBox='0 0 23 3'
                                  fill='none'
                                >
                                  <path
                                    d='M22.1602 1.60913C22.1602 1.95723 22.0219 2.29107 21.7757 2.53721C21.5296 2.78335 21.1958 2.92163 20.8477 2.92163H1.59766C1.24956 2.92163 0.91572 2.78335 0.669579 2.53721C0.423437 2.29107 0.285156 1.95723 0.285156 1.60913C0.285156 1.26103 0.423437 0.927195 0.669579 0.681053C0.91572 0.434912 1.24956 0.296631 1.59766 0.296631H20.8477C21.1958 0.296631 21.5296 0.434912 21.7757 0.681053C22.0219 0.927195 22.1602 1.26103 22.1602 1.60913Z'
                                    fill='none'
                                  />
                                </svg>

                                {/* Plus */}
                                <svg
                                  className={`absolute [&_path]:fill-charcoal ${
                                    selectedStore?._key === store._key
                                      ? 'opacity-0'
                                      : 'opacity-100'
                                  }`}
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 30 30'
                                  fill='none'
                                >
                                  <path
                                    d='M26.0508 14.5906C26.0508 14.9511 25.9076 15.2969 25.6526 15.5518C25.3977 15.8067 25.0519 15.95 24.6914 15.95H16.082V24.5593C16.082 24.9199 15.9388 25.2656 15.6839 25.5205C15.4289 25.7755 15.0832 25.9187 14.7227 25.9187C14.3621 25.9187 14.0164 25.7755 13.7614 25.5205C13.5065 25.2656 13.3633 24.9199 13.3633 24.5593V15.95H4.75391C4.39338 15.95 4.04762 15.8067 3.79268 15.5518C3.53775 15.2969 3.39453 14.9511 3.39453 14.5906C3.39453 14.23 3.53775 13.8843 3.79268 13.6294C4.04762 13.3744 4.39338 13.2312 4.75391 13.2312H13.3633V4.62183C13.3633 4.2613 13.5065 3.91554 13.7614 3.6606C14.0164 3.40567 14.3621 3.26245 14.7227 3.26245C15.0832 3.26245 15.4289 3.40567 15.6839 3.6606C15.9388 3.91554 16.082 4.2613 16.082 4.62183V13.2312H24.6914C25.0519 13.2312 25.3977 13.3744 25.6526 13.6294C25.9076 13.8843 26.0508 14.23 26.0508 14.5906Z'
                                    fill='none'
                                  />
                                </svg>
                              </div>
                            </span>
                          )}
                        </div>
                      </div>
                      {selectedStore?._key === store._key && (
                        <AnimatePresence>
                          <m.div
                            initial={{
                              opacity: 0,
                              height: 0,
                            }}
                            animate={{
                              opacity: 1,
                              height: 'auto',
                            }}
                            transition={{
                              duration: 0.3,
                            }}
                            key={`${index}-height`}
                            exit={{
                              opacity: 0,
                              height: 0,
                            }}
                            className='cursor-default space-y-6 overflow-hidden py-4'
                          >
                            <div>
                              {store.address && (
                                <div
                                  className={`space-y-2 pl-14 text-left text-charcoal [&_strong]:text-teal`}
                                >
                                  <h4 className='mb-2 font-bold'>Address:</h4>
                                  <PortableText
                                    value={store.address}
                                    components={paragraphs}
                                  ></PortableText>
                                </div>
                              )}
                            </div>
                            <div>
                              {store.tradingHours && (
                                <div
                                  className={`pl-14 text-left text-charcoal [&_strong]:text-teal`}
                                >
                                  <h4 className='mb-2 font-bold'>
                                    Trading hours:
                                  </h4>
                                  <PortableText
                                    value={store.tradingHours}
                                    components={paragraphs}
                                  ></PortableText>
                                </div>
                              )}
                            </div>
                            <div>
                              {store.contactDetails && (
                                <div
                                  className={`pl-14 text-left text-charcoal [&_a]:text-teal [&_a]:underline [&_strong]:text-teal`}
                                >
                                  <h4 className='mb-2 font-bold'>
                                    Contact details:
                                  </h4>
                                  <PortableText
                                    value={store.contactDetails}
                                    components={paragraphs}
                                  ></PortableText>
                                </div>
                              )}
                            </div>
                          </m.div>
                        </AnimatePresence>
                      )}
                    </button>
                  </m.div>
                ))}

              {filteredStores.length === 0 && (
                <div className='py-10 text-center sm:text-left'>
                  <h3 className='text-charcoal'>
                    No iK Stores found for your search.
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
