'use client';

import {
  noParagraphs,
  paragraphs,
} from '@/components/sections/PortableTextComponents';
import { MenuItemLink } from '@/types/types';
import { CaretDown, Check } from '@phosphor-icons/react';
import { PortableText } from '@portabletext/react';
import { m, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { PortableTextBlock } from 'sanity';
import { FunnelOnlineAcceptedPaymentMethods } from '../groupIcons';
import { IKButton } from '../material';

type CardType = {
  _id: string;
  title: PortableTextBlock[];
  description: PortableTextBlock[];
  longDescription: PortableTextBlock[];
  availableOn: {
    title: string;
    link: MenuItemLink;
  }[];
  learnMoreLink: MenuItemLink;
};

export default function FunnelProductCard({ card }: { card: CardType }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      className='rounded-3xl border border-yellow bg-white px-8 py-4 shadow-lg'
      key={card._id}
    >
      <button
        className='mb-4 flex w-full items-center justify-between border-b border-charcoal-300 pb-1'
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div>
          <div className='flex items-center gap-2'>
            <span className='flex h-5 w-5 items-center justify-center rounded-full bg-yellow-200 p-1'>
              <Check
                weight='bold'
                className='h-5 w-5 font-bold text-charcoal [&_path]:stroke-2'
              />
            </span>
            <span className='text-lg font-bold text-orange'>Free</span>
          </div>
          <h4 className='heading-xs font-sans font-bold text-charcoal'>
            <PortableText value={card.title} components={noParagraphs} />
          </h4>
        </div>
        <div>
          <button
            className={`transform p-2 duration-150 ${isOpen ? 'rotate-180' : ''}`}
          >
            <CaretDown className='h-8 w-8 font-bold text-charcoal-500 [&_path]:stroke-2' />
          </button>
        </div>
      </button>
      <div className='mb-4 max-w-[unset] text-base leading-relaxed text-charcoal [&_ul]:list-inside [&_ul]:list-disc'>
        <PortableText
          value={card.description as PortableTextBlock[]}
          components={paragraphs}
        />
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <m.div
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ duration: 0.3, type: 'tween', ease: 'easeInOut' }}
            className='space-y-5 overflow-hidden text-charcoal'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
          >
            {card.longDescription && (
              <div className='[&_ol]:list-disc'>
                <PortableText
                  value={card.longDescription as PortableTextBlock[]}
                  components={paragraphs}
                />
              </div>
            )}

            {card?.availableOn?.length && (
              <div className='flex items-center'>
                <span className='text-xs'>Available on:</span>
                <div className='ml-2 flex flex-wrap gap-2'>
                  {card?.availableOn?.map((item, index) => (
                    <IKButton
                      key={`compatible-with-${index}`}
                      link={item.link}
                      color='charcoal'
                      variant='text'
                      size='sm'
                      className='inline-flex items-center justify-start gap-x-1.5 !rounded-md border border-gray-200 bg-white !py-0.5 pl-1 pr-2 text-xs font-normal hover:border-teal hover:transition-all hover:duration-500'
                    >
                      <Check
                        className='h-4 w-4'
                        weight='bold'
                        color='#00C3D5'
                      />
                      {item.title}
                    </IKButton>
                  ))}
                </div>
              </div>
            )}

            <div className='!mt-5 flex justify-center'>
              <FunnelOnlineAcceptedPaymentMethods
                className='w-full max-w-[200px]'
                color='gray'
              />
            </div>

            <div className='!mt-3'>
              <IKButton
                link={card?.learnMoreLink}
                variant='text'
                size='sm'
                className='bg-gradient-line relative mb-5 overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !py-1 text-base transition-all duration-500 hover:!bg-left-bottom hover:transition-all hover:duration-500'
              >
                Learn more
              </IKButton>
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </div>
  );
}
