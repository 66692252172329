import React from 'react';
import Image from 'next/image';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections';
import { MenuItemLink } from '@/types/types';

export type CardType = {
  title?: PortableTextBlock;
  description?: PortableTextBlock;
  image?: {
    url?: string;
    alt?: string;
    mediaAlt?: string;
    blurDataURL?: string;
  };
  button: {
    text: string;
    link?: MenuItemLink;
  };
};

export default function UniqueSellingPointsCard(props: { card: CardType }) {
  const card = props.card;

  return (
    <>
      <div className='relative flex h-full w-full flex-col'>
        <div
          className={`relative z-10 flex h-full items-center justify-start gap-5 rounded-3xl border border-yellow !bg-white px-5 pb-5 pt-5 shadow-lg shadow-black/10 md:py-10 lg:flex-grow lg:flex-col`}
        >
          {card.image && card.image.url && (
            <div className='flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-yellow-200 lg:mx-auto'>
              <Image
                width={35}
                height={35}
                src={card.image.url}
                alt={card.image.mediaAlt || card.image.alt || ''}
                className={'h-10 w-10 object-contain'}
              />
            </div>
          )}
          <div className='space-y-1 lg:px-5 lg:text-center'>
            {card.title && (
              <div className='heading-xs font-bold text-charcoal [&_br]:hidden md:[&_br]:inline'>
                <PortableText components={components} value={card.title} />
              </div>
            )}
            {card.description && (
              <div className='text-base text-charcoal [&_a]:text-teal [&_a]:hover:underline [&_br]:hidden md:[&_br]:inline'>
                <PortableText
                  components={components}
                  value={card.description}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
