'use client';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';

import { m, useSpring, useTransform } from 'framer-motion';
import { IKLink } from './material';
import { TrustBadgeType } from '@/types/types';

export default function TrustBadge(props: {
  showTrustBadge?: boolean;
  trustBadge?: TrustBadgeType;
  className?: string;
}) {
  const [zoom, setZoom] = useState(false);
  const fiveStarReviewsCount = props.trustBadge?.fiveStarReviewsCount || 0;

  let spring = useSpring(0, {
    stiffness: 25,
    damping: 20,
    mass: 0.7,
  });
  let displayRating = useTransform(spring, (current) => {
    return current.toLocaleString('en-US', {
      minimumIntegerDigits: fiveStarReviewsCount.toString().length,
      useGrouping: true,
      maximumFractionDigits: 0,
    });
  });

  const fiveStarReviewsCountTotalFormatted =
    fiveStarReviewsCount.toLocaleString();
  displayRating.on('change', (value) => {
    if (value === fiveStarReviewsCountTotalFormatted) {
      setZoom(true);
    }
  });

  useEffect(() => {
    spring.set(fiveStarReviewsCount);
  }, [spring, fiveStarReviewsCount]);

  return (
    <>
      {fiveStarReviewsCount > 0 &&
      props?.showTrustBadge &&
      props?.trustBadge?.link ? (
        <IKLink link={props?.trustBadge?.link}>
          <m.div
            initial={{ scale: 1 }}
            animate={{ scale: zoom ? 1.1 : 1 }}
            transition={{ duration: 0.2 }}
            onAnimationComplete={() => setZoom(false)} // Reset zoom after the animation
            className={`bottom-0 !mb-6 !mt-2 flex items-center gap-2.5 sm:absolute sm:-bottom-5 sm:!mb-0 sm:!mt-24 md:bottom-16 lg:items-end xl:bottom-20 ${props.className}`}
          >
            {props?.trustBadge?.image?.url && (
              <Image
                quality={95}
                priority={true}
                alt={props?.trustBadge?.image?.alt ?? 'iKhokha'}
                width='90'
                height='40'
                loading='eager'
                src={props?.trustBadge?.image?.url}
              />
            )}
            <div className='flex flex-col'>
              <div className='flex flex-col text-sm sm:text-base'>
                <span>{props?.trustBadge?.text || ''}</span>
                <div>
                  by{' '}
                  <span className='font-bold'>
                    {' '}
                    <m.span className=''>{displayRating}</m.span> customers{' '}
                  </span>
                </div>
              </div>
            </div>
          </m.div>
        </IKLink>
      ) : null}
    </>
  );
}
