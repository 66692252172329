'use client';
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from './PortableTextComponents';
import { m, useInView } from 'framer-motion';
import { fadeUp, moveUp } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';
import { CompareTable } from '@/components/shared/table';

export type SlideType = {
  title: string;
  product?: {
    productId?: string;
    productGid?: string;
    slug?: string;
    categorySlug?: string;
    price?: number;
  };
  image: {
    url: string;
    alt: string;
    placeholderUrl?: string;
  };
  button: {
    text: string;
    link: MenuItemLink;
  };
  items?: [
    {
      text: string;
      disabled: boolean;
      image: {
        url: string;
        alt?: string;
        placeholderUrl?: string;
      };
    },
  ];
};

interface TableRow {
  _type: string;
  _key: string;
  cells: string[];
}

interface TableNode {
  rows?: TableRow[];
}

export default function CompareTableSection(props: {
  data?: {
    _key?: string;
    sectionId: string;
    heading?: PortableTextBlock;
    subheading?: PortableTextBlock;
    table?: TableNode;
    items?: SlideType[];
  };
}) {
  // Props
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const subheading = props.data?.subheading;
  const table = props.data?.table;
  const headingRef = useRef(null);
  const sectionRef = useRef(null);
  const isInView = useInView(headingRef, { amount: 0.4, once: true });
  const tableRef = useRef<HTMLTableElement | null>(null);

  // Swiper
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const paginationButtonClass = ` [pointer-events:_fill] scroll-m-16 disabled:opacity-0 cursor-pointer [&_path]:fill-black fill-black h-12 w-12 shadow-lg absolute rounded-full bg-gray-50 hover:bg-gray-100 flex items-center justify-center hover:scale-105 p-2.5`;
  const leftButton = `${paginationButtonClass} left-0 swiper-button-outline-left`;
  const rightButton = `${paginationButtonClass} right-0 swiper-button-outline-right`;

  const [scrollLeft, setScrollLeft] = useState(0);
  const [hideScrollRight, setHideScrollRight] = useState(false);

  const handleScrollRight = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        left: 300,
        behavior: 'smooth',
      });
    }
  }, []);

  const handleScrollLeft = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  const handleScrollChange = (value: number) => {
    setScrollLeft(value);

    if (tableRef.current) {
      if (
        tableRef.current.clientWidth + value >=
        tableRef.current.scrollWidth
      ) {
        setHideScrollRight(true);
      } else {
        setHideScrollRight(false);
      }
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.clientWidth;
    }
  }, [scrollLeft]);

  return (
    <>
      <section
        id={sectionId}
        ref={sectionRef}
        className='section-padding relative bg-gradient-to-b from-white via-yellow-50 to-white'
      >
        <div className=''>
          <div className='container-padding container relative mx-auto'>
            <m.h2
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
              variants={fadeUp}
              ref={headingRef}
              className='heading-xl relative z-10 pb-4 font-display font-medium tracking-tighter text-charcoal sm:max-w-[900px] [&_strong]:text-pink'
            >
              {heading && (
                <PortableText
                  components={components}
                  value={heading}
                ></PortableText>
              )}
            </m.h2>
            <p className='text-xl leading-8'>
              {subheading && (
                <PortableText
                  components={components}
                  value={subheading}
                ></PortableText>
              )}
            </p>
          </div>

          <div className='container relative px-0 sm:px-8 lg:px-10'>
            <m.div
              className='relative z-10'
              initial='outOfView'
              animate={isInView ? 'visible' : 'outOfView'}
              variants={moveUp}
            >
              {table && (
                <CompareTable
                  onScrollChange={handleScrollChange}
                  tableRef={tableRef}
                  table={table}
                />
              )}

              <div className='pointer-events-none absolute left-0 top-0 z-40 block h-full w-full -translate-y-8 transform'>
                <div className='compare-table-button-container pointer-events-none sticky left-0 top-[50%] z-[1] flex h-[190px] items-center overflow-hidden pt-10 sm:-mx-[20px]'>
                  <button
                    ref={prevRef}
                    onClick={() => handleScrollLeft()}
                    className={leftButton}
                    style={{
                      display: !hideScrollRight ? 'none' : '',
                    }}
                  >
                    <svg
                      className='h-5 w-5 rotate-180 transform'
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='16'
                      viewBox='0 0 17 16'
                      fill='none'
                    >
                      <path
                        d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                        fill='none'
                      />
                    </svg>
                  </button>
                  <button
                    ref={nextRef}
                    onClick={() => handleScrollRight()}
                    className={rightButton}
                    style={{
                      display: hideScrollRight ? 'none' : '',
                    }}
                  >
                    <svg
                      className='h-5 w-5'
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='16'
                      viewBox='0 0 17 16'
                      fill='none'
                    >
                      <path
                        d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                        fill='none'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </m.div>
          </div>
        </div>
      </section>
    </>
  );
}
