'use client';
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Link from 'next/link';

export type SlideType = {
  title: string;
  image: {
    url: string;
    alt?: string;
    placeholderUrl?: string;
  };
  anchor: string;
};

export default function ShopAnchorSlide({
  slide,
}: {
  slide: SlideType;
  activeSlide: number;
  index: number;
}) {
  const [anchorTarget, setAnchorTarget] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const target = document.getElementById('anchor-' + slide.anchor);
    setAnchorTarget(target);
  }, [slide.anchor]);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (anchorTarget) {
      const offset = 120; // Adjust this offset as needed
      const targetPosition = anchorTarget.offsetTop - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
      <Link
        href={`#${slide.anchor}`}
        onClick={handleClick}
        aria-label={`Scroll to ${slide.title}`}
        className={`group relative flex h-[150px] w-[150px] items-center justify-center overflow-hidden rounded-3xl`}
      >
        <div
          className={`inset absolute inset-0 -z-10 bg-gradient-to-r from-yellow-300 to-yellow-150`}
        ></div>
        <div>
          <div className={`h-20 overflow-hidden`}>
            <Image
              src={slide.image.url}
              alt={slide.image.alt ?? slide.title}
              className={`h-full w-full object-contain object-center`}
              height={200}
              width={200}
            />
          </div>
          <h3 className={`relative mt-3 flex w-full justify-center`}>
            {slide.title}
          </h3>
        </div>
      </Link>
    </>
  );
}
