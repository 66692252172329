'use client';
import { LazyMotion } from 'framer-motion';

const loadFeatures = () =>
  import('@/assets/framer/features').then((res) => res.default);

export default function LazyMotionWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return <LazyMotion features={loadFeatures}>{children}</LazyMotion>;
}
