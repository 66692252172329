'use client';
import React, { useState, useEffect } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Link from 'next/link';
import { m } from 'framer-motion';
import { fadeRight } from '@/assets/framer/animations';

export type SlideType = {
  title: string;
  image: {
    url: string;
    alt?: string;
    placeholderUrl?: string;
  };
  anchor: string;
};

export default function ShopAnchorSlideSticky({
  slide,
  activeSlide,
  index,
}: {
  slide: SlideType;
  activeSlide: number;
  index: number;
}) {
  const [anchorTarget, setAnchorTarget] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const target = document.getElementById('anchor-' + slide.anchor);
    setAnchorTarget(target);
  }, [slide.anchor]);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (anchorTarget) {
      const offset = 120; // Adjust this offset as needed
      const targetPosition = anchorTarget.offsetTop - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
      <Link
        href={`#${slide.anchor}`}
        onClick={handleClick}
        aria-label={`Scroll to ${slide.title}`}
        className={`group relative flex items-center justify-center overflow-hidden rounded-none`}
      >
        <div>
          <h3 className={`relative mt-0 flex w-full justify-center`}>
            {slide.title}
            {activeSlide === index && (
              <m.div
                initial='hidden'
                animate={activeSlide === index ? 'visible' : 'hidden'}
                variants={fadeRight}
                className='absolute inset-x-0 bottom-0 h-0.5 bg-charcoal'
              ></m.div>
            )}
          </h3>
        </div>
      </Link>
    </>
  );
}
