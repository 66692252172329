import { getHomePageUrl } from '@/lib/pathUtils';
import React, { Fragment } from 'react';
import { House } from '@phosphor-icons/react/dist/ssr';
import { Breadcrumb } from '@/types/types';
import IKLink from '../shared/material/IKLink';

export default function Breadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  return (
    <nav
      aria-label='breadcrumb'
      className='relative z-10 flex flex-wrap items-center gap-2'
    >
      <IKLink
        className='text-base underline-offset-4 hover:underline'
        link={
          {
            url: `${getHomePageUrl()}`,
            type: 'internal',
          } as any
        }
      >
        <House weight='regular' size={18} />
      </IKLink>
      <span className='pointer-events-none select-none font-sans text-base font-normal leading-normal text-charcoal antialiased'>
        /
      </span>
      {breadcrumbs &&
        breadcrumbs.length > 0 &&
        breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => (
          <Fragment key={index}>
            {index < breadcrumbs.length - 1 ? (
              <IKLink
                className='text-base text-charcoal underline-offset-4 hover:underline'
                link={
                  {
                    url: `${getHomePageUrl()}${breadcrumb.slug}`,
                    type: 'internal',
                  } as any
                }
              >
                {breadcrumb.title}
              </IKLink>
            ) : (
              <span className='text-base font-bold text-charcoal'>
                {breadcrumb.title}
              </span>
            )}
            {index < breadcrumbs.length - 1 && (
              <span className='pointer-events-none select-none font-sans text-base font-normal leading-normal text-charcoal antialiased'>
                /
              </span>
            )}
          </Fragment>
        ))}
    </nav>
  );
}
