'use client';
import React, { useEffect, useRef } from 'react';
import Image from 'next/image';
import { Card, IKButton } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections';
import { formatPriceWithoutCents } from '@/sanity/utils/shopify/priceHelpers';
import { AcceptedPaymentMethods } from '@components/shared/groupIcons';
import { Check } from '@phosphor-icons/react/dist/ssr';
import { useInView } from 'framer-motion';
import { mixpanelProductImpression } from '@/lib/mixpanel/services/client';
import { useStatsigClient } from '@statsig/react-bindings';
import PriceSlash from '../PriceSlash';

export type CardType = {
  product: {
    variantGid: string;
    slug: string;
    categorySlug: string;
    price?: number;
    compareAtPrice?: number;
    sanityTitle: string;
  };
  title: PortableTextBlock;
  description?: PortableTextBlock;
  image: {
    url: string;
    alt?: string;
    blurDataURL: string;
  };
  features?: [
    {
      text: string;
      image: {
        url: string;
        alt?: string;
      };
    },
  ];
  compatibleWith?: [
    {
      text: string;
      product: {
        title: string;
        slug: string;
        categorySlug: string;
      };
    },
  ];
  showPaymentOptions?: boolean;
  useProductTitle?: boolean;
};

export default function ProductCard(props: { card: CardType }) {
  const card = props.card;
  const ref = useRef(null);
  const isInViewMixpanel = useInView(ref, { amount: 0.5, once: true });
  const { logEvent } = useStatsigClient();

  // Track product impression
  useEffect(() => {
    if (isInViewMixpanel && card?.product) {
      mixpanelProductImpression({ product: card.product });
      logEvent('product_impression', card.product.sanityTitle, {
        event_source: 'Online',
        currency_code: 'ZAR',
        price: `${card.product.price}`,
        name: card.product.sanityTitle,
        category: card.product.categorySlug?.replace(/-/g, ' ') ?? '',
      });
    }
  }, [isInViewMixpanel, card]);

  return (
    <>
      <div ref={ref} className='relative flex w-full flex-col'>
        <Card
          className={`relative z-10 flex flex-grow flex-col rounded-3xl border border-yellow !bg-white px-3 pb-5 pt-5 shadow-lg shadow-black/10 md:px-5 lg:pb-8`}
        >
          {card.image && card.image.url && (
            <div className='aspect-square mx-auto h-44'>
              <Image
                width={176}
                height={176}
                src={card.image.url}
                alt={card.image.alt ?? 'iKhokha product card image'}
                placeholder={'blur'}
                blurDataURL={card.image.blurDataURL ?? ''}
                className={
                  'h-full w-full object-contain object-center sm:h-full sm:w-full'
                }
              />
            </div>
          )}
          <div className='flex h-full flex-col space-y-3 px-3 lg:px-5'>
            {/* Price & Product Title */}
            <div>
              <div className='flex items-center gap-3'>
                {card.product.compareAtPrice && (
                  <PriceSlash
                    className='self-center text-base text-orange opacity-80'
                    priceSlashProps={{ className: 'bg-orange' }}
                    compareAtPrice={card.product.compareAtPrice}
                  />
                )}
                {card.product.price && (
                  <div className='text-lg font-bold text-orange'>
                    {formatPriceWithoutCents(card.product.price ?? 0)}
                  </div>
                )}
              </div>

              <h3 className='heading-sm border-b border-gray-400 pb-3 font-bold text-charcoal'>
                {card.title && !card.useProductTitle && (
                  <PortableText components={components} value={card.title} />
                )}
                {card.useProductTitle && card.product.sanityTitle}
              </h3>
            </div>
            {/* Product Description */}
            {card.description && (
              <div className='text-base text-charcoal [&_strong]:text-teal'>
                <PortableText
                  components={components}
                  value={card.description}
                />
              </div>
            )}
            {/* Product Features */}
            {card.features && (
              <div className='flex flex-wrap gap-2'>
                {card.features.map((feature, index) => (
                  <div
                    key={index}
                    className='inline-flex items-center justify-start gap-x-1.5 rounded-md bg-gray-100 px-2 py-1'
                  >
                    {feature.image && feature.image.url && (
                      <Image
                        width={20}
                        height={20}
                        src={feature.image.url}
                        alt={feature.image.alt ?? ''}
                        className={'h-5 w-5'}
                      />
                    )}
                    <div className='text-center text-xs text-charcoal'>
                      {feature.text}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {card.showPaymentOptions && (
              <AcceptedPaymentMethods
                className='max-w-[250px]'
                color='charcoal'
              />
            )}
            {/* Compatible With */}
            {card.compatibleWith && (
              <div className='s flex items-center gap-2'>
                <div className='text-xs text-charcoal [&_strong]:text-teal'>
                  Compatible with:
                </div>
                <div className='flex flex-wrap gap-2'>
                  {card.compatibleWith.map((item, index) => (
                    <IKButton
                      key={`compatible-with-${index}`}
                      link={{
                        url: `${item.product.slug}`,
                        type: 'internal',
                        document: 'product',
                        category: item.product.categorySlug,
                      }}
                      color='charcoal'
                      variant='text'
                      size='sm'
                      className='inline-flex items-center justify-start gap-x-1.5 !rounded-md border border-gray-200 bg-white !py-0.5 px-2 text-xs font-normal hover:border-teal hover:transition-all hover:duration-500'
                    >
                      <Check
                        className='h-5 w-5'
                        weight='bold'
                        color='#00C3D5'
                      />
                      {item.text ?? item.product.title}
                    </IKButton>
                  ))}
                </div>
              </div>
            )}
            {/* Action Buttons */}
            <div className='!mt-auto flex items-center gap-10 pt-5 md:pt-10 [&>a:nth-child(2)]:!flex-grow'>
              <IKButton
                link={{
                  url: `${card.product.slug}`,
                  type: 'internal',
                  document: 'product',
                  category: `${card.product.categorySlug}`,
                }}
                color='charcoal'
                variant='text'
                size='sm'
                className='bg-gradient-line relative flex overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !py-2 text-center text-base transition-all duration-500 hover:!bg-left-bottom hover:transition-all hover:duration-500 md:text-lg'
              >
                <span>Learn more</span>
              </IKButton>
              <IKButton
                link={{
                  url: `${card.product.slug}`,
                  type: 'internal',
                  document: 'product',
                  category: `${card.product.categorySlug}`,
                  addToCart: true,
                  variantGid: card.product.variantGid,
                }}
                color='yellow'
                variant='filled'
                size='md'
                className='flex flex-grow items-center justify-center gap-1 px-3 py-3 text-base sm:flex-grow-0 md:px-4 md:py-3 md:text-lg'
              >
                <span className='flex-shrink-0'>Add to cart</span>
                <svg
                  className='h-6 w-6 transform transition-transform duration-300 group-hover:translate-x-2'
                  xmlns='http://www.w3.org/2000/svg'
                  width='25'
                  height='25'
                  viewBox='0 0 25 25'
                  fill='none'
                >
                  <path
                    d='M17.5854 12.5703L10.0854 20.0704C10.0157 20.14 9.93302 20.1953 9.84197 20.233C9.75093 20.2707 9.65335 20.2901 9.5548 20.2901C9.45625 20.2901 9.35867 20.2707 9.26763 20.233C9.17658 20.1953 9.09386 20.14 9.02417 20.0704C8.95449 20.0007 8.89922 19.9179 8.8615 19.8269C8.82379 19.7359 8.80438 19.6383 8.80438 19.5397C8.80438 19.4412 8.82379 19.3436 8.8615 19.2526C8.89922 19.1615 8.95449 19.0788 9.02417 19.0091L15.9945 12.0397L9.02417 5.07035C8.88344 4.92962 8.80438 4.73875 8.80438 4.53972C8.80438 4.3407 8.88344 4.14983 9.02417 4.0091C9.1649 3.86837 9.35578 3.78931 9.5548 3.78931C9.75382 3.78931 9.94469 3.86837 10.0854 4.0091L17.5854 11.5091C17.6552 11.5788 17.7105 11.6615 17.7482 11.7525C17.786 11.8436 17.8054 11.9412 17.8054 12.0397C17.8054 12.1383 17.786 12.2359 17.7482 12.3269C17.7105 12.418 17.6552 12.5007 17.5854 12.5703Z'
                    fill='currentColor'
                  />
                </svg>
              </IKButton>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
