'use client';
import React, { useCallback, useRef, useState } from 'react';
import { m, useInView } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections/PortableTextComponents';
import { IKButton, IKLink } from '@/components/shared/material';
import { CaretRight } from '@phosphor-icons/react';
import { fadeUp } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';
import { ContactModal } from '../shared/modals';

export default function ContactBlock(props: {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock;
    subheading?: PortableTextBlock;
    letsChatButton?: {
      text: string;
    };
    modal: {
      heading: PortableTextBlock;
      textContentTop: PortableTextBlock;
      textContentBottom: PortableTextBlock;
      buttons: {
        whatsAppButton: {
          text: string;
          link: MenuItemLink;
        };
        chatbotButton: {
          text: string;
        };
        callbackButton: {
          text: string;
          link: MenuItemLink;
        };
      };
    };
  };
}) {
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const subheading = props.data?.subheading;
  const letsChatButton = props.data?.letsChatButton;
  const modal = props.data?.modal;
  const section = useRef(null);
  const isInView = useInView(section, { amount: 0.3, once: true });

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className='section-padding scroll-m-16 bg-gradient-to-b from-white via-yellow-50 to-white'
      >
        <div className='container-padding container'>
          {/* Card */}
          <div className='mx-auto max-w-[560px] space-y-4 rounded-3xl border border-yellow bg-white px-6 pb-10 text-charcoal shadow-xl shadow-charcoal/[5%]'>
            {/* Icon */}
            <div className='-mb-8 flex justify-center'>
              <div className='flex h-16 w-16 -translate-y-[50%] transform items-center justify-center rounded-full bg-yellow'>
                <m.svg
                  initial='hidden'
                  variants={fadeUp}
                  animate={isInView ? 'visible' : 'hidden'}
                  className='h-8 w-8 fill-charcoal text-charcoal'
                  xmlns='http://www.w3.org/2000/svg'
                  width='28'
                  height='41'
                  viewBox='0 0 28 41'
                  fill='none'
                >
                  <path
                    d='M17.3183 38.7504C17.7562 38.0878 17.9899 37.3088 17.9899 36.512C17.9899 35.4434 17.5701 34.4186 16.8227 33.663C16.0753 32.9075 15.0617 32.483 14.0047 32.483C13.2165 32.483 12.446 32.7193 11.7907 33.162C11.1353 33.6047 10.6245 34.2339 10.3229 34.9701C10.0212 35.7063 9.94232 36.5164 10.0961 37.298C10.2499 38.0795 10.6294 38.7974 11.1868 39.3609C11.7441 39.9244 12.4542 40.3081 13.2272 40.4636C14.0003 40.619 14.8016 40.5392 15.5298 40.2343C16.258 39.9293 16.8804 39.4129 17.3183 38.7504Z'
                    fill='currentColor'
                  />
                  <path
                    d='M0.720703 12.338C0.720703 5.67334 6.67859 0.250977 14.0047 0.250977C21.3309 0.250977 27.2887 5.67334 27.2887 12.338C27.2887 18.1733 22.719 23.0585 16.6615 24.1832V24.425C16.6615 25.1373 16.3816 25.8205 15.8834 26.3243C15.3851 26.828 14.7094 27.111 14.0047 27.111C13.3001 27.111 12.6243 26.828 12.1261 26.3243C11.6278 25.8205 11.3479 25.1373 11.3479 24.425V21.739C11.3479 21.0266 11.6278 20.3434 12.1261 19.8397C12.6243 19.336 13.3001 19.053 14.0047 19.053C18.3984 19.053 21.9751 16.0312 21.9751 12.338C21.9751 8.64473 18.3984 5.62298 14.0047 5.62298C9.61103 5.62298 6.03431 8.64473 6.03431 12.338V13.681C6.03431 14.3933 5.7544 15.0765 5.25615 15.5803C4.7579 16.084 4.08214 16.367 3.37751 16.367C2.67288 16.367 1.99711 16.084 1.49886 15.5803C1.00061 15.0765 0.720703 14.3933 0.720703 13.681V12.338Z'
                    fill='currentColor'
                  />
                </m.svg>
              </div>
            </div>

            {/* Body */}

            {heading && (
              <m.h3
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.1 }}
                className='text-center text-2xl font-bold'
              >
                <PortableText value={heading} components={components} />
              </m.h3>
            )}

            {subheading && (
              <m.div
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.2 }}
                className='mx-auto max-w-[360px] text-center'
              >
                <PortableText value={subheading} />
              </m.div>
            )}

            {letsChatButton && letsChatButton.text && (
              <m.div
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.2 }}
                className='flex items-center justify-center'
              >
                <IKButton
                  onClick={openModal}
                  color='yellow'
                  size='sm'
                  className='flex items-center justify-center gap-2 px-8'
                >
                  <span>Let&apos;s chat</span>
                  <CaretRight
                    weight='bold'
                    className='text-currentColor text-lg'
                  />
                </IKButton>
              </m.div>
            )}
          </div>
          {/* Card end */}

          {/* Contact details */}
          <m.div
            initial='hidden'
            variants={fadeUp}
            animate={isInView ? 'visible' : 'hidden'}
            transition={{ delay: 0.3 }}
            className='mx-auto max-w-[560px] px-2 py-6 text-center text-base'
          >
            <p className='text-charcoal'>
              You can also call &nbsp;
              <IKLink
                className='font-bold text-teal underline-offset-2 hover:underline'
                link={{
                  url: 'tel:0872227000',
                  type: 'external',
                  document: 'external',
                }}
              >
                087 222 7000
              </IKLink>
              &nbsp; or email &nbsp;
              <IKLink
                className='font-bold text-teal underline-offset-2 hover:underline'
                link={{
                  url: 'mailto:support@ikhokha.com',
                  type: 'external',
                  document: 'external',
                }}
              >
                support@ikhokha.com
              </IKLink>
            </p>
          </m.div>
        </div>
      </section>

      {/* Modal */}
      <ContactModal open={isModalOpen} handler={setModalOpen} modal={modal} />
    </>
  );
}
