import { formatPriceWithoutCents } from '@/sanity/utils/shopify/priceHelpers';
import { cn } from '../utils/utils';

type PriceSlashProps = {
  compareAtPrice: number;
  className?: string;
  priceSlashProps?: {
    className?: string;
  };
};

export default function PriceSlash({
  compareAtPrice,
  className,
  priceSlashProps,
}: PriceSlashProps) {
  return (
    <>
      {compareAtPrice && (
        <span
          className={cn(
            'relative flex w-max items-center justify-center self-start opacity-75',
            className
          )}
        >
          <span
            className={cn(
              'absolute h-[2px] w-[110%] rotate-[-8deg] bg-black',
              priceSlashProps?.className
            )}
          ></span>
          {formatPriceWithoutCents(compareAtPrice)}
        </span>
      )}
    </>
  );
}
