'use client';
import React from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';

import { paragraphsWithTables } from './PortableTextComponents';

export default function ContentSection(props: {
  data?: {
    sectionId: string;
    content?: PortableTextBlock[];
  };
}) {
  const sectionId = props.data?.sectionId;
  const content = props.data?.content;

  return (
    <>
      <section id={sectionId} className='section-padding scroll-m-16'>
        <div className='container-padding container'>
          <div className='prose prose-sm mx-auto sm:prose-base 2xl:prose-xl'>
            {content && (
              <PortableText value={content} components={paragraphsWithTables} />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
