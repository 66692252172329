'use client';
import React from 'react';
import Image from 'next/image';
import { IKButton } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { noParagraphs } from '@/components/sections/PortableTextComponents';
import { formatPriceWithoutCents } from '@/sanity/utils/shopify/priceHelpers';
import { MenuItemLink } from '@/types/types';
import PriceSlash from '@/components/shared/PriceSlash';

export default function ProductBannerCard(props: {
  ref?: React.MutableRefObject<null>;
  type: string;
  product?: {
    sanityTitle: string;
    productId: string;
    productGid: string;
    slug: string;
    categorySlug: string;
    price: number;
    compareAtPrice?: number;
  };
  button?: {
    text?: string;
    link: MenuItemLink;
  };
  trustImage?: {
    url?: string;
    alt?: string;
    mediaAlt?: string;
    blurDataUrl?: string;
  };
  includesText?: PortableTextBlock;
  disclaimer?: PortableTextBlock;
  priceBadgeText?: PortableTextBlock;
  className?: string;
}) {
  const {
    product,
    trustImage,
    includesText,
    className,
    ref,
    disclaimer,
    priceBadgeText,
    type,
    button,
  } = props;
  return (
    <>
      <div
        className={`scroll-m-16 md:mt-16 lg:mt-20 ${type === 'ik-tap-on-phone' ? 'xl:mt-40' : 'xl:mt-28'} ${className}`}
      >
        {disclaimer && (
          <p className='mb-2 pl-4 text-xs md:pl-0'>
            <PortableText value={disclaimer} components={noParagraphs} />
          </p>
        )}
        <div
          ref={ref}
          className={`relative z-50 space-y-2 rounded-3xl bg-white px-5 py-6 shadow-sm shadow-charcoal/10 sm:px-7 md-h:!py-2 ${type === 'ik-app' ? 'sm:max-w-[455px]' : 'sm:max-w-[395px]'} `}
        >
          <div className='space-y-0 md:space-y-2'>
            <h3 className='flex items-center gap-2 text-[34px] font-bold text-charcoal'>
              {product?.compareAtPrice && (
                <PriceSlash
                  className='mt-2 text-xl font-normal text-black'
                  priceSlashProps={{ className: 'bg-black' }}
                  compareAtPrice={product.compareAtPrice}
                />
              )}
              {product && formatPriceWithoutCents(product.price ?? 0)}
              {priceBadgeText && (
                <span className='rounded-full border-2 border-charcoal px-4 py-[1px] text-[15px] text-xs font-extrabold uppercase xs:text-sm'>
                  <PortableText
                    value={priceBadgeText}
                    components={noParagraphs}
                  />
                </span>
              )}
            </h3>
          </div>
          <div>
            {(type === 'ik-flyer' ||
              type === 'ik-mover' ||
              type === 'ik-tap-on-phone' ||
              type === 'ik-app' ||
              type === 'standard') &&
              button && (
                <IKButton
                  link={button.link}
                  color='yellow'
                  className='w-full !shadow-none'
                >
                  {button.text}
                </IKButton>
              )}
          </div>
          {includesText && (
            <p className='text-sm font-normal text-charcoal'>
              <PortableText value={includesText} components={noParagraphs} />
            </p>
          )}

          <div className='mx-auto max-w-[395px] pb-8 pt-4'>
            {trustImage && trustImage.url && (
              <Image
                quality={100}
                alt={trustImage.mediaAlt || trustImage.alt || 'iKhokha'}
                width='655'
                height='740'
                src={trustImage.url}
                className='relative z-10 h-full w-full object-contain'
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
