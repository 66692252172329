'use client';
import React from 'react';
import Image from 'next/image';
import { IKButton } from '@/components/shared/material';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { MenuItemLink } from '@/types/types';
import { formatPriceWithoutCents } from '@/sanity/utils/shopify/priceHelpers';
import { PortableText } from 'next-sanity';
import { noParagraphs } from '@/components/sections/PortableTextComponents';
import { useMatchMedia } from '@/lib/matchMedia';

export type SlideType = {
  title: string;
  product?: {
    productId?: string;
    productGid?: string;
    slug?: string;
    categorySlug?: string;
    price?: number;
  };
  image: {
    url: string;
    alt?: string;
    placeholderUrl?: string;
  };
  button: {
    text: string;
    link: MenuItemLink;
  };
  items?: [
    {
      text?: string;
      disabled: boolean;
      image: {
        url: string;
        alt?: string;
        placeholderUrl?: string;
      };
    },
  ];
  inPersonProductResultsViewType?: string;
};

export default function ProductSlide({
  slide,
  inPersonProductResultsViewType,
  isFirstSlide,
  isLastSlide,
}: {
  slide: SlideType;
  inPersonProductResultsViewType?: string;
  isFirstSlide?: boolean;
  isLastSlide?: boolean;
}) {
  const productPrice =
    slide.product?.price && slide.product.price
      ? ` ` + formatPriceWithoutCents(slide.product?.price)
      : '';

  const isDesktopResolution = useMatchMedia('(min-width: 768px)', true);

  let itemsWithImage: any = [];
  let itemsWithoutImage: any = [];
  slide.items?.forEach((item) => {
    if (item.image && item.image.url) {
      itemsWithImage.push(item);
    } else {
      itemsWithoutImage.push(item);
    }
  });

  return (
    <>
      <div
        className={`col-span-1 grid rounded-lg ${inPersonProductResultsViewType === 'multi' ? 'w-full' : 'pt-6'} text-center`}
      >
        <div
          className={`relative h-40 ${inPersonProductResultsViewType !== 'multi' && 'mr-1.5 lg:h-60'}`}
        >
          <Image
            src={slide.image.url}
            alt={slide.image.alt ?? slide.title}
            sizes='100vw'
            style={{
              width: 'auto',
              height: '100%',
            }}
            width={500}
            height={500}
            className='mx-auto'
          />
        </div>
        <div
          className={`flex items-center justify-center gap-x-2 ${inPersonProductResultsViewType !== 'multi' && 'mr-1.5'}`}
        >
          <h2
            className={`${inPersonProductResultsViewType !== 'multi' ? 'pb-4 pt-6 font-bold lg:text-3xl' : slide?.product?.slug !== 'tap-on-phone' ? 'pt-2 font-sans font-medium' : 'pt-2 font-sans text-lg font-medium md:text-xl'} text-xl md:text-2xl`}
          >
            {typeof slide.title === 'string' ? (
              slide.title
            ) : (
              <PortableText value={slide.title} components={noParagraphs} />
            )}
          </h2>
          {/* price */}
          {inPersonProductResultsViewType === 'multi' &&
            typeof slide.product?.price === 'number' && (
              <h3 className='pt-3 text-base'>
                (
                {slide.product.price > 0
                  ? formatPriceWithoutCents(slide.product.price)
                  : 'FREE'}
                )
              </h3>
            )}
        </div>
        {slide.button && slide.button.text && (
          <div
            className={`mx-auto w-full md:w-auto ${inPersonProductResultsViewType !== 'multi' && !isDesktopResolution && '!w-[95%]'}`}
          >
            <IKButton
              link={slide.button.link}
              color={'teal'}
              // @ts-ignore
              variant={'filled'}
              size={'md'}
              className={` ${inPersonProductResultsViewType != 'multi' && 'h-11 sm:h-[50px] md:px-4'} flex w-full min-w-full items-center justify-center gap-1 px-1 text-center md:px-8 lg:w-auto`}
            >
              <span className='text-base'>
                {slide.button.text + productPrice}
              </span>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewBox='0 0 25 25'
                fill='none'
              >
                <path
                  d='M17.5854 12.5703L10.0854 20.0704C10.0157 20.14 9.93302 20.1953 9.84197 20.233C9.75093 20.2707 9.65335 20.2901 9.5548 20.2901C9.45625 20.2901 9.35867 20.2707 9.26763 20.233C9.17658 20.1953 9.09386 20.14 9.02417 20.0704C8.95449 20.0007 8.89922 19.9179 8.8615 19.8269C8.82379 19.7359 8.80438 19.6383 8.80438 19.5397C8.80438 19.4412 8.82379 19.3436 8.8615 19.2526C8.89922 19.1615 8.95449 19.0788 9.02417 19.0091L15.9945 12.0397L9.02417 5.07035C8.88344 4.92962 8.80438 4.73875 8.80438 4.53972C8.80438 4.3407 8.88344 4.14983 9.02417 4.0091C9.1649 3.86837 9.35578 3.78931 9.5548 3.78931C9.75382 3.78931 9.94469 3.86837 10.0854 4.0091L17.5854 11.5091C17.6552 11.5788 17.7105 11.6615 17.7482 11.7525C17.786 11.8436 17.8054 11.9412 17.8054 12.0397C17.8054 12.1383 17.786 12.2359 17.7482 12.3269C17.7105 12.418 17.6552 12.5007 17.5854 12.5703Z'
                  fill='currentColor'
                />
              </svg>
            </IKButton>
          </div>
        )}

        {slide.items && (
          <ul
            role='list'
            className={`divide-y divide-charcoal-50/40 ${inPersonProductResultsViewType !== 'multi' ? 'py-6' : 'px-4'}`}
          >
            {itemsWithImage.map((item: any, index: number) => (
              <li
                key={`product-slide-items-with-image-${index}`}
                className={`relative flex h-24 flex-col items-center justify-center gap-y-1`}
              >
                <Image
                  src={item.image.url}
                  alt={
                    item.image.alt || item.text || slide.title || 'Feature icon'
                  }
                  width={24}
                  height={24}
                  className={`mx-auto ${inPersonProductResultsViewType === 'multi' ? 'h-8 w-32' : 'h-10 w-10'}`}
                />
                <div
                  className={`flex items-center gap-2 text-center text-sm text-charcoal ${
                    item.disabled ? 'text-opacity-20' : ''
                  }`}
                >
                  <span
                    className={`text-center font-sans ${inPersonProductResultsViewType === 'multi' ? 'whitespace-nowrap' : 'text-xs sm:text-lg'}`}
                  >
                    {item.text ?? ``}
                  </span>
                </div>
              </li>
            ))}
            {itemsWithoutImage.map((item: any, index: number) => (
              <li
                key={`product-slide-items-without-image-${index}`}
                className={`relative flex h-16 flex-col items-center justify-center gap-y-1 ${inPersonProductResultsViewType !== 'multi' && ((isDesktopResolution && (index + 1) % 2 !== 0) || (!isDesktopResolution && (index + 1) % 2 === 0)) ? `${isFirstSlide && 'sm:rounded-l-lg'} ${isLastSlide && 'sm:rounded-r-lg'} ${isFirstSlide && '-ml-4 sm:ml-auto'} ${isLastSlide && '-mr-4 sm:mr-auto'} h-16 bg-[#D9D9D9] bg-opacity-[0.2]` : 'mr-1.5 h-20 sm:mr-0'} ${inPersonProductResultsViewType !== 'multi' && (!item.image || !item.image.url) ? 'border-none' : ''}`}
              >
                <div
                  className={`flex items-center gap-2 text-center text-sm text-charcoal ${
                    item.disabled ? 'text-opacity-20' : ''
                  }`}
                >
                  <span
                    className={`text-center font-sans ${inPersonProductResultsViewType === 'multi' ? 'whitespace-nowrap' : 'text-xs sm:text-lg'}`}
                  >
                    {item.text ?? ``}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}
