import { useState, useCallback } from 'react';

interface Coordinates {
  x: number;
  y: number;
  width: number;
  height: number;
  centerX: number;
  centerY: number;
}

export function useRelativeCoordinates(referenceElement: {
  current: HTMLElement | null;
}) {
  const [coordinates, setCoordinates] = useState<Coordinates>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    centerX: 0,
    centerY: 0,
  });

  const [isValid, setIsValid] = useState(false);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!referenceElement?.current) {
        setIsValid(false);
        return;
      }

      const position = {
        x: event.pageX,
        y: event.pageY,
      };

      const offset = {
        left: referenceElement.current.offsetLeft,
        top: referenceElement.current.offsetTop,
        width: referenceElement.current.clientWidth,
        height: referenceElement.current.clientHeight,
      };

      let reference = referenceElement.current
        .offsetParent as HTMLElement | null;

      while (reference) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent as HTMLElement | null;
      }

      const newCoordinates = {
        x: position.x - offset.left,
        y: position.y - offset.top,
        width: offset.width,
        height: offset.height,
        centerX:
          offset.width !== 0
            ? (position.x - offset.left - offset.width / 2) / (offset.width / 2)
            : 0,
        centerY:
          offset.height !== 0
            ? (position.y - offset.top - offset.height / 2) /
              (offset.height / 2)
            : 0,
      };

      setCoordinates(newCoordinates);
      setIsValid(true);
    },
    [referenceElement]
  );

  return { coordinates, handleMouseMove, isValid };
}
