import React from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from './PortableTextComponents';

export default function Heading(props: {
  data: {
    _key?: string;
    heading: PortableTextBlock;
    headingStyle: string;
    anchor?: string;
  };
}) {
  // Props
  const heading = props.data.heading;
  const headingStyle = props.data.headingStyle;
  const anchor = props.data?.anchor;

  // Classes
  const headingClasses: { [key: string]: string } = {
    h1: 'heading-xl',
    h2: 'heading-xl',
    h3: 'heading-sm',
  };

  // Set the HeadingTag based on headingStyle
  const HeadingTag = headingStyle.includes('h1')
    ? 'h1'
    : headingStyle.includes('h3')
      ? 'h3'
      : 'h2';
  const headingClass = headingClasses[HeadingTag];
  return (
    <section
      id={anchor ? `anchor-${anchor}` : ''}
      className='section-padding-sm relative scroll-m-5 bg-white'
    >
      <div className='container-padding container relative mx-auto'>
        {/* Use the dynamically set HeadingTag */}
        {React.createElement(
          HeadingTag,
          {
            className: `${headingClass} relative z-10 font-display font-medium tracking-tighter text-charcoal sm:max-w-[700px] [&_.highlight]:text-pink`,
          },
          // Pass children explicitly
          heading && (
            <PortableText
              components={components}
              value={heading}
            ></PortableText>
          )
        )}
      </div>
    </section>
  );
}
