'use client';
import React, { useState } from 'react';
import Image from 'next/image';

import { m } from 'framer-motion';
export default function IKDebitCardAnimation() {
  const [fanOut] = useState(true);

  const ikDebitCards = Array.from({ length: 3 });

  const cardVariants = {
    open: (index: number) => ({
      rotate: [11, 32, 54][index],
      y: 0,
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: (index + 1) * 100,
        damping: (index + 1) * 5,
        x: {
          bounce: 0,
        },
        delay: index * 0.1,
      },
    }),
    closed: {
      rotate: 5,
      x: -40,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  const shadowVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 180,
        damping: 15,
      },
    },
    closed: {
      x: -40,
      transition: {
        duration: 0.5,
      },
    },
  };

  const textVariants = {
    open: {
      rotateZ: 270,
      rotateY: 45,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 90,
        damping: 3,
        delay: 0.4,
      },
    },
    closed: {
      rotateZ: 270,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <m.div className='relative mt-48 h-full w-full pl-8 sm:mt-[19rem] sm:!pl-32 md:!pl-0 lg:mt-0 lg:!pl-12 xs:pl-20'>
        <m.div
          initial='closed'
          animate={fanOut ? 'open' : 'closed'}
          className='absolute bottom-[2px] left-8 h-[50px] !w-[301px] origin-center sm:!left-40 sm:-bottom-[27px] sm:h-[80px] md:!left-[40px] lg:!left-16 lg:-bottom-[5px] lg:!w-[468px] xs:left-20'
          variants={shadowVariants}
        >
          <Image
            src={'/images/ik-debit-card-shadow.png'}
            alt={'test'}
            width={468}
            height={80}
          />
        </m.div>
        {ikDebitCards.map((_card, index) => (
          <>
            <m.div
              style={{
                zIndex: ikDebitCards.length - index,
              }}
              variants={cardVariants}
              className='absolute bottom-2.5 origin-bottom-right rotate-6 transform bg-transparent'
              animate={fanOut ? 'open' : 'closed'}
              initial='closed'
              key={index}
              custom={index}
            >
              <m.img
                src={'/images/ik-debit-card.png'}
                alt={'ik-debit-card'}
                width={230}
                height={340}
                className='h-[202px] w-32 sm:h-[260px] sm:w-[170px] lg:h-[325px] lg:w-[208px]'
              ></m.img>
              {/* if is last add text */}
              {index === ikDebitCards.length - 1 && (
                <m.div
                  style={{ transformOrigin: 'top center' }}
                  className='absolute -right-10 top-10 text-center sm:-right-20 sm:top-20'
                  variants={textVariants}
                  initial='closed'
                  animate={fanOut ? 'open' : 'closed'}
                >
                  <p className='font-sans text-[7.441px] font-bold tracking-[0.15em] sm:text-sm'>
                    NO MONTHLY FEE
                  </p>
                </m.div>
              )}
            </m.div>
          </>
        ))}
      </m.div>
    </>
  );
}
