'use client';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components, noParagraphs, paragraphs } from './PortableTextComponents';
import { m, useInView, AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { getFullUrl } from '@/lib/pathUtils';
import { fadeUp, fadeUpLeft } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';
import { useMatchMedia } from '@/lib/matchMedia';
import { sectionPadding } from '../utils/utils';
import YouTube, { YouTubeProps } from 'react-youtube';
import { IKButton } from '../shared/material';
import { CaretRight } from '@phosphor-icons/react';

type DisplayOptions = {
  singleImage: boolean;
  numberedList: boolean;
  padding: 'py' | 'pt' | 'pb';
};

type Item = {
  title?: string;
  description?: PortableTextBlock;
  image: {
    url: string;
    alt: string;
    blurDataUrl: string;
  };
  mobileImage: {
    url: string;
    alt: string;
    blurDataUrl: string;
  };
  icon: {
    url: string;
  };
  link: MenuItemLink;
};

type ComponentProps = {
  sectionId?: string;
  heading?: PortableTextBlock;
  textContent?: PortableTextBlock;
  video: string;
  image: {
    url: string;
    alt: string;
    blurDataUrl: string;
  };
  mobileImage: {
    url: string;
    alt: string;
    blurDataUrl: string;
  };
  items: Item[];
  button: {
    text: string;
    link: MenuItemLink;
  };
  displayOptions: DisplayOptions;
};

function ListItem(props: {
  item: Item;
  index: number;
  isLink: boolean;
  activeIndex: number | undefined;
  displayOptions: DisplayOptions;
}) {
  const item = props.item;
  const index = props.index;
  const activeIndex = props.activeIndex;
  const isLink = props.isLink;
  const displayOptions = props.displayOptions;

  return (
    <>
      {displayOptions?.numberedList ? (
        <>
          {/* Numbered list icon */}
          <div
            className={`relative -mt-1 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full transition-all`}
          >
            <span
              className={`absolute z-[3] text-xl font-bold transition-all ${
                activeIndex === index ? 'text-charcoal' : 'text-teal-600'
              }`}
            >
              {/* Number */}
              {index + 1}
            </span>
            <span
              className={`absolute z-[2] h-full w-full rounded-full transition-all duration-500 ${
                activeIndex === index ? 'bg-yellow' : 'bg-teal-50'
              }`}
            ></span>
            <span
              className={`absolute h-[calc(100%_+_6px)] w-[calc(100%_+_6px)] rounded-full ${
                activeIndex === index ? 'z-0 animate-grow bg-orange/10' : ''
              }`}
            ></span>
          </div>
        </>
      ) : (
        <>
          {item.icon.url && (
            <div
              className={`relative -mt-1 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full transition-all`}
            >
              {/* Addtional image is added as mix-blend cannot be animated, and when active index changes, you see a flash of red for the icon when the background turns yellow */}
              <Image
                className={`absolute z-[3] mix-blend-color-burn transition-all ${
                  activeIndex === index
                    ? 'opacity-0'
                    : 'opacity-100 delay-100 duration-200'
                }`}
                src={item.icon.url}
                alt={item.title ?? 'iKhokha'}
                quality={85}
                height={24}
                width={24}
              />
              <Image
                className={`relative z-[4] mix-blend-normal transition-all duration-200 ${
                  activeIndex === index ? 'opacity-100' : 'opacity-0'
                }`}
                src={item.icon.url}
                alt={item.title ?? 'iKhokha'}
                quality={85}
                height={24}
                width={24}
              />
              <span
                className={`absolute z-[2] h-full w-full rounded-full transition-all duration-500 ${
                  activeIndex === index ? 'bg-yellow' : 'bg-teal-50'
                }`}
              ></span>
              <span
                className={`absolute h-[calc(100%_+_6px)] w-[calc(100%_+_6px)] rounded-full ${
                  activeIndex === index ? 'z-0 animate-grow bg-orange/10' : ''
                }`}
              ></span>
            </div>
          )}
        </>
      )}

      <div>
        {item.title && (
          <span
            className={`flex items-center gap-2 transition-all duration-500 ${
              activeIndex === index ? '' : 'opacity-50'
            }`}
          >
            <h3 className='heading-xs font-medium'>{item.title}</h3>
            {isLink && (
              <svg
                className='transform transition duration-500 group-hover:translate-x-2 [&_path]:fill-charcoal'
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='16'
                viewBox='0 0 18 16'
                fill='none'
              >
                <path
                  d='M10.2936 15.0102L16.6956 8.61414C16.8 8.51932 16.8833 8.40405 16.9403 8.27564C16.9973 8.14722 17.0267 8.00848 17.0267 7.8682C17.0267 7.72792 16.9973 7.58917 16.9403 7.46076C16.8833 7.33235 16.8 7.21708 16.6956 7.12226L10.2936 0.726216C10.1032 0.541461 9.85294 0.429373 9.58715 0.409767C9.32136 0.390162 9.05713 0.464303 8.84118 0.619082C8.71141 0.705244 8.60331 0.819748 8.52519 0.953787C8.44708 1.08783 8.40103 1.23783 8.39058 1.39226C8.38014 1.5467 8.40558 1.70145 8.46495 1.84461C8.52432 1.98778 8.61604 2.11555 8.73305 2.21809L13.3599 6.80086L1.95662 6.80086C1.67856 6.81153 1.41548 6.92849 1.22256 7.12718C1.02964 7.32588 0.921875 7.59089 0.921875 7.8666C0.921875 8.14232 1.02964 8.40732 1.22256 8.60602C1.41548 8.80471 1.67856 8.92167 1.95662 8.93234L13.3615 8.93234L8.73467 13.5167C8.61766 13.6193 8.52594 13.747 8.46657 13.8902C8.4072 14.0334 8.38176 14.1881 8.3922 14.3425C8.40264 14.497 8.44869 14.647 8.52681 14.781C8.60492 14.9151 8.71302 15.0296 8.84279 15.1157C9.05874 15.2705 9.32297 15.3446 9.58876 15.325C9.85456 15.3054 10.1048 15.1933 10.2952 15.0086L10.2936 15.0102Z'
                  fill='none'
                />
              </svg>
            )}
          </span>
        )}
        {item.description && (
          <div
            className={`[&_a]:text-medium block max-w-[700px] text-left text-charcoal transition-all duration-500 [&_a]:text-teal hover:[&_a]:underline [&_strong]:text-teal ${
              activeIndex === index ? 'opacity-100' : 'opacity-50'
            }`}
          >
            <PortableText
              value={item.description}
              components={paragraphs}
            ></PortableText>
          </div>
        )}
      </div>
    </>
  );
}

export default function FeatureList(props: { data?: ComponentProps }) {
  const headingRef = useRef(null);
  const section = useRef(null);

  const isInView = useInView(headingRef, { amount: 0.4, once: true });
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const textContent = props.data?.textContent;
  const displayOptions = props.data?.displayOptions;
  const items = props.data?.items;
  const image = props.data?.image;
  const video = props.data?.video;
  const mobileImage = props.data?.mobileImage;
  const sectionPaddingClass = sectionPadding(displayOptions?.padding);
  const button = props.data?.button;
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);

  const isDesktopResolution = useMatchMedia('(min-width:1025px)', true);
  const [activeVideo, setActiveVideo] = useState<string | null>(null);

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '640',
    iframeClassName: 'w-full h-full',
  };

  const setActiveVideoHandler = useCallback(() => {
    if (video) {
      setActiveVideo(video);
    }
  }, [video]);

  useEffect(() => {
    if (activeVideo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [activeVideo]);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.playVideo();
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (
        (event.key === 'Escape' && activeVideo) ||
        (event.key === 'Esc' && activeVideo)
      ) {
        setActiveVideo(null);
      }
    };

    window.addEventListener('keydown', handleEscKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [activeVideo]);

  useEffect(() => {
    if (isDesktopResolution) {
      setActiveIndex(0);
    }
  }, [isDesktopResolution]);

  const handleSetActiveIndex = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  const handleSetMouseOver = useCallback((value: boolean, index?: number) => {
    setIsMouseOver(value);

    if (index !== undefined) {
      setActiveIndex(index);
    }
  }, []);

  const handleAccordionToggle = useCallback((index?: number) => {
    if (index !== undefined) {
      setActiveIndex((prevIndex) => (prevIndex === index ? undefined : index));
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (!isMouseOver && items && items.length > 0 && isDesktopResolution) {
      timer = setTimeout(() => {
        setActiveIndex((prevIndex) =>
          prevIndex === undefined ? 0 : (prevIndex + 1) % items.length
        );
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [activeIndex, items, isMouseOver, isDesktopResolution]);

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className={`${sectionPaddingClass} relative scroll-m-16 bg-white`}
      >
        <div className='container-padding container relative mx-auto'>
          <div className='grid gap-10 pt-10 sm:pt-0 lg:grid-cols-2 xl:grid-cols-5'>
            <div className='relative xl:col-span-3'>
              <div className='space-y-5'>
                {heading && (
                  <m.h2
                    initial='hidden'
                    animate={isInView ? 'visible' : 'hidden'}
                    variants={fadeUp}
                    ref={headingRef}
                    className='heading-xl relative z-10 font-display font-medium tracking-tighter text-charcoal sm:max-w-[700px] [&_strong]:text-pink'
                  >
                    {heading && (
                      <PortableText
                        components={noParagraphs}
                        value={heading}
                      ></PortableText>
                    )}
                  </m.h2>
                )}

                {textContent && (
                  <m.h3
                    initial='hidden'
                    animate={isInView ? 'visible' : 'hidden'}
                    variants={fadeUp}
                    className='relative z-10 text-base font-normal text-charcoal sm:text-lg lg:text-xl [&_br]:hidden md:[&_br]:inline [&_strong]:text-pink'
                  >
                    {heading && (
                      <PortableText
                        components={noParagraphs}
                        value={textContent}
                      ></PortableText>
                    )}
                  </m.h3>
                )}
              </div>

              <div>
                {displayOptions?.singleImage && (
                  <>
                    {mobileImage && mobileImage.url && (
                      <div onClick={() => setActiveVideoHandler()}>
                        <Image
                          className={`relative mt-5 w-full object-contain transition-all duration-500 lg:hidden ${video ? '' : ''}`}
                          src={mobileImage.url}
                          alt={mobileImage.alt ?? 'iKhokha'}
                          height={600}
                          width={600}
                          quality={85}
                          placeholder='blur'
                          blurDataURL={mobileImage.blurDataUrl}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className='mt-8 flex flex-col divide-y divide-gray-300 md:mt-10 lg:gap-5 lg:divide-y-0'>
                {items &&
                  items.length > 0 &&
                  items.map((item, index) => (
                    <m.div
                      initial='hidden'
                      animate={isInView ? 'visible' : 'hidden'}
                      transition={{
                        delay: 0.1 * index + 0.1,
                      }}
                      variants={fadeUp}
                      key={`feature-list-item-${index}`}
                    >
                      {isDesktopResolution && (
                        <>
                          {item.link.url ? (
                            <Link
                              href={`${
                                item.link.type === 'internal'
                                  ? `${getFullUrl(item.link.url)}`
                                  : item.link.url
                              }`}
                              onMouseOver={() =>
                                handleSetMouseOver(true, index)
                              }
                              onMouseLeave={() => handleSetMouseOver(false)}
                              onClick={() => handleSetActiveIndex(index)}
                              className='group hidden cursor-pointer items-start gap-4 py-2 lg:flex'
                            >
                              <ListItem
                                index={index}
                                item={item}
                                isLink={item.link.url ? true : false}
                                activeIndex={activeIndex}
                                displayOptions={
                                  displayOptions ?? {
                                    singleImage: false,
                                    numberedList: false,
                                    padding: 'py',
                                  }
                                }
                              />
                            </Link>
                          ) : (
                            <div
                              className='user-select-none group flex cursor-default items-start gap-4 py-2'
                              onMouseOver={() =>
                                handleSetMouseOver(true, index)
                              }
                              onMouseLeave={() => handleSetMouseOver(false)}
                              onClick={() => handleSetActiveIndex(index)}
                            >
                              <ListItem
                                index={index}
                                item={item}
                                isLink={item.link.url ? true : false}
                                activeIndex={activeIndex}
                                displayOptions={
                                  displayOptions ?? {
                                    singleImage: false,
                                    numberedList: false,
                                    padding: 'py',
                                  }
                                }
                              />
                            </div>
                          )}
                        </>
                      )}

                      {!isDesktopResolution && (
                        <button className='group block w-full cursor-default items-center py-4'>
                          <div
                            onClick={() => handleAccordionToggle(index)}
                            className='flex cursor-pointer items-center gap-4'
                          >
                            {displayOptions?.numberedList ? (
                              <>
                                <div
                                  className={`relative flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full transition-all`}
                                >
                                  <span
                                    className={`relative z-[4] mix-blend-normal ${
                                      activeIndex === index
                                        ? 'opacity-100'
                                        : 'opacity-80 mix-blend-color-burn'
                                    }`}
                                  >
                                    {index + 1}
                                  </span>
                                  <span
                                    className={`absolute z-[2] h-full w-full rounded-full ${
                                      activeIndex === index
                                        ? 'bg-yellow'
                                        : 'bg-teal-50'
                                    }`}
                                  ></span>
                                  <span
                                    className={`absolute h-[calc(100%_+_6px)] w-[calc(100%_+_6px)] rounded-full ${
                                      activeIndex === index
                                        ? 'z-0 animate-grow bg-orange/10'
                                        : ''
                                    }`}
                                  ></span>
                                </div>
                              </>
                            ) : (
                              <>
                                {item.icon.url && (
                                  <div
                                    className={`relative flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full transition-all`}
                                  >
                                    <Image
                                      className={`relative z-[4] mix-blend-normal ${
                                        activeIndex === index
                                          ? 'opacity-100'
                                          : 'opacity-80 mix-blend-color-burn'
                                      }`}
                                      src={item.icon.url}
                                      alt='iKhokha feature list icon'
                                      quality={85}
                                      height={24}
                                      width={24}
                                    />
                                    <span
                                      className={`absolute z-[2] h-full w-full rounded-full ${
                                        activeIndex === index
                                          ? 'bg-yellow'
                                          : 'bg-teal-50'
                                      }`}
                                    ></span>
                                    <span
                                      className={`absolute h-[calc(100%_+_6px)] w-[calc(100%_+_6px)] rounded-full ${
                                        activeIndex === index
                                          ? 'z-0 animate-grow bg-orange/10'
                                          : ''
                                      }`}
                                    ></span>
                                  </div>
                                )}
                              </>
                            )}

                            <div className='flex-grow'>
                              {item.title && (
                                <span
                                  className={`flex items-center gap-2 transition-all duration-500`}
                                >
                                  <h3
                                    className={`heading-xs text-left leading-none ${
                                      activeIndex === index
                                        ? 'font-medium'
                                        : 'font-normal'
                                    }`}
                                  >
                                    {item.title}
                                  </h3>

                                  <div className='relative ml-auto flex h-8 w-8 items-center justify-center'>
                                    {/* Minus */}
                                    <svg
                                      className={`absolute [&_path]:fill-charcoal ${
                                        activeIndex === index
                                          ? 'opacity-100'
                                          : 'opacity-0'
                                      }`}
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='23'
                                      height='3'
                                      viewBox='0 0 23 3'
                                      fill='none'
                                    >
                                      <path
                                        d='M22.1602 1.60913C22.1602 1.95723 22.0219 2.29107 21.7757 2.53721C21.5296 2.78335 21.1958 2.92163 20.8477 2.92163H1.59766C1.24956 2.92163 0.91572 2.78335 0.669579 2.53721C0.423437 2.29107 0.285156 1.95723 0.285156 1.60913C0.285156 1.26103 0.423437 0.927195 0.669579 0.681053C0.91572 0.434912 1.24956 0.296631 1.59766 0.296631H20.8477C21.1958 0.296631 21.5296 0.434912 21.7757 0.681053C22.0219 0.927195 22.1602 1.26103 22.1602 1.60913Z'
                                        fill='none'
                                      />
                                    </svg>

                                    {/* Plus */}
                                    <svg
                                      className={`absolute [&_path]:fill-charcoal ${
                                        activeIndex === index
                                          ? 'opacity-0'
                                          : 'opacity-100'
                                      }`}
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='30'
                                      height='30'
                                      viewBox='0 0 30 30'
                                      fill='none'
                                    >
                                      <path
                                        d='M26.0508 14.5906C26.0508 14.9511 25.9076 15.2969 25.6526 15.5518C25.3977 15.8067 25.0519 15.95 24.6914 15.95H16.082V24.5593C16.082 24.9199 15.9388 25.2656 15.6839 25.5205C15.4289 25.7755 15.0832 25.9187 14.7227 25.9187C14.3621 25.9187 14.0164 25.7755 13.7614 25.5205C13.5065 25.2656 13.3633 24.9199 13.3633 24.5593V15.95H4.75391C4.39338 15.95 4.04762 15.8067 3.79268 15.5518C3.53775 15.2969 3.39453 14.9511 3.39453 14.5906C3.39453 14.23 3.53775 13.8843 3.79268 13.6294C4.04762 13.3744 4.39338 13.2312 4.75391 13.2312H13.3633V4.62183C13.3633 4.2613 13.5065 3.91554 13.7614 3.6606C14.0164 3.40567 14.3621 3.26245 14.7227 3.26245C15.0832 3.26245 15.4289 3.40567 15.6839 3.6606C15.9388 3.91554 16.082 4.2613 16.082 4.62183V13.2312H24.6914C25.0519 13.2312 25.3977 13.3744 25.6526 13.6294C25.9076 13.8843 26.0508 14.23 26.0508 14.5906Z'
                                        fill='none'
                                      />
                                    </svg>
                                  </div>
                                </span>
                              )}
                            </div>
                          </div>
                          {activeIndex === index && (
                            <AnimatePresence>
                              <m.div
                                initial={{
                                  opacity: 0,
                                  height: 0,
                                }}
                                animate={{
                                  opacity: 1,
                                  height: 'auto',
                                }}
                                transition={{
                                  duration: 0.3,
                                }}
                                key={`${index}-height`}
                                exit={{
                                  opacity: 0,
                                  height: 0,
                                }}
                                className='overflow-hidden'
                              >
                                <div>
                                  {item.description && (
                                    <div
                                      className={`py-4 text-left text-charcoal [&_strong]:text-teal`}
                                    >
                                      <PortableText
                                        value={item.description}
                                        components={components}
                                      ></PortableText>
                                    </div>
                                  )}
                                  {/* @ts-ignore */}
                                  {item.mobileImage &&
                                    item.mobileImage.url &&
                                    !displayOptions?.singleImage && (
                                      <Image
                                        className={`h-full w-full object-contain transition-all duration-500 ${
                                          activeIndex === index
                                            ? 'opacity-100'
                                            : 'opacity-0'
                                        }`}
                                        src={item.mobileImage.url}
                                        alt={
                                          item.mobileImage.alt ??
                                          'iKhokha feature list image'
                                        }
                                        height={360}
                                        width={500}
                                        placeholder='blur'
                                        blurDataURL={
                                          item.mobileImage.blurDataUrl
                                        }
                                      />
                                    )}
                                </div>
                              </m.div>
                            </AnimatePresence>
                          )}
                        </button>
                      )}
                    </m.div>
                  ))}
              </div>

              {button && (
                <div className='mt-5 flex items-center gap-2'>
                  <IKButton
                    link={button.link}
                    color='yellow'
                    size='sm'
                    className='flex items-center justify-center gap-2 px-5'
                  >
                    {' '}
                    {button.text}
                    <CaretRight
                      weight='bold'
                      className='text-currentColor text-lg'
                    />
                  </IKButton>
                </div>
              )}
            </div>
            {isDesktopResolution && (
              <div className='aspect-square relative block w-full [aspect-ratio:1_/_1] xl:col-span-2'>
                {displayOptions?.singleImage ? (
                  <>
                    {image && image.url && (
                      <Image
                        onClick={() => setActiveVideoHandler()}
                        className={`absolute left-0 top-0 h-full w-full object-contain transition-all duration-500 ${video ? 'transform duration-300 hover:scale-[102%] hover:cursor-pointer' : ''}`}
                        src={image.url}
                        alt={image.alt ?? 'iKhokha'}
                        height={600}
                        width={600}
                        quality={85}
                        placeholder='blur'
                        blurDataURL={image.blurDataUrl}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {items &&
                      items.length > 0 &&
                      items.map((item, index) => (
                        <Fragment key={`item-${index}`}>
                          {/* @ts-ignore */}
                          {item.image && item.image.url && (
                            <Image
                              className={`absolute left-0 top-0 h-full w-full object-contain transition-all duration-500 ${
                                activeIndex === index
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              }`}
                              src={item.image.url}
                              alt={item.image.alt ?? 'iKhokha'}
                              height={600}
                              width={600}
                              quality={85}
                              placeholder='blur'
                              blurDataURL={item.image.blurDataUrl}
                            />
                          )}
                        </Fragment>
                      ))}
                  </>
                )}

                {/* SVG Pattern */}
                <m.svg
                  initial='hidden'
                  animate={isInView ? 'visible' : 'hidden'}
                  variants={fadeUpLeft}
                  className='absolute -left-20 top-0 hidden lg:block [&_path]:fill-yellow'
                  xmlns='http://www.w3.org/2000/svg'
                  width='114'
                  height='108'
                  viewBox='0 0 114 108'
                  fill='none'
                >
                  <path
                    d='M71.7834 28.1507C72.085 28.1513 72.3268 27.9103 72.3274 27.6084L72.3523 14.8277L85.5641 14.8535L85.5392 27.6341C85.5386 27.936 85.7794 28.178 86.081 28.1786L98.3879 28.2026L98.3623 41.3656L85.5929 41.3407C85.2913 41.3401 85.0494 41.5812 85.0489 41.8831L85.0233 54.9858L72.5958 54.9616L72.6213 41.9193C72.6218 41.6174 72.381 41.3754 72.0794 41.3748L58.9682 41.3492L58.994 28.1258L71.7834 28.1507ZM44.7896 42.1066L57.9008 42.1321L57.8746 55.5769C57.874 55.8788 58.1149 56.1208 58.4165 56.1214L71.749 56.1474L71.7236 69.1897C71.723 69.4916 71.9638 69.7336 72.2654 69.7342L85.9397 69.7608C86.2413 69.7614 86.4831 69.5204 86.4837 69.2185L86.5093 56.0957L99.2787 56.1205C99.5803 56.1211 99.8221 55.8801 99.8227 55.5782L99.8489 42.1333L112.618 42.1582C112.92 42.1588 113.162 41.9178 113.162 41.6159L113.189 27.9496C113.189 27.6477 112.949 27.4057 112.647 27.4051L100.34 27.3812L100.366 13.9967C100.367 13.6948 100.126 13.4528 99.8243 13.4522L86.3913 13.4261L86.4163 0.625287C86.4169 0.323382 86.1761 0.081388 85.8744 0.0808002L72.2202 0.0541927C71.9186 0.0536049 71.6768 0.294658 71.6762 0.596563L71.6514 13.3571L58.2184 13.3309C57.9167 13.3303 57.675 13.5714 57.6744 13.8733L57.6482 27.3181L44.8989 27.2933C44.5973 27.2927 44.3555 27.5337 44.3549 27.8356L44.3282 41.5019C44.3277 41.8038 44.5685 42.0457 44.8702 42.0463'
                    fill='none'
                  />
                  <path
                    d='M27.5985 92.521L14.789 92.496L14.8124 80.4802L27.622 80.5052L27.5985 92.521ZM0.568075 94.3401L13.8804 94.3661L13.8563 106.724C13.8557 107.026 14.0966 107.268 14.3982 107.269L28.0725 107.295C28.3741 107.296 28.6159 107.055 28.6165 106.753L28.6425 93.4086L41.8141 93.4343C42.1157 93.4348 42.3575 93.1938 42.358 92.8919L42.3847 79.2055C42.3853 78.9036 42.1445 78.6616 41.8428 78.661L28.5305 78.6351L28.5546 66.2771C28.5552 65.9752 28.3143 65.7332 28.0127 65.7327L14.3384 65.706C14.0368 65.7054 13.795 65.9465 13.7945 66.2484L13.7684 79.5926L0.596864 79.5669C0.295225 79.5663 0.0534655 79.8074 0.0528772 80.1093L0.0262462 93.7755C0.0256579 94.0774 0.266476 94.3194 0.568115 94.32'
                    fill='none'
                  />
                </m.svg>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Video Modal */}
      <AnimatePresence>
        {activeVideo && (
          <m.div
            initial={false}
            className={`fixed left-0 top-0 z-[99999] h-screen w-screen bg-black/90`}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className='container-padding container relative flex h-full items-center justify-center'>
              <button
                className='absolute right-5 top-10 z-[100] rounded-full bg-white/10 px-4 py-4 text-white/90 transition duration-200 hover:bg-white/20 md:right-0'
                onClick={() => setActiveVideo(null)}
              >
                <svg
                  className='h-4 w-4 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  width='23'
                  height='23'
                  viewBox='0 0 23 23'
                  fill='none'
                >
                  <path
                    d='M21.5362 1.30971C21.8319 1.60542 21.9981 2.00649 21.9981 2.42469C21.9981 2.84289 21.8319 3.24396 21.5362 3.53967L3.69651 21.3794C3.4008 21.6751 2.99973 21.8412 2.58153 21.8412C2.16333 21.8412 1.76226 21.6751 1.46654 21.3794C1.17083 21.0837 1.0047 20.6826 1.0047 20.2644C1.0047 19.8462 1.17083 19.4451 1.46654 19.1494L19.3063 1.30971C19.602 1.014 20.003 0.847866 20.4212 0.847866C20.8394 0.847866 21.2405 1.014 21.5362 1.30971Z'
                    fill='currentColor'
                  />
                  <path
                    d='M21.5367 21.3807C21.241 21.6764 20.8399 21.8425 20.4217 21.8425C20.0035 21.8425 19.6025 21.6764 19.3068 21.3807L1.46705 3.54099C1.17134 3.24528 1.00521 2.84421 1.00521 2.42601C1.00521 2.00781 1.17134 1.60674 1.46705 1.31103C1.76276 1.01532 2.16383 0.849186 2.58203 0.849186C3.00023 0.849186 3.4013 1.01532 3.69701 1.31103L21.5367 19.1507C21.8324 19.4465 21.9986 19.8475 21.9986 20.2657C21.9986 20.6839 21.8324 21.085 21.5367 21.3807Z'
                    fill='currentColor'
                  />
                </svg>
              </button>
              {/* @ts-ignore  */}
              <YouTube
                videoId={activeVideo}
                opts={opts}
                className='aspect-video w-full max-w-4xl overflow-hidden rounded-md bg-black [aspect-ratio:_16_/_9]'
                iframeClassName='aspect-video h-full w-full [aspect-ratio:16_/_9]'
                onReady={onPlayerReady}
              />
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
}
