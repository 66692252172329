'use client';
import React, { useRef } from 'react';
import { m, useInView } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components, paragraphs } from './PortableTextComponents';
import { fadeUp } from '@/assets/framer/animations';
import { sectionPadding } from '../utils/utils';
import Image from 'next/image';
import { IKButton } from '../shared/material';
import { ArrowRight } from '@phosphor-icons/react';
export default function TwoColTextGrid(props: {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock;
    subheading: PortableTextBlock;
    columns: {
      title: string;
      content: PortableTextBlock;
      icon: string;
    }[];
    button: {
      text: string;
      link: any;
    };
    noteText?: PortableTextBlock;
    displayOptions: {
      padding?: 'py' | 'pt' | 'pb' | 'p-0' | undefined;
      backgroundPattern?: boolean;
    };
  };
}) {
  const sectionId = props.data?.sectionId;
  const displayOptions = props.data?.displayOptions;
  const heading = props.data?.heading;
  const subHeading = props.data?.subheading;
  const section = useRef(null);

  const headingRef = useRef(null);
  const isInView = useInView(headingRef, { amount: 0.4, once: true });

  const button = props.data?.button;
  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className={`${sectionPadding(displayOptions?.padding)} relative scroll-m-16`}
      >
        <div className='absolute left-0 top-0 h-max w-full -translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-[200%] md:w-full [&_path]:fill-white'
            width='1464'
            height='34'
            viewBox='0 0 1464 34'
            fill='none'
            preserveAspectRatio='none'
          >
            <path
              d='M0 34H1464V13.9567C1462.14 14.0919 1459.76 14.2755 1456.86 14.4993L1456.79 14.5044C1400.39 18.8525 1148.67 38.2577 731.531 12.6924C315.789 -12.7874 65.0374 7.19974 7.26685 11.8046C4.35205 12.0369 1.92871 12.23 0 12.3763V34Z'
              fill='none'
            />
          </svg>
        </div>
        <div className={`container-padding container relative mx-auto`}>
          <div className='flex flex-col sm:gap-6'>
            {displayOptions?.backgroundPattern && (
              <div className='absolute right-12 top-0 hidden h-max -translate-y-[calc(100%_-_140px)] transform overflow-hidden md:block'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='114'
                  height='108'
                  viewBox='0 0 114 108'
                  fill='none'
                >
                  <path
                    d='M71.9211 28.1507C72.2228 28.1513 72.4645 27.9103 72.4651 27.6084L72.49 14.8277L85.7018 14.8535L85.6769 27.6341C85.6763 27.936 85.9171 28.178 86.2188 28.1786L98.5257 28.2026L98.5 41.3656L85.7307 41.3407C85.429 41.3402 85.1872 41.5812 85.1866 41.8831L85.1611 54.9858L72.7336 54.9616L72.759 41.9193C72.7596 41.6174 72.5188 41.3754 72.2171 41.3748L59.1059 41.3492L59.1317 28.1258L71.901 28.1507L71.9211 28.1507ZM44.9274 42.1066L58.0386 42.1321L58.0124 55.5769C58.0118 55.8788 58.2527 56.1208 58.5543 56.1214L71.8867 56.1474L71.8613 69.1897C71.8607 69.4916 72.1015 69.7336 72.4032 69.7342L86.0774 69.7608C86.3791 69.7614 86.6209 69.5204 86.6215 69.2185L86.6471 56.0957L99.4164 56.1205C99.7181 56.1211 99.9598 55.8801 99.9604 55.5782L99.9866 42.1333L112.756 42.1582C113.058 42.1588 113.299 41.9178 113.3 41.6159L113.327 27.9496C113.327 27.6477 113.086 27.4057 112.785 27.4051L100.478 27.3812L100.504 13.9967C100.505 13.6948 100.264 13.4528 99.962 13.4522L86.5291 13.4261L86.554 0.625289C86.5546 0.323384 86.3138 0.0813902 86.0122 0.0808024L72.358 0.0541948C72.0563 0.053607 71.8146 0.294661 71.814 0.596566L71.7891 13.3571L58.3561 13.3309C58.0545 13.3303 57.8127 13.5714 57.8121 13.8733L57.7859 27.3181L45.0367 27.2933C44.735 27.2927 44.4932 27.5337 44.4926 27.8356L44.466 41.5019C44.4654 41.8038 44.7063 42.0457 45.0079 42.0463'
                    fill='#FFCD00'
                  />
                  <path
                    d='M27.5986 92.5205L14.789 92.4955L14.8124 80.4797L27.622 80.5047L27.5986 92.5205ZM0.568131 94.3396L13.8804 94.3656L13.8564 106.724C13.8558 107.025 14.0966 107.267 14.3983 107.268L28.0725 107.295C28.3742 107.295 28.616 107.054 28.6166 106.752L28.6426 93.4081L41.8141 93.4338C42.1158 93.4344 42.3575 93.1933 42.3581 92.8914L42.3848 79.2051C42.3854 78.9031 42.1445 78.6612 41.8429 78.6606L28.5306 78.6346L28.5547 66.2767C28.5553 65.9747 28.3144 65.7328 28.0128 65.7322L14.3385 65.7055C14.0369 65.7049 13.7951 65.946 13.7945 66.2479L13.7685 79.5921L0.59692 79.5664C0.295281 79.5658 0.0535216 79.8069 0.0529333 80.1088L0.0263022 93.775C0.0257139 94.0769 0.266532 94.3189 0.568171 94.3195'
                    fill='#FFCD00'
                  />
                </svg>
              </div>
            )}
            <m.div
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
              variants={fadeUp}
              ref={headingRef}
              className='heading-xl relative z-10 max-w-sm font-display tracking-tighter text-charcoal sm:max-w-4xl'
            >
              {heading && (
                <PortableText
                  components={components}
                  value={heading}
                ></PortableText>
              )}
            </m.div>

            {subHeading && (
              <m.div
                initial='hidden'
                animate={isInView ? 'visible' : 'hidden'}
                variants={fadeUp}
                className='heading-xs text-charcoal sm:max-w-4xl'
              >
                <PortableText
                  components={components}
                  value={subHeading}
                ></PortableText>
              </m.div>
            )}
            <div className='grid gap-8 divide-y-2 sm:divide-y-0 md:grid-cols-2 md:gap-x-16 md:gap-y-9'>
              {props.data?.columns.length &&
                props.data?.columns?.map((column, index) => (
                  <div
                    className={`relative flex flex-col gap-y-5 pt-9 sm:gap-y-6 sm:pt-0`}
                    key={index}
                  >
                    <div className={`flex items-center gap-6`}>
                      <div
                        className={`relative -mt-1 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-teal-50 transition-all`}
                      >
                        <Image
                          src={column.icon}
                          alt={column.title}
                          quality={85}
                          height={24}
                          width={24}
                        />
                      </div>

                      <h2 className='heading-xs font-bold text-charcoal'>
                        {column.title}
                      </h2>
                    </div>

                    <div
                      className={`text-base sm:mx-16 ${index < (props.data?.columns.length || 0) - 2 ? 'pb-0 sm:border-b-2 sm:border-b-gray-300 sm:pb-9' : ''}`}
                    >
                      <PortableText
                        components={paragraphs}
                        value={column.content}
                      ></PortableText>
                    </div>
                  </div>
                ))}
            </div>

            {/* button link */}
            {button?.text && button?.link && (
              <div className='-ml-4 mt-1 flex sm:ml-12 sm:mt-0'>
                <IKButton
                  color='teal'
                  variant='text'
                  className='group flex items-center gap-2 text-left'
                  link={button?.link}
                >
                  <span>{button?.text}</span>
                  <ArrowRight
                    weight='bold'
                    className='text-currentColor text-lg transition duration-500 group-hover:translate-x-2'
                  />
                </IKButton>
              </div>
            )}

            {props.data?.noteText && (
              <div className='mt-10 flex flex-col gap-3 sm:ml-14 sm:mt-8'>
                <div className='text-xs'>
                  <PortableText
                    components={paragraphs}
                    value={props.data?.noteText}
                  ></PortableText>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
