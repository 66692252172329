'use client';
import React from 'react';
import { IKButton } from '@/components/shared/material';
import { X } from '@phosphor-icons/react';
import { Dialog, DialogBody } from '@material-tailwind/react';
import { MenuItemLink } from '@/types/types';
import { useSearchParams } from 'next/navigation';
import FunnelSaveToEmailModalInner from '@/components/business-toolkit/FunnelSaveToEmailModalInner';
import { useAtomValue } from 'jotai';
import { showSaveToEmailAtom } from '@/store/funnelStore';

export default function ContactModal(props: {
  open: boolean;
  handler: (open: boolean) => void;
  modal?: {
    heading: any;
    buttons: {
      whatsAppButton: {
        text: string;
        link: MenuItemLink;
      };
      chatbotButton: {
        text: string;
      };
      callbackButton: {
        text: string;
        link: MenuItemLink;
      };
    };
  };
}) {
  const searchParams = useSearchParams();
  const resultsShowing = searchParams.get('result') !== null;
  const showSaveToEmail = useAtomValue(showSaveToEmailAtom);

  const { open, handler } = props;

  return (
    <>
      <Dialog
        size='xs'
        open={open}
        handler={() => handler}
        className='z-50 !w-full max-w-[350px] lg:!max-w-[350px]'
      >
        <DialogBody className='space-y-10 px-6 pb-10 pt-8 md:px-8 md:pb-10 md:pt-8'>
          <div className='space-y-2'>
            <div className='flex w-full items-center justify-between'>
              {!resultsShowing ? (
                <>
                  <h3 className='text-2xl font-bold text-charcoal md:text-3xl'>
                    Leave quiz?
                  </h3>
                  <button
                    onClick={() => handler(false)}
                    aria-label='Close Modal'
                    className='inline-block p-2 hover:cursor-pointer hover:opacity-80'
                  >
                    <X weight='bold' className='text-2xl text-charcoal' />
                  </button>
                </>
              ) : (
                <div className='w-full space-y-5'>
                  {showSaveToEmail ? (
                    <FunnelSaveToEmailModalInner
                      notSubmittedTitle='Want to save your results?'
                      handler={handler}
                      hideCloseButton={true}
                      showExitButton={true}
                    />
                  ) : (
                    <div className='flex items-center justify-between'>
                      <h3 className='text-2xl font-bold text-charcoal md:text-3xl'>
                        Do you want to leave?
                      </h3>
                      <button
                        onClick={() => handler(false)}
                        aria-label='Close Modal'
                        className='inline-block p-2 hover:cursor-pointer hover:opacity-80'
                      >
                        <X weight='bold' className='text-2xl text-charcoal' />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {!resultsShowing && (
            <div
              className={`flex flex-wrap items-center gap-10 ${resultsShowing ? 'justify-center' : 'justify-between'}`}
            >
              <IKButton
                linkProps={{
                  className: 'flex-grow',
                }}
                onClick={() => handler(false)}
                variant='text'
                size='sm'
                className='bg-gradient-line relative flex flex-shrink-0 items-center justify-center gap-2 overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !py-2 transition-all duration-500 hover:!bg-left-bottom hover:transition-all hover:duration-500'
              >
                <span>No, continue quiz</span>
              </IKButton>
              <IKButton
                linkProps={{
                  className: 'flex-grow',
                }}
                link={{ document: 'home', type: 'internal', url: '/' }}
                color='yellow'
                size='sm'
                className='flex flex-shrink-0 flex-grow items-center justify-center gap-2'
              >
                <span>Yes, leave</span>
              </IKButton>
            </div>
          )}
          {resultsShowing && !showSaveToEmail && (
            <div
              className={`flex flex-wrap items-center gap-10 ${resultsShowing ? 'justify-center' : 'justify-between'}`}
            >
              <IKButton
                linkProps={{
                  className: 'flex-grow',
                }}
                onClick={() => handler(false)}
                variant='text'
                size='sm'
                className='relative flex min-w-[150px] flex-shrink-0 items-center justify-center gap-2 overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !py-2 transition-all duration-500 hover:!bg-left-bottom hover:transition-all hover:duration-500'
              >
                <span>No, stay</span>
              </IKButton>
              <IKButton
                linkProps={{
                  className: 'flex-grow',
                }}
                link={{ document: 'home', type: 'internal', url: '/' }}
                color='yellow'
                size='sm'
                className='flex max-w-[300px] flex-shrink-0 flex-grow items-center justify-center gap-2'
              >
                <span>Yes, leave</span>
              </IKButton>
            </div>
          )}
        </DialogBody>
      </Dialog>
    </>
  );
}
