'use client';
import React, { useEffect, useState } from 'react';
import { X, CaretRight, ReadCvLogo } from '@phosphor-icons/react';
import { Dialog, DialogBody } from '@material-tailwind/react';
import Image from 'next/image';
import Link from 'next/link';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections';

interface Location {
  city: string;
  region: string;
  country: string;
  remote: boolean;
  latitude: string;
  longitude: string;
}

interface Industry {
  id: string;
  label: string;
}

interface Department {
  id: string;
  label: string;
}

interface Function {
  id: string;
  label: string;
}

interface Company {
  identifier: string;
  name: string;
}

interface CustomField {
  fieldId: string;
  fieldLabel: string;
  valueId: string;
  valueLabel: string;
}

interface Posting {
  id: string;
  name: string;
  uuid: string;
  jobAdId: string;
  defaultJobAd: boolean;
  refNumber: string;
  company: Company;
  releasedDate: string;
  location: Location;
  industry: Industry;
  department: Department;
  func: Function;
  typeOfEmployment: {
    id: string;
    label: string;
  };
  experienceLevel: {
    id: string;
    label: string;
  };
  customField: CustomField[];
  visibility: string;
  ref: string;
  language: {
    code: string;
    label: string;
    labelNative: string;
  };
  creator?: {
    name: string;
  };
}

interface PostingsData {
  offset: number;
  limit: number;
  totalFound: number;
  content: Posting[];
}

export default function JobsModal(props: {
  open: boolean;
  handler: (open: boolean) => void;
  title?: PortableTextBlock;
  departmentId: string;
}) {
  const { open, handler, departmentId, title } = props;
  const [data, setData] = useState<PostingsData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.smartrecruiters.com/v1/companies/ikhokha/postings?department=${departmentId}`
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const fetchedData: PostingsData = await response.json();
        setData(fetchedData);
      } catch (error) {
        // Handle error, e.g., set an error state
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [departmentId]);

  // get value from customField where fieldId =628df305a19931251acd31ac and return the valueLabel of work policy
  const getCustomFieldValue = (customField: CustomField[], fieldId: string) => {
    const field = customField.find((field) => field.fieldId === fieldId);
    return field?.valueLabel;
  };

  return (
    <>
      <Dialog size='lg' open={open} handler={() => handler} className='!z-700'>
        <DialogBody className='max-h-svh space-y-5 overflow-y-auto px-6 pb-10 pt-8 md:px-8 md:pb-10 md:pt-8'>
          <div className='space-y-2'>
            <div className='flex items-center justify-between'>
              <h3 className='font-display text-3xl font-bold text-charcoal md:text-3xl'>
                {title && (
                  <PortableText components={components} value={title} />
                )}{' '}
                Jobs at iKhokha
              </h3>
              <button
                onClick={() => handler(false)}
                aria-label='Close Modal'
                className='inline-block p-2 hover:cursor-pointer hover:opacity-80'
              >
                <X weight='bold' className='text-2xl text-charcoal' />
              </button>
            </div>
          </div>
          {data && data.totalFound > 0 ? (
            <ul role='list' className='divide-y divide-gray-100'>
              {data.content.map((post) => (
                <li
                  key={post.id}
                  className='relative flex justify-between gap-x-6 py-5'
                >
                  <div className='flex min-w-0 gap-x-4'>
                    <Image
                      className='h-12 w-12 flex-none rounded-full bg-gray-50'
                      src={
                        'https://cdn.sanity.io/images/2xvhb43x/production/afb07d2608a911571e0e6de0ed9d7ab934fa8f55-86x86.svg'
                      }
                      alt='iKhokha Logo Square'
                      width={48}
                      height={48}
                    />
                    <div className='min-w-0 flex-auto'>
                      <p className='text-sm font-semibold leading-6 text-gray-900'>
                        <Link
                          target={'_blank'}
                          rel={'nofollow noindex'}
                          href={`https://jobs.smartrecruiters.com/IKhokha/${post.id}?trid=998bc6c9-cfbe-4db9-af4b-d7bb8407f264`}
                        >
                          <span className='absolute inset-x-0 -top-px bottom-0' />
                          {post.name}
                        </Link>
                      </p>
                      <p className='mt-1 flex text-xs leading-5 text-gray-700'>
                        {post.typeOfEmployment.label}
                        {' - '}
                        {post.location.city}, {post.location.region},{' '}
                        {getCustomFieldValue(post.customField, 'COUNTRY')}
                      </p>
                    </div>
                  </div>
                  <div className='flex shrink-0 items-center gap-x-4'>
                    <div className='hidden sm:flex sm:flex-col sm:items-end'>
                      <p className='text-sm leading-6 text-gray-900'>
                        {getCustomFieldValue(
                          post.customField,
                          '628df305a19931251acd31ac'
                        )}
                      </p>
                      {!post.releasedDate ? (
                        <p className='mt-1 text-xs leading-5 text-gray-700'>
                          Posted on{' '}
                          <time dateTime={post.releasedDate}>
                            {new Date(post.releasedDate).toLocaleDateString(
                              'en-US',
                              {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                              }
                            )}
                          </time>
                        </p>
                      ) : (
                        <div className='mt-1 flex items-center gap-x-1.5'>
                          <div className='bg-emerald-500/20 flex-none rounded-full p-1'>
                            <div className='bg-emerald-500 h-1.5 w-1.5 rounded-full' />
                          </div>
                          <p className='text-xs leading-5 text-gray-700'>
                            Posted recently
                          </p>
                        </div>
                      )}
                    </div>
                    <CaretRight
                      size={32}
                      className='h-5 w-5 flex-none text-gray-600'
                    />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <>
              <div className='relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                <ReadCvLogo
                  size={32}
                  weight='light'
                  className='mx-auto h-12 w-12 text-gray-400'
                />

                <span className='mt-2 block text-sm font-semibold text-charcoal'>
                  Currently, there are no jobs available for this department.
                </span>
              </div>
            </>
          )}
        </DialogBody>
      </Dialog>
    </>
  );
}
