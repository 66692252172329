'use client';
import React, { useRef } from 'react';
import { m, useInView } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections/PortableTextComponents';
import { FAQAccordion } from '@/components/shared/material';
import { portableTextToString } from '../utils/utils';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      type: 'spring',
    },
  },
};

const block = {
  hidden: { opacity: 0, y: 100 },
  show: { opacity: 1, y: 0 },
};

export default function FAQ(props: {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock[];
    faqs: { heading: PortableTextBlock[]; textContent: PortableTextBlock[] }[];
  };
}) {
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const faqs = props.data?.faqs;
  const section = useRef(null);
  const isInView = useInView(section, { amount: 0.4, once: true });

  const entities = (faqs ?? []).map((faq) => {
    const nameText = portableTextToString(faq.heading);
    const answerText = portableTextToString(faq.textContent);

    return {
      '@type': 'Question',
      name: nameText,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answerText,
      },
    };
  });

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [entities],
  };

  return (
    <>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
      <section
        id={sectionId}
        ref={section}
        className='section-padding mb-10 scroll-m-16 bg-gradient-to-b from-white via-yellow-50 to-white md:mb-0'
      >
        <div className='container-padding container space-y-8'>
          <div>
            <h2 className='heading-xl max-w-[560px] font-display font-medium tracking-tighter md:leading-[0.9] [&_strong]:font-bold [&_strong]:text-pink'>
              {heading && (
                <PortableText
                  components={components}
                  value={heading}
                ></PortableText>
              )}
            </h2>
          </div>

          <m.div
            initial='hidden'
            variants={container}
            animate={isInView ? 'show' : 'hidden'}
            className='grid gap-3 md:grid-cols-2 md:gap-10'
          >
            {faqs &&
              faqs.map((faq, index) => (
                <m.div variants={block} key={`faq-${index}`}>
                  <FAQAccordion
                    heading={faq.heading}
                    textContent={faq.textContent}
                  />
                </m.div>
              ))}
          </m.div>
        </div>
      </section>
    </>
  );
}
