'use client';
import React, { useRef, useState } from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from './PortableTextComponents';
import { m, useInView } from 'framer-motion';
import { fadeUp, moveUp } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';
import { ProductSlide } from '../shared/carousel';
import { Scrollbar, A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Radio } from '@material-tailwind/react';
import { useAtom } from 'jotai';
import { selectedInPersonProductAtom } from '@/store/funnelStore';

import { testIds } from '@/constants/testIds';

export type SlideType = {
  title: string;
  product?: {
    productId?: string;
    productGid?: string;
    slug?: string;
    categorySlug?: string;
    price?: number;
  };
  image: {
    url: string;
    alt: string;
    placeholderUrl?: string;
  };
  button: {
    text: string;
    link: MenuItemLink;
  };
  items?: [
    {
      text: string;
      disabled: boolean;
      image: {
        url: string;
        alt?: string;
        placeholderUrl?: string;
      };
    },
  ];
  inPersonProductResultsViewType?: string;
};

export default function CompareProductsTable(props: {
  data?: {
    _key?: string;
    sectionId?: string;
    heading?: PortableTextBlock;
    subheading?: PortableTextBlock;
    items?: SlideType[];
    inPersonProductResultsViewType?: string;
  };
}) {
  // Props
  const sectionKey = props.data?._key;
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const subheading = props.data?.subheading;
  const items = props.data?.items ?? [];
  const headingRef = useRef(null);
  const sectionRef = useRef(null);
  const isInView = useInView(headingRef, { amount: 0.4, once: true });
  const endOfSlide = useRef(null);
  const endOfSlideInView = useInView(endOfSlide, { amount: 0.4 });
  const startOfSlide = useRef(null);
  const startOfSlideInView = useInView(startOfSlide, { amount: 0.4 });
  const [selectedInPersonProduct, setSelectedInPersonProduct] = useAtom(
    selectedInPersonProductAtom
  );

  // Swiper
  const [, setSwiper] = useState<SwiperClass | null>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const slideClass = `rounded-lg overflow-hidden bg-transparent !overflow-visible !h-[unset] flex-grow aspect-square md:max-w-none ${props?.data?.inPersonProductResultsViewType === 'multi' ? 'sm:max-w-[220px] max-w-[290px] !w-[unset] ' : 'sm:max-w-[230px]  !m-0 sm:!w-[unset]'}`;
  const paginationButtonClass = `disabled:opacity-0 cursor-pointer [&_path]:fill-black fill-black h-12 w-12 shadow-lg absolute rounded-full bg-gray-50 hover:bg-gray-100 flex items-center justify-center hover:scale-105 p-2.5 `;
  const leftButton = `${paginationButtonClass} left-0 swiper-button-outline-left ${props?.data?.inPersonProductResultsViewType === 'multi' && 'bg-yellow-500 hover:bg-yellow-200 !-left-2 sm:!left-4'} `;
  const rightButton = `${paginationButtonClass} right-0 swiper-button-outline-right ${props?.data?.inPersonProductResultsViewType === 'multi' && 'bg-yellow-500 hover:bg-yellow-200 right-4'} `;

  return (
    <section
      id={sectionId}
      ref={sectionRef}
      className={`${props?.data?.inPersonProductResultsViewType !== 'multi' && 'section-padding'} relative scroll-m-16 !pb-0`}
    >
      {props?.data?.inPersonProductResultsViewType !== 'multi' && (
        <div className='absolute -top-40 left-0 right-0 -z-10 h-[40%] bg-gradient-to-b from-white via-yellow-50 to-white'></div>
      )}
      <div
        className={`overflow-hidden ${props?.data?.inPersonProductResultsViewType === 'multi' && 'flex'}`}
      >
        <div
          className={`${props?.data?.inPersonProductResultsViewType !== 'multi' ? 'container-padding container' : 'w-full'} relative mx-auto`}
        >
          {heading && (
            <m.h2
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
              variants={fadeUp}
              ref={headingRef}
              className='heading-xl relative z-10 pb-4 font-display font-medium tracking-tighter text-charcoal sm:max-w-[900px] [&_strong]:text-pink'
            >
              <PortableText
                components={components}
                value={heading}
              ></PortableText>
            </m.h2>
          )}
          {subheading && (
            <p className='text-xl leading-8'>
              <PortableText
                components={components}
                value={subheading}
              ></PortableText>
            </p>
          )}

          <m.div
            className={`relative z-10 ${props?.data?.inPersonProductResultsViewType === 'multi' && 'pl-6 xl:px-0'} `}
            initial='outOfView'
            animate={isInView ? 'visible' : 'outOfView'}
            variants={
              props?.data?.inPersonProductResultsViewType !== 'multi'
                ? moveUp
                : fadeUp
            }
          >
            <Swiper
              className={`!overflow-visible ${props?.data?.inPersonProductResultsViewType !== 'multi' ? '[&>.swiper-wrapper]:my-10 [&>.swiper-wrapper]:space-x-2 lg:[&>.swiper-wrapper]:space-x-8' : '[&>.swiper-wrapper]:my-7 [&>.swiper-wrapper]:space-x-3 lg:[&>.swiper-wrapper]:space-x-4'} [&>.swiper-wrapper]:overflow-visible md:[&>.swiper-wrapper]:space-x-4 sm:[&_.swiper-pagination]:!bottom-8`}
              modules={[Navigation, Scrollbar, A11y]}
              onInit={(swiper: SwiperClass) => {
                setSwiper(swiper);
              }}
              breakpoints={{
                0: {
                  slidesPerView:
                    props?.data?.inPersonProductResultsViewType === 'multi'
                      ? 'auto'
                      : 2.1,
                  spaceBetween: 0,
                },
                // when window width is >= 425px
                425: {
                  slidesPerView:
                    props?.data?.inPersonProductResultsViewType === 'multi'
                      ? 'auto'
                      : 2.1,
                  spaceBetween: 0,
                },
                // when window width is >= 765px
                765: {
                  slidesPerView: 'auto',
                  spaceBetween: 0,
                },
                // when window width is >= 980px
                980: {
                  slidesPerView: 'auto',
                  spaceBetween: 0,
                },
              }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
                disabledClass: 'disabled',
              }}
            >
              {items.length > 0 &&
                items.map((item, index) => (
                  <SwiperSlide
                    className={`${slideClass} ${props?.data?.inPersonProductResultsViewType === 'multi' && '!flex items-end'}`}
                    key={`${sectionKey}-product-carousel-${index} `}
                  >
                    {props?.data?.inPersonProductResultsViewType === 'multi' &&
                      index === 0 && (
                        <div
                          ref={startOfSlide}
                          className='absolute left-6 h-2 w-2'
                        ></div>
                      )}
                    {props?.data?.inPersonProductResultsViewType === 'multi' ? (
                      <div
                        data-test-qa={`${testIds.funnelProductResults}-${index}`}
                        onClick={() => setSelectedInPersonProduct(item)}
                        key={index}
                        className={`relative flex w-screen items-center ${index === items.length - 1 && 'mr-5'} ${index === 0 ? 'max-h-[800px]' : 'max-h-[585px] justify-end pt-3.5 sm:max-h-[585px]'} h-full max-w-60 flex-col gap-3.5 overflow-hidden rounded-2xl border-4 ${selectedInPersonProduct?.product?.productId === item?.product?.productId ? 'border-yellow-300' : 'border-[#9E9E9E33]/20'}`}
                      >
                        {index === 0 && (
                          <span
                            className={`flex h-8 w-full items-center justify-center ${selectedInPersonProduct?.product?.productId === item?.product?.productId ? 'bg-yellow-300 text-yellow-700' : 'bg-[#9E9E9E33]/20 text-gray-400'} text-base font-extrabold capitalize tracking-[0.2em]`}
                          >
                            RECOMMENDED
                          </span>
                        )}
                        <div
                          className={`w-11/12 rounded-xl ${selectedInPersonProduct?.product?.productId === item?.product?.productId ? 'bg-yellow-100 text-base [&_label]:font-bold [&_label]:text-yellow-700' : 'bg-[#9E9E9E33]/15 [&_label]:font-normal [&_label]:text-black'}`}
                        >
                          <Radio
                            crossOrigin={true}
                            checked={
                              selectedInPersonProduct?.product?.productId ===
                              item?.product?.productId
                            }
                            onChange={() => setSelectedInPersonProduct(item)}
                            color='yellow'
                            ripple={false}
                            name='rate'
                            className={`flex-shrink-0 before:scale-50 before:transform ${selectedInPersonProduct?.product?.productId === item?.product?.productId ? 'text-yellow-800 before:bg-yellow' : ''}`}
                            label={
                              selectedInPersonProduct?.product?.productId ===
                              item?.product?.productId
                                ? 'Selected'
                                : 'Select'
                            }
                          />
                        </div>
                        <ProductSlide
                          slide={item}
                          inPersonProductResultsViewType={
                            props?.data?.inPersonProductResultsViewType
                          }
                          key={sectionKey + 'product-slide-' + index}
                        />
                      </div>
                    ) : (
                      <ProductSlide
                        slide={item}
                        isFirstSlide={index === 0}
                        isLastSlide={index === items.length - 1}
                        key={sectionKey + 'product-slide-' + index}
                      />
                    )}
                    {props?.data?.inPersonProductResultsViewType === 'multi' &&
                      index === items.length - 1 && (
                        <div
                          ref={endOfSlide}
                          className='absolute right-6 h-2 w-2'
                        ></div>
                      )}
                  </SwiperSlide>
                ))}

              {props?.data?.inPersonProductResultsViewType === 'multi' &&
              !endOfSlideInView ? (
                <div className='pointer-events-none absolute right-0 top-0 z-10 hidden h-full w-16 bg-[linear-gradient(270deg,#FFF_0%,rgba(255,255,253,0)_100%)] lg:block'></div>
              ) : null}
              {props?.data?.inPersonProductResultsViewType === 'multi' &&
              !startOfSlideInView ? (
                <div className='pointer-events-none absolute -left-1 top-0 z-10 hidden h-full w-16 bg-[linear-gradient(270deg,rgba(255,251,229,0)_0%,#FFF_100%)] lg:block'></div>
              ) : null}
              <div
                className={`absolute ${props?.data?.inPersonProductResultsViewType === 'multi' ? 'top-[51%]' : 'left-0 top-[20%]'} z-40 block w-full -translate-y-8 transform`}
              >
                <button ref={prevRef} className={leftButton}>
                  <svg
                    className='h-5 w-5 rotate-180 transform'
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='16'
                    viewBox='0 0 17 16'
                    fill='none'
                  >
                    <path
                      d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                      fill='none'
                    />
                  </svg>
                </button>
                <button ref={nextRef} className={rightButton}>
                  <svg
                    className='h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='16'
                    viewBox='0 0 17 16'
                    fill='none'
                  >
                    <path
                      d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                      fill='none'
                    />
                  </svg>
                </button>
              </div>
            </Swiper>
          </m.div>
        </div>
      </div>
    </section>
  );
}
