import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';

import { useGeolocation } from '@uidotdev/usehooks';
import { PortableText } from '@portabletext/react';
import { components } from '@components/sections/PortableTextComponents';
import { useAtom } from 'jotai';
import { Store } from '@/types/types';
import { selectedStoreAtom } from '@/store/globalStore';

function MapContainer({
  stores,
  className,
}: {
  stores: Store[];
  className?: string;
}) {
  const containerStyle = {
    width: '100%',
    height: '300px', // Increased via media queries in SCSS
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedStore, setSelectedStore] = useAtom(selectedStoreAtom);

  const [location, setLocation] = useState({
    lat: -26.1715215,
    lng: 28.0400245,
  });
  const geoState = useGeolocation();

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map && !geoState.loading && !geoState.error) {
      // Update the location
      const newLocation = {
        lat: geoState.latitude ?? -26.195246,
        lng: geoState.longitude ?? 28.034088,
      };
      setLocation(newLocation);

      // Set the zoom level
      map.panTo(newLocation);
      map.setZoom(12);
    }
  }, [
    map,
    geoState.loading,
    geoState.error,
    geoState.latitude,
    geoState.longitude,
  ]);

  // Update location when selectedStore changes
  useEffect(() => {
    if (selectedStore) {
      setLocation(selectedStore.geoLocation);
      map?.panTo(selectedStore.geoLocation);
    }
  }, [map, selectedStore]);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div className={`map` + (className ? ` ${className}` : '')}>
      <div className='map-container'>
        {/* @ts-ignore */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location}
          zoom={11}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
          }}
        >
          {stores.map((store) =>
            store?.geoLocation?.lat !== undefined &&
            store?.geoLocation?.lng !== undefined ? (
              <Fragment key={'fragment-marker' + store._key}>
                {/* @ts-ignore */}
                <Marker
                  key={'marker' + store._key}
                  position={{
                    lat: store?.geoLocation?.lat,
                    lng: store?.geoLocation?.lng,
                  }}
                  onClick={() => setSelectedStore(store)}
                />
              </Fragment>
            ) : null
          )}
          {selectedStore && (
            <Fragment key={'fragment-info-window' + selectedStore._key}>
              {/* @ts-ignore */}
              <InfoWindow
                key={'infoWindo' + selectedStore._key}
                position={selectedStore?.geoLocation}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -30), // Adjust the offset as needed
                }}
                onCloseClick={() => setSelectedStore(null)}
              >
                {selectedStore.title && (
                  <PortableText
                    value={selectedStore.title}
                    components={components}
                  />
                )}
              </InfoWindow>
            </Fragment>
          )}
        </GoogleMap>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MapContainer);
