import React from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from './PortableTextComponents';
import { ShopAnchorSwiper } from '@/components/shared/carousel';
import { Breadcrumb } from '@/types/types';
import Breadcrumbs from '@/components/shop/Breadcrumbs';

export type SlideType = {
  title: string;
  image: {
    url: string;
    alt?: string;
    placeholderUrl?: string;
  };
  anchor: string;
};

export default function ShopNavigationAnchorBanner(props: {
  data?: {
    _key?: string;
    sectionId?: string;
    heading?: PortableTextBlock;
    items?: SlideType[];
    breadcrumbs: Breadcrumb[];
    hideBreadcrumbsId: string;
  };
}) {
  // Props
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const breadcrumbs = props.data?.breadcrumbs;
  const hideBreadcrumbsId = props.data?.hideBreadcrumbsId;

  return (
    <>
      <section
        id={sectionId}
        className='relative mb-12 scroll-m-16 overflow-hidden pt-24 md:pb-0 md:pt-28 xl:pt-32 2xl:pt-36'
      >
        <div className='container-padding container mx-auto'>
          <Breadcrumbs breadcrumbs={breadcrumbs || []} />
          <h1 className='heading-xxl lg:heading-xl relative z-10 max-w-lg font-display font-medium tracking-tighter text-charcoal md:pt-8'>
            {heading && (
              <PortableText
                components={components}
                value={heading}
              ></PortableText>
            )}
          </h1>
          <ShopAnchorSwiper
            hideId={hideBreadcrumbsId ?? ''}
            items={props.data?.items ?? []}
          />
        </div>
        {/* Bottom Divider wave */}
        <div className='bg-gradient-yellow absolute bottom-14 left-0 top-0 w-full overflow-hidden bg-gradient-to-b'></div>
        <div className='absolute bottom-14 left-0 h-max w-full translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-[200%] md:w-full [&_path]:fill-yellow-700'
            width='1464'
            height='26'
            viewBox='0 0 1464 26'
            fill='none'
            preserveAspectRatio='none'
          >
            <path
              d='M0 0V13.4014C71.6641 8.27045 311.629 -4.51068 731.531 15.2506C1153.28 35.099 1393.51 22.1175 1464 17.0324V0H0Z'
              fill='none'
            />
          </svg>
        </div>
      </section>
    </>
  );
}
