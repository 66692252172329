/**
 * Resusable helper function to get the correct padding class for a section
 * @param padding string - 'py' | 'pt' | 'pb'
 * @returns section-padding | section-padding-t | section-padding-b
 */

import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { DisplayOptionsType, PaddingTopBottomType } from '@/types/types';
import { PortableTextBlock } from 'sanity';

export const sectionPadding = (
  padding: DisplayOptionsType['padding'] | undefined
) => {
  switch (padding) {
    case 'py':
      return 'section-padding';
    case 'pt':
      return 'section-padding-t';
    case 'pb':
      return 'section-padding-b';
    case 'p-0':
      return '';
    default:
      return 'section-padding';
  }
};

//section margin
export const sectionMargin = (
  margin: DisplayOptionsType['margin'] | undefined
) => {
  switch (margin) {
    case 'my':
      return 'section-margin';
    case 'mt':
      return 'section-margin-t';
    case 'mb':
      return 'section-margin-b';
    case 'm-0':
      return '';
    default:
      return 'section-margin';
  }
};

export const sectionPaddingTopBottom = (
  padding: PaddingTopBottomType | undefined
) => {
  let paddingTop;
  let paddingBottom;
  switch (padding?.paddingTop) {
    case 'none':
      paddingTop = '';
      break;
    case 'sm':
      paddingTop = 'section-padding-t-sm';
      break;
    case 'md':
      paddingTop = 'section-padding-t';
      break;
    case 'lg':
      paddingTop = 'section-padding-t-lg';
      break;
    default:
      paddingTop = 'section-padding-t-sm';
      break;
  }

  switch (padding?.paddingBottom) {
    case 'none':
      paddingTop = '';
      break;
    case 'sm':
      paddingBottom = 'section-padding-b-sm';
      break;
    case 'md':
      paddingBottom = 'section-padding-b';
      break;
    case 'lg':
      paddingBottom = 'section-padding-b-lg';
      break;
    default:
      paddingBottom = 'section-padding-b-sm';
  }

  return `${paddingTop} ${paddingBottom}`;
};

export const getTailwindColor = (color: string) => {
  switch (color) {
    case 'white':
      return 'white';
    case 'light-yellow':
      return 'yellow-50';
    default:
      return '';
  }
};

export const backgroundClasses = (
  dispalyOptions:
    | Partial<Pick<DisplayOptionsType, 'background' | 'customBackground'>>
    | undefined
) => {
  let background = dispalyOptions?.background;
  let customBackground = dispalyOptions?.customBackground;

  if (background === 'custom') {
    if (customBackground?.type === 'gradient') {
      const [first, second, third] = customBackground.gradientStops.map(
        (stop) => getTailwindColor(stop)
      );

      const gradientString = `bg-gradient-to-b from-${first}`;

      // Handle 1, 2, or 3 color stops by adjusting the gradient direction and stop positions
      switch (customBackground.gradientStops.length) {
        case 1:
          return `${gradientString} to-${first}`; // Single color gradient
        case 2:
          return `${gradientString} to-${second}`; // Two color gradient
        case 3:
          return `${gradientString} via-${second} to-${third}`; // Three color gradient
        default:
          return ''; // Return an empty string or a default value if needed
      }
    }
  } else {
    switch (background) {
      case 'charcoal':
        return 'bg-gradient-charcoal z-[11] bg-gradient-to-b';
      case 'white':
        return 'bg-white';
      default:
        return 'bg-white';
    }
  }
};

/**
 * Converts Portable Text to plain text.
 * @param blocks - The Portable Text blocks to convert.
 * @returns The plain text representation.
 */

export function portableTextToString(
  blocks?: PortableTextBlock[] | PortableTextBlock | undefined
): string {
  if (!blocks || !Array.isArray(blocks)) {
    console.error('Invalid input: expected an array of blocks.');
    return '';
  }

  return blocks
    .map((block) => {
      // Handle simple text blocks
      if (block._type === 'block') {
        // @ts-ignore
        return block.children.map((child) => child.text).join('');
      }

      // Example handling for 'listItem':
      if (block._type === 'listItem') {
        // @ts-ignore
        return `• ${block.children.map((child) => child.text).join('')}`;
      }

      // Return an empty string for unhandled block types to safely ignore them
      return '';
    })
    .join('\n'); // Join blocks with a newline. Adjust as needed.
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}