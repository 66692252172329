type PriceObject = {
  minVariantPrice: number;
  maxVariantPrice: number;
};

export const formatPrice = (val: number): string => {
  if (val === 0) {
    return 'FREE';
  }

  return new Intl.NumberFormat('en', {
    currency: 'ZAR',
    style: 'currency',
    useGrouping: true,
  })
    .format(val)
    .replace('ZAR', 'R');
};

export const formatPriceWithoutCents = (val: number) => {
  if (val === 0) {
    return 'FREE';
  }

  const formattedPrice = new Intl.NumberFormat('en', {
    currency: 'ZAR',
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: false,
  }).format(val);

  return formattedPrice.replace('ZAR', 'R');
};

export const getPriceRange = (price: PriceObject) => {
  if (!price || typeof price?.minVariantPrice === 'undefined') {
    return 'No price found';
  }
  if (
    price.maxVariantPrice &&
    price.minVariantPrice !== price.maxVariantPrice
  ) {
    return `${formatPrice(price.minVariantPrice)} – ${formatPrice(
      price.maxVariantPrice
    )}`;
  }

  return formatPrice(price.minVariantPrice);
};
