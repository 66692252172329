import { FormStep } from '@/types/types';
import { atom, createStore } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const storage = createJSONStorage<boolean>(() => localStorage);

export const showSaveToEmailAtom = atom<boolean>(false);
export const savedResultsToEmailAtom = atomWithStorage<boolean>(
  'savedResultsToEmail',
  false,
  storage
);

export const merchantStoryAtom = atom<any>(null);
export const IndustryAtom = atom<string | null>(null);
export const articleCategoryAtom = atom<string | null>(null);
export const isHighVolumeMerchantAtom = atom<string | null>(null);

export const currentStepAtom = atom<FormStep | null>(null);
export const previousStepsAtom = atom<
  { id: string; nextStep: string | string[] }[]
>([]);
export const answersAtom = atom<{ [key: string]: string | string[] }>({});
export const nextStepAtom = atom<string | string[] | null | undefined>(
  undefined
);

export const productsToShowAtom = atom<{ [key: string]: string[] }>({});
export const productsToHideAtom = atom<{ [key: string]: string[] }>({});

export const funnelStore = createStore();

export const selectedInPersonProductAtom = atom<{ [key: string]: any } | null>(
  null
);
