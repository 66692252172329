import React, { RefObject } from 'react';
import { Check } from '@phosphor-icons/react';

// Assuming a simple type for the table node
interface TableRow {
  _type: string;
  _key: string;
  cells: string[];
}

interface TableNode {
  rows?: TableRow[]; // Adjust the type based on your actual data structure
}

export default function CompareTable({
  onScrollChange,
  table,
  tableRef,
}: {
  onScrollChange: (value: number) => void;
  table: TableNode;
  tableRef: RefObject<HTMLTableElement>;
}) {
  const rows = table?.rows || [];

  return (
    <div className='flex-shrink-0f relative z-[3] mb-10 mt-3 max-w-[705px] sm:mb-5 sm:mt-6 sm:rounded-2xl sm:border sm:border-yellow sm:bg-white sm:py-5 md:px-3 lg:max-w-[835px]'>
      <div
        onScroll={() => onScrollChange(tableRef.current?.scrollLeft || 0)}
        ref={tableRef}
        className='overflow-x-auto'
      >
        <div className='-mr-5 inline-block py-2 align-middle md:mr-0'>
          <div className='w-full max-w-[300px] rounded-lg'>
            <table className='not-prose ml-[130px] w-full min-w-full max-w-[220px] border-spacing-y-0 overflow-auto bg-white sm:ml-[330px] sm:max-w-[300px] md:ml-[378px] lg:ml-[503px]'>
              <thead className=''>
                {rows[0] && (
                  <tr className=''>
                    {rows[0].cells.map((cell, cellIndex) => (
                      <th
                        className={`text-xs sm:text-sm md:text-base ${cellIndex === 0 ? 'pointer-events-none absolute left-0 z-[4] ml-1 w-[140px] bg-white text-left after:absolute after:left-[-10px] after:top-0 after:z-[-1] after:h-full after:w-[220px] after:bg-white after:content-[""] sm:ml-3 sm:w-[180px] md:w-[180px] md:after:w-[300px] lg:w-[365px]' : ''} ${cellIndex === 1 ? 'pointer-events-none absolute left-[140px] z-[4] w-[90px] !bg-white sm:left-[180px] sm:w-[150px] md:left-[240px] lg:left-[365px]' : ''} ${cellIndex > 1 ? 'w-[100px] sm:w-[150px]' : ''} py-2 pl-1 text-sm font-bold text-charcoal sm:pl-2 md:pl-3`}
                        key={cellIndex}
                      >
                        {cell}
                      </th>
                    ))}
                  </tr>
                )}
              </thead>
              <tbody className=''>
                {rows.map((row, rowIndex: number) =>
                  rowIndex > 0 ? (
                    <tr className={``} key={row._key || rowIndex}>
                      {row.cells.map((cell, cellIndex) => (
                        <td
                          className={`text-xs sm:text-sm md:text-base ${cellIndex === 0 ? 'pointer-events-none absolute left-0 z-[3] w-[140px] border-gray-300 bg-white !text-left sm:w-[180px] md:w-[240px] lg:w-[365px]' : ''} ${cellIndex === 1 ? 'pointer-events-none absolute left-[140px] z-[3] w-[90px] !bg-white sm:left-[180px] sm:w-[150px] md:left-[240px] lg:left-[365px]' : ''} ${cellIndex > 1 ? 'w-[100px] sm:w-[150px]' : ''} py-0 pl-1 text-center text-sm font-normal text-charcoal sm:pl-2 md:pl-3`}
                          key={cellIndex}
                        >
                          <span
                            className={`${rowIndex === 1 ? 'border-t border-gray-300' : ''} ${cellIndex > 0 ? 'flex items-center justify-center' : ''} ${cellIndex === 0 ? 'flex items-center !bg-white !py-2' : ''} ${cellIndex === 1 ? 'bg-yellow-400/50' : ''} ${rowIndex !== rows.length - 1 ? '' : ''} inline-block min-h-[45px] w-full !border-b border-gray-300 bg-gray-50 px-1 py-3 sm:px-3 md:px-5`}
                          >
                            {cell === '*' ? (
                              <span
                                className={`flex h-5 w-5 items-center justify-center rounded-full ${cellIndex === 1 ? 'bg-yellow' : 'bg-gray-400'}`}
                              >
                                <Check
                                  weight='bold'
                                  className='text-sm [&_path]:stroke-[5px] [&_path]:[stroke-linecap:_round] [&_path]:[stroke:_#000]'
                                />
                              </span>
                            ) : (
                              cell
                            )}
                          </span>
                        </td>
                      ))}
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
