'use client';

import {
  noParagraphs,
  paragraphs,
} from '@/components/sections/PortableTextComponents';
import { getForm } from '@/lib/hubspot/services/formService';

import { useState, useEffect } from 'react';
import FormFields from './FormFields';
import FormSubtext from './FormSubtext';
import FormWrapper from './FormWrapper';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
export default function FormClient(props: {
  form: {
    heading?: PortableTextBlock;
    textContent?: PortableTextBlock;
    subText?: PortableTextBlock;
    formId?: string;
    formType?: string;
  };
  formClassName?: string;
  subTextClassName?: string;
}) {
  const [form, setForm] = useState<any>(null);
  useEffect(() => {
    const getFormContent = async () => {
      if (props?.form?.formId) {
        const form = await getForm(props.form.formId);
        setForm(form);
      }
    };
    getFormContent();
  }, [props]);

  if (!form) {
    return (
      <div
        className={`${props.formClassName} mx-auto w-full max-w-xl  rounded-3xl bg-white p-8 px-6 py-7 shadow-lg sm:p-8 md:p-10`}
      >
        <div className='mb-4 h-6 animate-pulse rounded bg-gray-300'></div>
        <div className='mb-8 h-4 animate-pulse rounded bg-gray-300'></div>

        <div className='space-y-4'>
          <div className='h-10 animate-pulse rounded bg-gray-300'></div>
          <div className='h-10 animate-pulse rounded bg-gray-300'></div>
          <div className='h-10 animate-pulse rounded bg-gray-300'></div>
          <div className='h-32 animate-pulse rounded bg-gray-300'></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={` ${props.formClassName} mt-10`}>
        <FormWrapper form={form} formType={props?.form?.formType}>
          <div className='mx-auto mt-2 max-w-xl rounded-3xl border border-gray-200 bg-white px-6 py-7 sm:p-8 md:p-10'>
            <div className='mb-10 space-y-5'>
              {props?.form?.heading && (
                <h2 className='heading-sm font-display font-bold text-charcoal'>
                  <PortableText
                    value={props?.form?.heading}
                    components={noParagraphs}
                  />
                </h2>
              )}
              {props?.form?.textContent && (
                <div className='text-charcoal'>
                  <PortableText
                    value={props?.form?.textContent}
                    components={paragraphs}
                  />
                </div>
              )}
            </div>
            <FormFields form={form} />
          </div>
        </FormWrapper>
        {props?.form?.subText && (
          <div
            className={`mx-auto mt-8 max-w-md px-5 ${props?.subTextClassName}`}
          >
            <FormSubtext subText={props?.form?.subText} />
          </div>
        )}
      </div>
    );
  }
}
