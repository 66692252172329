'use client';
import React from 'react';
export default function TermsConditionsSection(props: {
  data?: {
    sectionId: string;
    content?: string;
  };
}) {
  const sectionId = props.data?.sectionId;
  const content = props.data?.content;

  return (
    <>
      <section id={sectionId} className='section-padding scroll-m-16'>
        <div className='container-padding container'>
          <div className='mx-auto'>
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
          </div>
        </div>
      </section>
    </>
  );
}
