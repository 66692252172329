'use client';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import {
  components,
  noParagraphs,
  paragraphsWithTables,
} from './PortableTextComponents';
import { m, useInView, AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import { fadeUp } from '@/assets/framer/animations';
import { useMatchMedia } from '@/lib/matchMedia';
import { sectionPadding } from '../utils/utils';

type DisplayOptions = {
  padding: 'py' | 'pt' | 'pb';
};

type Accordion = {
  icon?: string;
  title?: PortableTextBlock;
  layout: 'oneColumn' | 'twoColumns';
  textContent: PortableTextBlock;
  textContentSecondColumn?: PortableTextBlock;
};

type ComponentProps = {
  sectionId?: string;
  heading?: PortableTextBlock;
  accordionItems: Accordion[];
  displayOptions: DisplayOptions;
};

function Accordion(props: {
  item: Accordion;
  index: number;
  activeIndex: number | undefined;
  setActiveIndex: (index: number) => void;
}) {
  const item = props.item;
  const index = props.index;
  const activeIndex = props.activeIndex;
  const setActiveIndex = props.setActiveIndex;
  const accordion = useRef<HTMLButtonElement | null>(null);
  const isDesktopResolution = useMatchMedia('(min-width:960px)', true);
  const [hasMounted, setHasMounted] = useState(false);
  const isInView = useInView(accordion);

  // This effect runs only once after the initial render
  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handleAccordionToggle = useCallback(
    (index?: number) => {
      if (index !== undefined) {
        setActiveIndex(index);

        if (isDesktopResolution || !hasMounted || !isInView) {
          return;
        }

        const currentScrollPosition = window.scrollY;
        const accordionTopPosition =
          accordion.current?.getBoundingClientRect().top ?? 0;
        const offsetPosition = currentScrollPosition + accordionTopPosition;

        if (accordion?.current) {
          window.scrollTo(0, offsetPosition - 120);
        }
      }
    },
    [setActiveIndex, isDesktopResolution, hasMounted, isInView]
  );

  return (
    <>
      <button
        ref={accordion}
        aria-expanded={activeIndex === index}
        onClick={() => handleAccordionToggle(index)}
        className='group block w-full cursor-pointer scroll-py-20 items-center py-4 outline-none'
      >
        <div className='flex items-center gap-4'>
          <>
            {item.icon ? (
              <>
                <div
                  className={`relative -mt-1 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full transition-all`}
                >
                  {/* Addtional image is added as mix-blend cannot be animated, and when active index changes, you see a flash of red for the icon when the background turns yellow */}
                  <Image
                    className={`absolute z-[3] mix-blend-color-burn transition-all ${
                      activeIndex === index
                        ? 'opacity-0'
                        : 'opacity-100 delay-100 duration-200'
                    }`}
                    src={item.icon}
                    alt={''}
                    quality={85}
                    height={24}
                    width={24}
                  />
                  <Image
                    className={`relative z-[4] mix-blend-normal transition-all duration-200 ${
                      activeIndex === index ? 'opacity-100' : 'opacity-0'
                    }`}
                    src={item.icon}
                    alt={'iKokhha accordion icon'}
                    quality={85}
                    height={24}
                    width={24}
                  />
                  <span
                    className={`absolute z-[2] h-full w-full rounded-full transition-all duration-500 ${
                      activeIndex === index ? 'bg-yellow' : 'bg-teal-50'
                    }`}
                  ></span>
                  <span
                    className={`absolute h-[calc(100%_+_6px)] w-[calc(100%_+_6px)] rounded-full ${
                      activeIndex === index
                        ? 'z-0 animate-grow bg-orange/10'
                        : ''
                    }`}
                  ></span>
                </div>
              </>
            ) : (
              <>
                {/* Numbered list icon */}
                <div
                  className={`relative -mt-1 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full transition-all`}
                >
                  <span
                    className={`absolute z-[3] text-xl font-bold transition-all ${
                      activeIndex === index ? 'text-charcoal' : 'text-teal-600'
                    }`}
                  >
                    {/* Number */}
                    {index + 1}
                  </span>
                  <span
                    className={`absolute z-[2] h-full w-full rounded-full transition-all duration-500 ${
                      activeIndex === index ? 'bg-yellow' : 'bg-teal-50'
                    }`}
                  ></span>
                  <span
                    className={`absolute h-[calc(100%_+_6px)] w-[calc(100%_+_6px)] rounded-full ${
                      activeIndex === index
                        ? 'z-0 animate-grow bg-orange/10'
                        : ''
                    }`}
                  ></span>
                </div>
              </>
            )}
          </>

          <div className='flex-grow'>
            {item.title && (
              <span
                className={`flex items-center gap-2 transition-all duration-500`}
              >
                <h3
                  className={`heading-xs text-left leading-none ${
                    activeIndex === index ? 'font-medium' : 'font-normal'
                  }`}
                >
                  <PortableText components={noParagraphs} value={item.title} />
                </h3>

                <div className='relative ml-auto flex h-8 w-8 items-center justify-center'>
                  {/* Minus */}
                  <svg
                    className={`absolute [&_path]:fill-charcoal ${
                      activeIndex === index ? 'opacity-100' : 'opacity-0'
                    }`}
                    xmlns='http://www.w3.org/2000/svg'
                    width='23'
                    height='3'
                    viewBox='0 0 23 3'
                    fill='none'
                  >
                    <path
                      d='M22.1602 1.60913C22.1602 1.95723 22.0219 2.29107 21.7757 2.53721C21.5296 2.78335 21.1958 2.92163 20.8477 2.92163H1.59766C1.24956 2.92163 0.91572 2.78335 0.669579 2.53721C0.423437 2.29107 0.285156 1.95723 0.285156 1.60913C0.285156 1.26103 0.423437 0.927195 0.669579 0.681053C0.91572 0.434912 1.24956 0.296631 1.59766 0.296631H20.8477C21.1958 0.296631 21.5296 0.434912 21.7757 0.681053C22.0219 0.927195 22.1602 1.26103 22.1602 1.60913Z'
                      fill='none'
                    />
                  </svg>

                  {/* Plus */}
                  <svg
                    className={`absolute [&_path]:fill-charcoal ${
                      activeIndex === index ? 'opacity-0' : 'opacity-100'
                    }`}
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    viewBox='0 0 30 30'
                    fill='none'
                  >
                    <path
                      d='M26.0508 14.5906C26.0508 14.9511 25.9076 15.2969 25.6526 15.5518C25.3977 15.8067 25.0519 15.95 24.6914 15.95H16.082V24.5593C16.082 24.9199 15.9388 25.2656 15.6839 25.5205C15.4289 25.7755 15.0832 25.9187 14.7227 25.9187C14.3621 25.9187 14.0164 25.7755 13.7614 25.5205C13.5065 25.2656 13.3633 24.9199 13.3633 24.5593V15.95H4.75391C4.39338 15.95 4.04762 15.8067 3.79268 15.5518C3.53775 15.2969 3.39453 14.9511 3.39453 14.5906C3.39453 14.23 3.53775 13.8843 3.79268 13.6294C4.04762 13.3744 4.39338 13.2312 4.75391 13.2312H13.3633V4.62183C13.3633 4.2613 13.5065 3.91554 13.7614 3.6606C14.0164 3.40567 14.3621 3.26245 14.7227 3.26245C15.0832 3.26245 15.4289 3.40567 15.6839 3.6606C15.9388 3.91554 16.082 4.2613 16.082 4.62183V13.2312H24.6914C25.0519 13.2312 25.3977 13.3744 25.6526 13.6294C25.9076 13.8843 26.0508 14.23 26.0508 14.5906Z'
                      fill='none'
                    />
                  </svg>
                </div>
              </span>
            )}
          </div>
        </div>
        {activeIndex === index && (
          <AnimatePresence>
            <m.div
              initial='open'
              animate='open'
              transition={{ duration: 0.3, ease: [0.5, 0.62, 0.4, 0.98] }}
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              key={`${index}-height`}
              exit='collapsed'
              className='overflow-hidden'
            >
              <div>
                {item.textContent && (
                  <div className={`py-4`}>
                    <div
                      className={`${item.layout === 'twoColumns' ? 'grid gap-1 md:grid-cols-2 md:gap-10' : ''} prose prose-sm !w-full !max-w-[unset] px-2 text-left text-charcoal sm:prose-base 2xl:prose-lg sm:pt-0 [&_.highlight>strong]:text-pink [&_.highlight]:text-pink [&_a]:underline`}
                    >
                      <div>
                        <PortableText
                          value={item.textContent}
                          components={paragraphsWithTables}
                        ></PortableText>
                      </div>
                      {item.layout === 'twoColumns' &&
                        item.textContentSecondColumn && (
                          <>
                            <div>
                              <PortableText
                                value={item.textContentSecondColumn}
                                components={paragraphsWithTables}
                              ></PortableText>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </m.div>
          </AnimatePresence>
        )}
      </button>
    </>
  );
}

export default function AccordionSection(props: { data?: ComponentProps }) {
  const headingRef = useRef(null);
  const section = useRef(null);

  const isInView = useInView(headingRef, { amount: 0.4, once: true });
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const displayOptions = props.data?.displayOptions;
  const items = props.data?.accordionItems;
  const sectionPaddingClass = sectionPadding(displayOptions?.padding);

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const isDesktopResolution = useMatchMedia('(min-width:960px)', true);

  useEffect(() => {
    if (isDesktopResolution) {
      setActiveIndex(0);
    }
  }, [isDesktopResolution]);

  const handleSetActiveIndex = useCallback(
    (index: number) => {
      if (activeIndex === index) {
        setActiveIndex(undefined);
      } else setActiveIndex(index);
    },
    [setActiveIndex, activeIndex]
  );

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className={`${sectionPaddingClass} relative scroll-m-16 bg-white`}
      >
        <div className='container-padding container relative mx-auto'>
          <div className='relative mt-8 md:mt-0 xl:col-span-3'>
            <m.h2
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
              variants={fadeUp}
              ref={headingRef}
              className='heading-xl relative z-10 font-display font-medium tracking-tighter text-charcoal sm:max-w-[700px] [&_strong]:text-pink'
            >
              {heading && (
                <PortableText
                  components={components}
                  value={heading}
                ></PortableText>
              )}
            </m.h2>

            <div className='mb-12 mt-8 flex flex-col divide-y divide-gray-300 border-y border-gray-300 md:mb-5 md:mt-10'>
              {items &&
                items.length > 0 &&
                items.map((item: Accordion, index: number) => (
                  <m.div
                    // initial='hidden'
                    animate={isInView ? 'visible' : 'hidden'}
                    transition={{
                      delay: 0.1 * index + 0.1,
                    }}
                    variants={fadeUp}
                    key={`accordion-${index}`}
                    className=''
                  >
                    <Accordion
                      item={item}
                      index={index}
                      activeIndex={activeIndex}
                      setActiveIndex={handleSetActiveIndex}
                    />
                  </m.div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
