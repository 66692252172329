'use client';
import React, { useMemo, useRef, useState } from 'react';
import { m, useInView } from 'framer-motion';
import { sectionPadding } from '../utils/utils';
import Image from 'next/image';
import { fadeUp } from '@/assets/framer/animations';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { MenuItemLink } from '@/types/types';
import { IKLink } from '../shared/material';
import { PortableText, PortableTextBlock } from 'next-sanity';
import { components } from './PortableTextComponents';
export default function RatingBanner(props: {
  data?: {
    sectionId?: string;
    ratedFieldTagItems?: {
      ratedNumber?: number;
      tag?: string;
      source: {
        image?: {
          url?: string;
          alt?: string;
          mediaAlt?: string;
        };
        link: MenuItemLink;
        text?: PortableTextBlock;
        type?: string;
      };
    }[];
    displayOptions?: {
      padding: 'py' | 'pt' | 'pb' | 'p-0' | undefined;
      ratingColor: string;
      delayTimer: number;
    };
  };
}) {
  const sectionId = props.data?.sectionId;

  const section = useRef(null);
  const displayOptions = props.data?.displayOptions;
  const isInView = useInView(section, { amount: 0.4, once: true });

  const [index, setIndex] = useState(0);

  const swiperRef = useRef(null);

  const currentRatedFieldTagItem = useMemo(() => {
    if (!props.data?.ratedFieldTagItems) return null;
    return props.data?.ratedFieldTagItems[index];
  }, [index, props.data?.ratedFieldTagItems]);

  let textColor = 'text-teal';
  let backgroundColor = 'bg-teal-50';
  if (displayOptions?.ratingColor === 'yellow') {
    textColor = 'text-yellow';
    backgroundColor = 'bg-yellow-50';
  }
  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className={`${sectionPadding(displayOptions?.padding)} relative mt-9 scroll-m-16 bg-white sm:mt-6 xl:mt-8`}
      >
        <m.div
          className={`relative mx-auto flex items-center justify-center`}
          variants={fadeUp}
          initial='hidden'
          animate={isInView ? 'visible' : 'hidden'}
        >
          <div className='flex w-full flex-col items-center justify-center gap-3.5 sm:mx-0 sm:gap-7 lg:flex-row'>
            {currentRatedFieldTagItem?.ratedNumber && (
              <div className='sm:mt-2'>
                <span className='font-sans text-xl font-medium text-charcoal sm:text-[33px]'>
                  Rated
                </span>
                <span
                  className={`${textColor} ml-1.5 font-sans text-3xl font-extrabold sm:ml-2 sm:text-[48px]`}
                >
                  #{currentRatedFieldTagItem?.ratedNumber}
                </span>
                <span className='ml-1.5 font-sans font-medium text-charcoal sm:ml-2 sm:text-[33px]'>
                  in
                </span>
              </div>
            )}

            {props.data?.ratedFieldTagItems &&
              props.data?.ratedFieldTagItems?.length > 0 && (
                <div
                  className={`flex h-[64px] min-w-[331px] cursor-default items-center justify-center rounded-xl ${backgroundColor} text-center sm:h-[100px] sm:w-[525px] sm:rounded-3xl`}
                >
                  <Swiper
                    ref={swiperRef}
                    onProgress={(swiper: { realIndex: number }) => {
                      setIndex(swiper.realIndex);
                    }}
                    allowTouchMove={false}
                    className='h-[inherit]'
                    direction={'vertical'}
                    autoplay={{
                      delay: displayOptions?.delayTimer || 2500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    loop={true}
                  >
                    {props?.data?.ratedFieldTagItems.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className={`${textColor} flex h-full items-center justify-center font-display text-[32px] font-semibold tracking-[-0.05em] sm:text-6xl`}
                        >
                          {' '}
                          {item.tag}
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            {!currentRatedFieldTagItem?.source?.type ||
            currentRatedFieldTagItem?.source?.type === 'image' ? (
              <div className='inline-flex items-center gap-x-3 text-center sm:mt-2.5'>
                <span className='text-xl font-medium sm:text-[33px]'>on</span>
                {currentRatedFieldTagItem?.source?.image?.url ? (
                  <m.div
                    whileHover={{
                      scale: 1.04,
                      transition: { duration: 0.3 },
                    }}
                    className='mt-2'
                  >
                    <IKLink
                      link={currentRatedFieldTagItem?.source?.link}
                      className=''
                    >
                      <Image
                        src={currentRatedFieldTagItem?.source?.image?.url}
                        alt={
                          currentRatedFieldTagItem?.source?.image?.mediaAlt ||
                          currentRatedFieldTagItem?.source?.image?.alt ||
                          ''
                        }
                        width={190}
                        height={48}
                        className='h-[30px] w-[119px] sm:h-[48px] sm:w-[190px]'
                      />
                    </IKLink>
                  </m.div>
                ) : null}
              </div>
            ) : (
              <div className='heading-md inline-flex items-center gap-x-3 text-center'>
                {currentRatedFieldTagItem?.source?.text && (
                  <PortableText
                    value={currentRatedFieldTagItem?.source?.text}
                    components={components}
                  />
                )}
              </div>
            )}
          </div>
        </m.div>
      </section>
    </>
  );
}
