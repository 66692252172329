import React, { useCallback, useEffect, useState } from 'react';
import { useMeasure, useWindowSize } from '@uidotdev/usehooks';
import { useMotionValue, animate, m, useReducedMotion } from 'framer-motion';
import Image from 'next/image';

// Debounce function
const debounce = <F extends (...args: any[]) => any>(
  func: F,
  wait: number
): ((...args: Parameters<F>) => void) => {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: Parameters<F>) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export default function InfiniteCarousel({
  images,
  direction,
  duration,
  className,
  startingPosition,
}: {
  images: {
    id?: string;
    url?: string;
    alt?: string;
    blurDataUrl?: string;
  }[];
  direction?: 'up' | 'down';
  duration?: number;
  className?: string;
  startingPosition?: number;
}) {
  const isReducedMotion = useReducedMotion();

  const [gridContainerRef, { height }] = useMeasure();
  const windowSize = useWindowSize();
  const yTranslation = useMotionValue(startingPosition ?? 0);
  const [gap, setGap] = useState(20);

  const updateGap = useCallback(() => {
    const width = windowSize.width;
    if (width) {
      if (width >= 1280) {
        setGap(42); // xl
      } else if (width >= 1024) {
        setGap(35); // lg
      } else if (width >= 768) {
        setGap(25); // md
      } else {
        setGap(20); // default
      }
    }
  }, [windowSize.width]);

  useEffect(() => {
    // Initial gap setting
    updateGap();

    // Debounced resize handler
    const debouncedUpdateGap = debounce(updateGap, 250);

    // Update gap on window resize
    window.addEventListener('resize', debouncedUpdateGap);
    return () => {
      window.removeEventListener('resize', debouncedUpdateGap);
    };
  }, [updateGap]);

  useEffect(() => {
    if (height) {
      let controls;
      // height of container divided by 2, minus 20px for gap between images
      let directioinMultiplier = direction === 'down' ? 1 : -1;
      let finalPosition =
        (height / 2 + gap / 2) * directioinMultiplier + (startingPosition ?? 0);

      controls = animate(yTranslation, [startingPosition ?? 0, finalPosition], {
        ease: 'linear',
        duration: duration ?? 20,
        repeat: Infinity,
        repeatType: 'loop',
        repeatDelay: 0,
      });

      return controls?.stop; // Cleanup
    }
  }, [yTranslation, direction, height, startingPosition, duration, gap]);

  return (
    <>
      <m.div
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.5 }}
        className={`relative w-full ${className}`}
      >
        <m.div
          ref={gridContainerRef}
          style={{ y: yTranslation }}
          className={`absolute left-0 grid w-full gap-[20px] md:gap-[25px] lg:gap-[35px] xl:gap-[42px] ${direction === 'down' ? 'bottom-0' : 'top-0'}`}
        >
          {[...images, ...images].map((image, index) => (
            <m.div
              initial={{ scale: isReducedMotion ? 1 : 1 }}
              animate={{ scale: 1 }}
              transition={{
                delay: 0.05,
                ease: 'easeOut',
                type: 'spring',
                damping: 15,
                stiffness: 100,
              }}
              key={`infinite-carousel-${index}-${image.id}`}
            >
              <Image
                className='aspect-[6/7] h-full w-full rounded-[20px] object-cover shadow-[-2px_21px_31px_#DEA403]'
                width={450}
                height={530}
                loading={index < 2 ? 'eager' : 'lazy'}
                blurDataURL={index > 1 ? image?.blurDataUrl : ''}
                src={image?.url || ''}
                alt={'image-carousel'}
              />
            </m.div>
          ))}
        </m.div>
      </m.div>
    </>
  );
}
