import { getForm } from '@/lib/hubspot/services/formService';
import React, { Suspense } from 'react';
import {
  FormBackButton,
  FormFields,
  FormSubtext,
  FormWrapper,
} from '../shared/form';
import { PortableText } from '@portabletext/react';
import { noParagraphs, paragraphs } from './PortableTextComponents';
import { PortableTextBlock } from 'sanity';
import { Form as FormType } from '@/types/types';

interface Props {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock;
    textContent?: PortableTextBlock;
    subText?: PortableTextBlock;
    formId?: string;
    formType?: string;
  };
}

async function FormContent({ data }: Props) {
  if (!data || !data?.formId) return null;
  const heading = data?.heading;
  const textContent = data?.textContent;
  const subText = data?.subText;
  const form = await getForm(data?.formId ?? '');
  const formType = data?.formType;
  return (
    <>
      {
        <>
          {/* Back button */}
          {formType !== 'thirdPartySalesLeadCapture' && <FormBackButton />}

          {/* @ts-ignore */}
          <FormWrapper form={form} formType={formType}>
            <div className='mx-auto mt-2 max-w-xl rounded-3xl border border-gray-200 bg-white px-6 py-7 sm:p-8 md:p-10'>
              <div className='mb-10 space-y-5'>
                {heading && (
                  <h2 className='heading-sm font-display font-bold text-charcoal'>
                    <PortableText value={heading} components={noParagraphs} />
                  </h2>
                )}
                {textContent && (
                  <div className='text-charcoal'>
                    <PortableText value={textContent} components={paragraphs} />
                  </div>
                )}
              </div>
              <FormFields form={form as FormType} />
            </div>
          </FormWrapper>

          {subText && (
            <div className='mx-auto mt-8 max-w-md px-5'>
              <FormSubtext subText={subText} />
            </div>
          )}
        </>
      }
    </>
  );
}

export default function Form({ data }: Props) {
  return (
    <section
      id={data?.sectionId}
      className='section-padding bg-gradient-yellow scroll-m-16 bg-gradient-to-b'
    >
      <div className='container-padding mx-auto max-w-7xl py-20'>
        <div>
          <Suspense
            fallback={
              <div
                className={`mx-auto w-full max-w-xl rounded-3xl bg-white p-8 px-6 py-7 shadow-lg sm:p-8 md:p-10`}
              >
                <div className='mb-4 h-6 animate-pulse rounded bg-gray-300'></div>
                <div className='mb-8 h-4 animate-pulse rounded bg-gray-300'></div>

                <div className='space-y-4'>
                  <div className='h-10 animate-pulse rounded bg-gray-300'></div>
                  <div className='h-10 animate-pulse rounded bg-gray-300'></div>
                  <div className='h-10 animate-pulse rounded bg-gray-300'></div>
                  <div className='h-32 animate-pulse rounded bg-gray-300'></div>
                </div>
              </div>
            }
          >
            {/* @ts-ignore */}
            <FormContent data={data} />
          </Suspense>
        </div>
      </div>
    </section>
  );
}
