'use client';
import { useRef } from 'react';
import { PortableTextBlock } from 'sanity';
import { m, useInView } from 'framer-motion';
import { fadeUp } from '@/assets/framer/animations';
import { noParagraphs, paragraphs } from './PortableTextComponents';
import { PortableText } from '@portabletext/react';
import { ArrowRight } from '@phosphor-icons/react';
import { IKLink } from '../shared/material';
import { DisplayOptionsType, MenuItemLink } from '@/types/types';
import { sectionMargin } from '../utils/utils';

export default function TwoColumnTextList({
  data,
}: {
  data: {
    sectionId?: string;
    heading: PortableTextBlock[];
    items: {
      text: PortableTextBlock[];
      link: MenuItemLink;
    }[];
    displayOptions?: {
      margin: DisplayOptionsType['margin'];
    };
  };
}) {
  const heading = data.heading;
  const headingRef = useRef(null);
  const isInView = useInView(headingRef, { amount: 0.4, once: true });
  const displayOptions = data.displayOptions;
  const sectionId = data?.sectionId;

  return (
    <section
      id={sectionId}
      className={`relative scroll-m-5 bg-white ${sectionMargin(displayOptions?.margin)} `}
    >
      <div className='container-padding container relative mx-auto'>
        {heading && (
          <m.h2
            initial='hidden'
            animate={isInView ? 'visible' : 'hidden'}
            variants={fadeUp}
            ref={headingRef}
            className='heading-xl relative z-10 font-display font-medium tracking-tighter text-charcoal sm:max-w-[700px] [&_strong]:text-pink'
          >
            {heading && (
              <PortableText
                components={noParagraphs}
                value={heading}
              ></PortableText>
            )}
          </m.h2>
        )}
        <div className='mt-6 flex flex-col gap-6 sm:flex-row sm:gap-8'>
          <div className='grid w-full gap-5 sm:gap-5 md:grid-cols-2 lg:max-w-[80%]'>
            {data.items.map((item, index) => (
              <m.div
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{
                  delay: 0.1 * index + 0.1,
                }}
                key={index}
                className='inline-flex'
              >
                <IKLink
                  link={item.link}
                  className='group flex items-center gap-2'
                >
                  <div className='sm:heading-xs font-sans text-lg font-semibold text-teal'>
                    {typeof item.text === 'string' ? (
                      item.text
                    ) : (
                      <PortableText
                        components={paragraphs}
                        value={item.text}
                      ></PortableText>
                    )}
                  </div>
                  <ArrowRight
                    weight='bold'
                    className='text-currentColor text-xl font-semibold text-teal transition duration-500 group-hover:translate-x-2 sm:text-3xl'
                  />
                </IKLink>
              </m.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
